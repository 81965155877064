import { Box, Button, FormControlLabel, MenuItem, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import ShiftLogPriority from "../../../shared/types/operate/shift-logging/ShiftLogPriority";
import { useFormik } from "formik";
import * as yup from "yup";
import IShiftLogSubmission from "../../../shared/types/operate/shift-logging/IShiftLogSubmission";
import { LoadingButton } from "@mui/lab";
import IShiftLog from "../../../shared/types/operate/shift-logging/IShiftLog";
import { useEffect } from "react";
import moment from "moment";
import { IAssetInformation } from "../../../shared/types/IAssetInformation";

const ShiftLogSubmissionForm: React.FC<IShiftLogSubmissionFormProps> = ({ assets = [], editingData, onFinishedSubmitting, onClearForm }) => {
    const validationSchema = yup.object({
        siteId: yup.string()
            .required("Site is required"),
        text: yup.string()
            .required("Text log is required"),
    });

    const formik = useFormik({
        initialValues: {
            priority: "Low",
            ongoing: false,
            siteId: "",
            text: "",
        } as IShiftLogSubmission,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            // Make sure to do these when real submission finishes
            clearForm();
            formik.setSubmitting(false);
            onFinishedSubmitting && onFinishedSubmitting();
        },
        validateOnMount: true,
    });

    useEffect(() => {
        if (!editingData) return;

        formik.setValues({
            priority: editingData.priority,
            ongoing: editingData.ongoing,
            siteId: editingData.siteId,
            text: "",
        }, true);
    }, [editingData, formik]);

    const clearForm = () => {
        formik.resetForm();
        formik.validateForm();
        onClearForm && onClearForm();
    }

    return (
        <Paper
            sx={{ width: "100%" }}
            id="log-submission-form"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
        >
            <Stack direction="row" p={2} sx={{ height: "100%" }} spacing={2} useFlexGap flexWrap="wrap">
                <Stack flex={1} direction="column" id="log-submission-inputs" spacing={2}>
                    <Stack direction="row" id="log-submission-top-row" spacing={4} useFlexGap flexWrap="wrap">
                        <TextField
                            label="Priority"
                            select
                            name="priority"
                            value={formik.values.priority}
                            error={formik.touched.priority && Boolean(formik.errors.priority)}
                            helperText={formik.touched.priority && formik.errors.priority}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={formik.isSubmitting}
                            sx={{ width: "200px" }}
                        >
                            {
                                ShiftLogPriority.map((value) => {
                                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                                })
                            }
                        </TextField>
                        <Box width="190px" height="55px" display="flex" flexDirection="column" justifyContent="center">
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="ongoing"
                                        checked={formik.values.ongoing}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                    />
                                }
                                label="Ongoing Incident"
                            />
                        </Box>
                        <TextField
                            label="Site"
                            select
                            required
                            name="siteId"
                            value={formik.values.siteId}
                            error={formik.touched.siteId && Boolean(formik.errors.siteId)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={formik.isSubmitting || !!editingData}
                            sx={{ minWidth: "200px" }}
                        >
                            {assets?.map((asset) => {
                                return (
                                    <MenuItem key={asset.SiteID} value={asset.SiteID}>
                                        {asset.Name}
                                    </MenuItem>
                                )
                            })}
                        </TextField>

                        <Button onClick={clearForm}>
                            Clear Form
                        </Button>

                        {
                            editingData &&
                            <Typography height="56px" lineHeight="56px" marginLeft="auto">
                                Opened: {moment(editingData.openingTimestamp).format("YYYY-MM-DD HH:mm:ss")}
                            </Typography>
                        }
                    </Stack>

                    <TextField
                        label="Text Log"
                        variant="outlined"
                        required
                        name="text"
                        value={formik.values.text}
                        error={formik.touched.text && Boolean(formik.errors.text)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting}
                    />
                </Stack>
                <LoadingButton
                    size="large"
                    sx={{ width: "120px" }}
                    color="success"
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                >
                    {!editingData ? "Submit" : "Add to Log"}
                </LoadingButton>
            </Stack>
        </Paper>
    );
}

export interface IShiftLogSubmissionFormProps {
    assets?: IAssetInformation[];
    editingData?: IShiftLog;
    onClearForm?: () => void;
    onFinishedSubmitting?: () => void;
}

export default ShiftLogSubmissionForm;
