import moment, { MomentInput } from "moment"
export const DateFormatter = (date: MomentInput) => {
    return moment(date).format('DD-MMM-YY');
}

export const DateTimeFormatter = (date: MomentInput) => {
    return moment(date).format('DD-MMM-YY HH:mm');
};

export const TimeFormatter = (date: MomentInput) => {
    return moment(date).format('HH:mm');
};

export const Ordinal = (number: number) => {
    switch (number) {
        case 1:
        case 21:
        case 31:
            return number + 'st';
        case 2:
        case 22:
            return number + 'nd';
        case 3:
        case 23:
            return number + 'rd';
        default:
            return number + 'th';
    }
}
