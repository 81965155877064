import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Moment } from 'moment';
import OutageFormDate from '../../../operate/outage-forms/OutageFormDate';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import OutageFormTime from '../../../operate/outage-forms/OutageFormTime';
import moment from 'moment';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const BalancingSummaryControls: React.FC<IBalancingSummaryControls> = ({ selectedTime, setSelectedTime, nowSelected, setNowSelected }) => {
    const theme = useTheme()

    const [currentTime, setCurrentTime] = useState<Moment>(moment.utc())
    const [disableDateButton, setDisableDateButton] = useState<boolean>(false)
    const [disableTimeButton, setDisableTimeButton] = useState<boolean>(false)

    // Styling for arrow icons
    const iconStyling = (disabled: boolean = false) => {
        return {
            stroke: disabled ? theme.palette.primary.dark : theme.palette.primary.main,
            strokeWidth: 2,
            height: "40px",
            width: "40px",
        }
    }

    // Disable icons logic
    useEffect(() => {
        const startOfDay = moment(currentTime).utc().startOf("day")
        const startOfSelectedDay = moment(selectedTime).utc().startOf("day")
        setDisableDateButton(startOfSelectedDay.isSameOrAfter(startOfDay))
        const selectedTimePlusHour = moment(selectedTime).utc().startOf("minute").add(1, "hour")
        const currentTimeToMinute = moment(currentTime).utc().startOf("minute")
        setDisableTimeButton(selectedTimePlusHour.isAfter(currentTimeToMinute))
    }, [selectedTime, currentTime])

    // Keeping track of the current time
    const updateCurrentTime = useCallback(() => {
        setCurrentTime(moment.utc().startOf("minute"))
    }, [])

    useEffect(() => {
        updateCurrentTime()
        const intervalId = setInterval(() => {
            updateCurrentTime();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [updateCurrentTime])

    // Setting selected time when set to 'now'
    useEffect(() => {
        if (nowSelected) {
            setSelectedTime(currentTime)
        }
    }, [currentTime, nowSelected, setSelectedTime])

    // Control handlers
    const clickedSetToNow = () => {
        setNowSelected(true)
    }

    const handleDateTimeChange = (newDateTime: Moment) => {
        setNowSelected(false)
        setSelectedTime(newDateTime)
    }

    const backOneDay = () => {
        handleDateTimeChange(moment(selectedTime).utc().subtract(1, "day"))
    }

    const forwardOneDay = () => {
        handleDateTimeChange(moment(selectedTime).utc().add(1, "day"))
    }

    const backOneHour = () => {
        handleDateTimeChange(moment(selectedTime).utc().subtract(1, "hour"))
    }

    const forwardOneHour = () => {
        handleDateTimeChange(moment(selectedTime).utc().add(1, "hour"))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container>
                <Grid xs={3}>
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" spacing={0.5}>
                        <IconButton onClick={backOneDay}>
                            <ArrowBack sx={iconStyling()}/>
                        </IconButton>
                        <Box width={"70%"}>
                            <OutageFormDate label="Selected Date" date={selectedTime} setDate={handleDateTimeChange} minDate={moment.utc().subtract(1, "month")} maxDate={moment.utc()} />
                        </Box>
                        <IconButton onClick={forwardOneDay} disabled={disableDateButton}>
                            <ArrowForward sx={iconStyling(disableDateButton)} />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid xs={3}>
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" spacing={0.5}>
                        <IconButton onClick={backOneHour}>
                            <ArrowBack sx={iconStyling()}/>
                        </IconButton>
                        <Box width={"70%"}>
                            <OutageFormTime label="Selected Time (UTC)" time={selectedTime} setTime={handleDateTimeChange} />
                        </Box>
                        <IconButton onClick={forwardOneHour} disabled={disableTimeButton}>
                            <ArrowForward sx={iconStyling(disableTimeButton)} />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid xs={2}>
                    <Button sx={{ width: "100%", height: "100%" }} disabled={nowSelected} onClick={clickedSetToNow}>Revert To Now</Button>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
export interface IBalancingSummaryControls {
    selectedTime: Moment
    setSelectedTime: (selectedTime: Moment) => void
    nowSelected: boolean
    setNowSelected: (nowSelected: boolean) => void
}

export default BalancingSummaryControls;