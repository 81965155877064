import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userSettingsApi } from "../../shared/api/UserSettingsApi";
import { assetApi } from "../api/AssetApi";
import { engineerLocationApi } from "../api/EnginnerLocationApi";
import { userApi } from "../api/UserApi";
import { electricityInterconnectorApi } from "../api/ElectricityInterconnectorApi";
import dailyMeteredReducer from "./slices/DailyMeteredSlice";
import historicalSubmissionsReducer from "./slices/HistoricalSubmissionsSlice";
import navigationMenuReducer from "./slices/NavigationMenuSlice";
import nonDailyMeteredReducer from "./slices/NonDailyMeteredSlice";
import mapSettingsReducer from "./slices/MapSettingsSlice";
import { powerBiReduxApi } from "../api/PowerBiApi";
import { metadataApi } from "../api/MetadataApi";
import notificationsReducer from "./slices/NotificationsSlice";
import { notificationsApi } from "../api/NotificationsApi";
import { notificationSettingsApi } from "../api/NotificationSettingsApi";
import boaReducer from "./slices/BOADataSlice";
import { commercialOpportunityApi } from "../api/CommercialOpportunityApi";
import { permissionsApi } from "../api/PermissionsApi";
import { tradingAnalyticsApi } from "../api/TradingAnalyticsApi";

const reducers = combineReducers({
    navigationMenu: navigationMenuReducer,
    dailyMetered: dailyMeteredReducer,
    nonDailyMetered: nonDailyMeteredReducer,
    historicalSubmissions: historicalSubmissionsReducer,
    mapSettings: mapSettingsReducer,
    notifications: notificationsReducer,
    newBoas: boaReducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    [tradingAnalyticsApi.reducerPath]: tradingAnalyticsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [engineerLocationApi.reducerPath]: engineerLocationApi.reducer,
    [electricityInterconnectorApi.reducerPath]: electricityInterconnectorApi.reducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
    [powerBiReduxApi.reducerPath]: powerBiReduxApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [notificationSettingsApi.reducerPath]: notificationSettingsApi.reducer,
    [commercialOpportunityApi.reducerPath]: commercialOpportunityApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer
});

const apiMiddleware = [
    userSettingsApi.middleware,
    tradingAnalyticsApi.middleware,
    userApi.middleware,
    assetApi.middleware,
    engineerLocationApi.middleware,
    electricityInterconnectorApi.middleware,
    metadataApi.middleware,
    powerBiReduxApi.middleware,
    notificationsApi.middleware,
    notificationSettingsApi.middleware,
    permissionsApi.middleware,
    commercialOpportunityApi.middleware
];

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiMiddleware),
    //preloadedState: loadState("ion-portal")
});

//setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


//store.subscribe(() => {
//    const item = { navigationMenu: store.getState().navigationMenu };
//    console.log("TONY SUBSCRIBE", item);

//    saveState("ion-portal", item);
//});
