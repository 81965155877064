import moment from "moment";
import { IBalancingSummaryData } from "../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
import { IBidOfferData } from "../../../shared/types/analytics/trading-analytics/IBidOfferData";
import { IBSADData, IBSADDataFormatted } from "../../../shared/types/analytics/trading-analytics/IBSADData";
import { IFailureToDeliverData, IFailureToDeliverDataFormatted } from "../../../shared/types/analytics/trading-analytics/IFailureToDeliverData";
import { IImbalanceREMIT } from "../../../shared/types/analytics/trading-analytics/IImbalanceREMIT";
import { IMarketUpdateDictionary, IMarketUpdateMessage } from "../../../shared/types/analytics/trading-analytics/IMarketUpdateMessage";
import { INIVData } from "../../../shared/types/analytics/trading-analytics/INIVData";
import { ISystemSummaryData } from "../../../shared/types/analytics/trading-analytics/ISystemSummaryData";

// A collection of methods providing dummy data for trading analytics screens
// Will be replaced with actual backend methods
export const GetCurrentNIV = () => {
    return 100
}
export const GetCurrentSIP = () => {
    return 50
}
export const GetCurrentBSAD = () => {
    return 20
}

export const GetBalancingSummaryData = () => {
    const dummyData: IBalancingSummaryData[] = [
        {
            dateTime: moment.utc().startOf("hour").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(30, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(60, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(90, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(120, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(150, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(180, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(210, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(240, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(300, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(330, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(360, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        },
        {
            dateTime: moment.utc().startOf("hour").subtract(390, "minutes").valueOf(),
            biomass: 50,
            ccgt: 50,
            flexGen: 50,
            battery: 50,
            nuclear: 50,
            interconnectors: 50,
            pumped: 50,
            hydro: 50,
            bsadUp: 50,
            bsadDown: 50,
            bid250Plus: 50,
            bid200To250: 50,
            bid170To200: 50,
            bid140To170: 50,
            bid120To140: 50,
            bid100To120: 50,
            bid80To100: 50,
            bid65To80: 50,
            bid50To65: 50,
            bid0To50: 50,
            offer0Minus: 50,
            offer0To10: 50,
            offer10To20: 50,
            offer20To30: 50,
            offer30To35: 50,
            offer35To40: 50,
            offer40To45: 50,
            offer45To50: 50,
            offer50To55: 50,
            offer55Plus: 50,
            overallImbalance: 30
        }
    ]
    return dummyData
}

export const GetHighestDailyLOLP = () => {
    return 0.03
}

export const GetLowestDailyDRM = () => {
    return 15300
}

export const GetFrequency = () => {
    return 45.84
}

export const GetDemandForecastDeviation = () => {
    return 40
}

export const GetWindForecastDeviation = () => {
    return 300
}

export const GetTotalUnplannedOutages = () => {
    return 650
}

export const GetFailureToDeliverData = () => {

    const dummyData: IFailureToDeliverData[] = [
        {
            dateTime: moment.utc().subtract(9, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 40,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 40,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(8, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 40,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 40,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(7, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 40,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 40,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(6, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 40,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 40,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(5, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 40,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 40,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(4, "hour").valueOf(),
            interconnectors: 50,
            pumped: 40,
            battery: 50,
            oil: 40,
            flexgen: 50,
            peat: 50,
            coal: 50,
            ccgt: 40,
            biomass: 40,
            hydro: 50,
            wind: 40,
            solar: 50,
            nuclear: 40,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            interconnectors: 50,
            pumped: 50,
            battery: 40,
            oil: 40,
            flexgen: 50,
            peat: 40,
            coal: 50,
            ccgt: 50,
            biomass: 50,
            hydro: 40,
            wind: 40,
            solar: 50,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(2, "hour").valueOf(),
            interconnectors: 50,
            pumped: 40,
            battery: 50,
            oil: 50,
            flexgen: 50,
            peat: 50,
            coal: 50,
            ccgt: 50,
            biomass: 50,
            hydro: 50,
            wind: 50,
            solar: 50,
            nuclear: 50,
            synchronouscondenser: 50,
            other: 40
        },
        {
            dateTime: moment.utc().subtract(1, "hour").valueOf(),
            interconnectors: 40,
            pumped: 40,
            battery: 40,
            oil: 50,
            flexgen: 50,
            peat: 40,
            coal: 40,
            ccgt: 50,
            biomass: 50,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 50,
            synchronouscondenser: 40,
            other: 40
        },
        {
            dateTime: moment.utc().valueOf(),
            interconnectors: 50,
            pumped: 40,
            battery: 40,
            oil: 50,
            flexgen: 50,
            peat: 50,
            coal: 40,
            ccgt: 40,
            biomass: 50,
            hydro: 40,
            wind: 40,
            solar: 40,
            nuclear: 40,
            synchronouscondenser: 50,
            other: 40
        }
    ]

    const formattedDummyData: IFailureToDeliverDataFormatted[] = []

    dummyData.forEach((dataPoint) => {
        const formattedDataPoint = dataPoint as IFailureToDeliverDataFormatted
        let total = 0
        let key: keyof IFailureToDeliverData;
        for (key in dataPoint) {
            if (key !== "dateTime" && key !== "interconnectors") {
                total += dataPoint[key]
            }
        }
        formattedDataPoint.total = total
        formattedDummyData.push(formattedDataPoint)
    })
    return formattedDummyData
}

export const GetLiveImbalanceData = (dateTime: number) => {
    const dummyData: IImbalanceREMIT[] = [
        {
            dateTime: moment.utc().valueOf(),
            genType: "pumped",
            assetID: "STAY-4",
            assetName: "STAY-4",
            startTime: moment.utc().subtract(5, "day").format(),
            endTime: moment.utc().add(2, "hour").format(),
            unavailableMW: 6
        },
        {
            dateTime: moment.utc().subtract(1, "hour").valueOf(),
            genType: "pumped",
            assetID: "STAY-4",
            assetName: "STAY-4",
            startTime: moment.utc().subtract(5, "day").format(),
            endTime: moment.utc().add(2, "hour").format(),
            unavailableMW: 8
        }
    ]
    return dummyData
}

export const GetSystemSummaryData = () => {
    const dummyData: ISystemSummaryData[] = [
    ]
    for (let i = 0; i <= 100; i++) {
        dummyData.push({
            dateTime: moment.utc().startOf("hour").subtract(i*30, "minutes").valueOf(),
            imbalNGC: 1200,
            disBSAD: 1200,
            ispStack: 1200,
            nivForecast: 1200,
            totalBalMechLevel: 1200,
            ndf: 100,
            tsdf: 90,
            indo: 80,
            itsdo: 70,
            mid: 60,
            dateTimeGMT: "",
            dateTimeBST: "",
            settlementDate: "",
            settlementPeriod: 1
        })
    }
    return dummyData
}

export const GetMarketUpdateMessages = () => {
    // See ticket 3212 for more details on links
    const sonarDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some sonar stuff happened. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel nibh vel justo sagittis volutpat eu non turpis. Integer sit amet sapien ultricies quam maximus cursus nec in orci. Aenean felis velit, mollis hendrerit diam eget, rhoncus pretium diam. Maecenas varius faucibus ante, eget vulputate eros dignissim quis. Maecenas scelerisque pharetra urna, ut eleifend sapien pretium eget. Suspendisse diam lectus, rutrum quis sem vel, semper elementum ante. Nulla facilisi. Integer euismod nunc laoreet ultricies sodales. Mauris tincidunt vitae ligula at gravida. Donec consectetur tellus a neque tristique dignissim. Cras sollicitudin massa nisl, sit amet mattis massa vestibulum id.",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(2, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(1, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(2, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(4, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(5, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(6, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(7, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(8, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Oil",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(9, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        },
        {
            dateTime: moment.utc().subtract(10, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Solar",
            alertText: "Some more sonar stuff happened",
            link: "https://extranet.nationalgrid.com/Sonar"
        }
    ]
    const outagesDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Gas",
            alertText: "Some outages stuff happened",
            link: "https://bmrs.elexon.co.uk/remit/details/48X000000000318Q-NGET-RMT-00110457?messageId=759204"
        }
    ]
    const systemDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Gas",
            alertText: "Some system stuff happened",
            link: "https://bmrs.elexon.co.uk/events"
        }
    ]
    const melDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Gas",
            alertText: "Some mel stuff happened worth 80 MW",
            link: "https://bmrs.elexon.co.uk/balancing-mechanism-bmu-view?bmuId=T_ABRBO-1&activeTab=Physical&startDate=2024-06-26T23:00:00.000Z&endDate=2024-06-27T17:00:00.000Z",
            value: 80
        }
    ]
    const bodDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Battery",
            alertText: "Some bod stuff happened",
            link: "https://bmrs.elexon.co.uk/balancing-mechanism-bmu-view?bmuId=T_ABRBO-1&activeTab=Physical&startDate=2024-06-26T23:00:00.000Z&endDate=2024-06-27T17:00:00.000Z"
        }
    ]
    const boaDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Battery",
            alertText: "Some boa stuff happened",
            link: "https://bmrs.elexon.co.uk/adjustment-actions-disbsad"
        }
    ]
    const interconnectorsDummy: IMarketUpdateMessage[] = [
        {
            dateTime: moment.utc().subtract(3, "hour").valueOf(),
            unit: "ALCOA",
            fuelType: "Interconnectors",
            alertText: "Some interconnectors stuff happened",
            link: "https://bmrs.elexon.co.uk/interconnector-flows"
        }
    ]
    var dummyData: IMarketUpdateDictionary = {
        sonar: sonarDummy,
        remit: outagesDummy,
        system: systemDummy,
        mel: melDummy,
        bod: bodDummy,
        boa: boaDummy,
        interconnector: interconnectorsDummy
    }
    return dummyData
}

export const GetNIVData = () => {

    const dummyData: INIVData = {
        actualPower: 340,
        actualPrice: 135,
        forecastPower: 247,
        forecastPrice: 125,
        probOfShort: 0.66,
        minusFivePrice: 45.25,
        minusTwoPrice: 109,
        minusOnePrice: 115,
        plusOnePrice: 135,
        plusTwoPrice: 142,
        plusFivePrice: 175
    }

    return dummyData
};
export const GetBidData = () => {

    const dummyData: IBidOfferData[] = [
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Nuclear",
            price: 76,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Flex Gen",
            price: 77,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "OCGT",
            price: 78,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Pumped",
            price: 79,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Battery",
            price: 80,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Biomass",
            price: 81,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "Coal",
            price: 82,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "BSAD",
            price: 15000,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Alcoa",
            location: "SW England",
            fuelType: "CCGT",
            price: 84,
            fpn: 0,
            boa: 0,
            bmAdjustedFPN: 0,
            mel: 500,
            sel: 160,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_ALCOA-1",
            nationalGridBMID: "ALCOA-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Bispham",
            location: "NW England",
            fuelType: "Wind",
            price: 92,
            fpn: 839,
            boa: 0,
            bmAdjustedFPN: 839,
            mel: 900,
            sel: 270,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_BSPHM-1",
            nationalGridBMID: "BSPHM-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Bispham",
            location: "NW England",
            fuelType: "Hydro",
            price: 93,
            fpn: 839,
            boa: 20,
            bmAdjustedFPN: 839,
            mel: 900,
            sel: 270,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: false,
            elexonBMUID: "E_BSPHM-1",
            nationalGridBMID: "BSPHM-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Bispham",
            location: "NW England",
            fuelType: "Wind",
            price: 94,
            fpn: 839,
            boa: 0,
            bmAdjustedFPN: 839,
            mel: 900,
            sel: 270,
            pair: 1,
            volume: 0,
            bid: true,
            systemFlag: true,
            elexonBMUID: "E_BSPHM-1",
            nationalGridBMID: "BSPHM-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
    ]

    return dummyData
};
export const GetOfferData = () => {

    const dummyData: IBidOfferData[] = [
        {
            siteID: "Redfield Road A",
            location: "SE England",
            fuelType: "Flex Gen",
            price: 50,
            fpn: 14,
            boa: -14,
            bmAdjustedFPN: 0,
            mel: 14,
            sel: 14,
            pair: 1,
            volume: -14,
            bid: false,
            systemFlag: false,
            elexonBMUID: "E_RDFRD-1",
            nationalGridBMID: "RDFRD-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        },
        {
            siteID: "Redfield Road A",
            location: "SE England",
            fuelType: "Flex Gen",
            price: 51,
            fpn: 14,
            boa: -14,
            bmAdjustedFPN: 0,
            mel: 14,
            sel: 14,
            pair: 1,
            volume: -14,
            bid: false,
            systemFlag: true,
            elexonBMUID: "E_RDFRD-1",
            nationalGridBMID: "RDFRD-1",
            mnzt: 0,
            mzt: 0,
            ndz: 10,
            latestCall: moment.utc().format(),
            nto: 10,
            ntb: 10
        }
    ]

    return dummyData
};

const GetBSADData = () => {

    const dummyData: IBSADData[] = [
        {
            assetID: "Alcoa",
            location: "NE England",
            volume: 100,
            soFlag: false,
            cost: 20,
            settlementPeriod: 21,
            service: "Energy"
        },
        {
            assetID: "Alcoa",
            location: "NE England",
            volume: 100,
            soFlag: true,
            cost: 200,
            settlementPeriod: 21,
            service: "Energy"
        },
        {
            assetID: "Alcoa",
            location: "NE England",
            volume: 100,
            soFlag: false,
            cost: 500,
            settlementPeriod: 21,
            service: "Energy"
        },
        {
            assetID: "Alcoa",
            location: "NE England",
            volume: 100,
            soFlag: true,
            cost: 50,
            settlementPeriod: 21,
            service: "Energy"
        },
        {
            assetID: "Alcoa",
            location: "NE England",
            volume: 200,
            soFlag: true,
            cost: 80,
            settlementPeriod: 22,
            service: "Energy"
        }
    ]
    return dummyData
};

export const GetFormattedBSADData = () => {


    const bsadData = GetBSADData()
    const formattedBSADData: IBSADDataFormatted[] = []

    bsadData.forEach(dataPoint => {
        const formattedDataPoint = dataPoint as IBSADDataFormatted
        formattedDataPoint.fuelType = "BSAD"
        formattedDataPoint.price = formattedDataPoint.cost / formattedDataPoint.volume
        formattedBSADData.push(formattedDataPoint)
    })

    return formattedBSADData
};