import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../operate/LoadingSymbol";
import { Bar, Cell, ComposedChart, Customized, Label, Legend, Rectangle, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import SquareIcon from '@mui/icons-material/Square';
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateTimeFormatter } from "./helpers/DateFormatter"

const WithinDayMarketChart: React.FC<IWithinDayMarketChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();
    const profitColor = NewColorPalette[1]
    const lossColor = NewColorPalette[10]
    const errorBarColor = (colourMode.isDarkMode || displayScreen ? "#FFFFFF" : "#262626")

    const loadMarketData = useCallback(() => {
        const formattedData = new Array<IAnalyticsChartDataItem>();
        
        analyticsApi.getWDMarketData()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                const currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const dateTimes = new Set<number>()
                response.forEach((dataItem) => {
                    const dataMoment = moment(dataItem.dateTimeUTC + "Z").valueOf()
                    if (!dateTimes.has(dataMoment)) {
                        dateTimes.add(dataMoment)
                        const openingPrice = dataItem.openingPrice
                        const closingPrice = dataItem.closingPrice
                        const maxPriceAvg = dataItem.maxPrice
                        const minPriceAvg = dataItem.minPrice
                        let minValue
                        let maxValue
                        if (openingPrice && closingPrice) {
                            minValue = Math.min(openingPrice, closingPrice)
                            maxValue = Math.max(openingPrice, closingPrice)
                        }
                        const newItem: IAnalyticsChartDataItem = {
                            dateTime: dataMoment,
                            openingPriceAvg: openingPrice,
                            closingPriceAvg: closingPrice,
                            maxPriceAvg: maxPriceAvg,
                            minPriceAvg: minPriceAvg,
                            loss: (openingPrice && closingPrice && closingPrice < openingPrice ? 1 : 0),
                            barData: (minValue && maxValue ? [minValue, maxValue] : undefined)
                        }
                        formattedData.push(newItem)
                    }
                    
                })
                formattedData.sort((a, b) => {
                    if (a.dateTime < b.dateTime) { return -1 }
                    else { return 1 }
                })
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(formattedData)
                
            })
    }, [])


    useEffect(() => {
        loadMarketData()
        const intervalId = setInterval(() => {
            loadMarketData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadMarketData])

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    "color": (data.loss ? lossColor : profitColor),
                    px: 1,
                    py: 0.5
                }} >
                    <p style={{ color: (colourMode.isDarkMode ? "white" : "black") }}>Date (UTC): {DateTimeFormatter(data.dateTime)}</p>
                    {(data.openingPriceAvg ? <p>Average Opening Price: &pound;{data.openingPriceAvg.toFixed(2)}</p> : <></>)}
                    {(data.closingPriceAvg ? <p>Average Closing Price: &pound;{data.closingPriceAvg.toFixed(2)}</p> : <></>)}
                    {(data.maxPriceAvg ? <p>Average Max Price: &pound;{data.maxPriceAvg.toFixed(2)}</p> : <></>)}
                    {(data.minPriceAvg ? <p>Average Min Price: &pound;{data.minPriceAvg.toFixed(2)}</p> : <></>)}
                </Box>
            );
        }
        return null
    };
    const CustomLegend = ({ active, payload }: any) => {
        return (
            <Box>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <SquareIcon htmlColor={lossColor} />
                            <span> Losing Half Hour </span>
                            <SquareIcon htmlColor={profitColor} />
                            <span> Profitable Half Hour</span>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    };
    const CustomErrorBars = ({ formattedGraphicalItems } : any) => {
        const barSeries = formattedGraphicalItems[0]
        const upperErrorSeries = formattedGraphicalItems[1]
        const lowerErrorSeries = formattedGraphicalItems[2]
        const barData = barSeries.props.data
        const upperPointData = upperErrorSeries.props.points
        const lowerPointData = lowerErrorSeries.props.points
        let i=0
        return barData.map((bar: any, index: number) => {
            i++
            if (bar.openingPriceAvg && bar.closingPriceAvg && bar.maxPriceAvg && bar.minPriceAvg) {
                const upperPoint = upperPointData[index]
                const lowerPoint = lowerPointData[index]
                const width = 3
                const upperHeight = (upperPoint.y + upperPoint.height / 2) - bar.y
                const baseOfBar = bar.y + bar.height
                const lowerHeight = (lowerPoint.y + lowerPoint.height / 2) - baseOfBar
                const midpoint = (bar.x + bar.width / 2) - width / 2
                return (
                    <Fragment key={"WD-Error-Bar-" + index.toString()}>
                        <Rectangle
                            key={"UpperBar" + i}
                            width={width}
                            height={upperHeight}
                            x={midpoint}
                            y={bar.y}
                            fill={errorBarColor}
                        />
                        <Rectangle
                            key={"LowerBar" + i}
                            width={width}
                            height={lowerHeight}
                            x={midpoint}
                            y={baseOfBar}
                            fill={errorBarColor}
                        />
                    </Fragment>
                )
            }
        })
    };
    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" }) }
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Within Day Market Chart</Typography>
                <ResponsiveContainer height={"87%" }>
                    {(chartData.length > 0 ?
                        <ComposedChart data={chartData}>
                            <Tooltip filterNull={false} content={<CustomTooltip payload={chartData} />} />
                            <XAxis padding="gap" name="Time" domain={[domainStart, domainEnd]} dataKey="dateTime" scale="time" type="number" tickFormatter={DateTimeFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis domain={[0, 300]}><Label dx={-20} dy={50} angle={-90}></Label></YAxis>
                            {(!displayScreen ? <Legend content={<CustomLegend payload={chartData} />} />: <></>)}
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <Bar dataKey="barData" name="Bar Data" stroke={errorBarColor} isAnimationActive={false} >{
                                chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={(chartData[index].loss ? lossColor : profitColor)} />
                                ))}
                            </Bar>
                            
                            <Scatter name="Upper Range" dataKey="maxPriceAvg" label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                            <Scatter name="Lower Range" dataKey="minPriceAvg" label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                            <Customized component={CustomErrorBars} />
                        </ComposedChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IWithinDayMarketChart {
    displayScreen?: boolean
}

export default WithinDayMarketChart;