import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Icon from '@mdi/react';
import { mdiChartBellCurveCumulative } from '@mdi/js';
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import SystemSummaryWrapper from "../../../components/analytics/trading-analytics/system-summary/SystemSummaryWrapper";

const SystemSummaryPage: React.FC<ISystemSummaryPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="System Summary - Dummy Data" loaded={loaded}>
            <SystemSummaryWrapper />
        </PageWrapper>
    );
}


export interface ISystemSummaryPage {

}

const SystemSummaryPageConfig: IPortalRouteOptions = {
    relativeUrl: "system-summary",
    page: <SystemSummaryPage />,
    navDisplay: {
        title: "System Summary",
        icon: <Icon path={mdiChartBellCurveCumulative} size={1} />
    },
}

export default SystemSummaryPageConfig;