import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { useColourModeContext } from '../../components/ColourMode';
import { common } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import { HexMultiLineText } from './HexMultiLineText';
import { HexButton } from './HexButton';

export const DispatchButton: React.FC<IDispatchButton> = ({ location, dispatchActive, setDispatchActive }) => {
    const { isDarkMode } = useColourModeContext();
    const theme = useTheme()

    const handleClick = () => {
        setDispatchActive(!dispatchActive)
    }

    return (
        <HexButton location={location} cellStyle={{ fill: dispatchActive ? theme.palette.conradEnergyFreshGreen.dark : "none" }} stroke={isDarkMode ? common.white : common.black} strokeWidth={"0.01em"} style={{ cursor: "pointer" }} onClick={handleClick}>
            <HexMultiLineText text={dispatchActive ? "Dispatch Mode: On" : "Dispatch Mode: Off"} colour={isDarkMode ? common.white : common.black} y={-0.5} fontSize={0.09} fontWeight={800} />
        </HexButton>
    );
}

export interface IDispatchButton {
    location: CubicCoords,
    dispatchActive: boolean,
    setDispatchActive: (dispatchActive: boolean) => void
}