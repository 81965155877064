import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { IBidOfferData } from "../../../../shared/types/analytics/trading-analytics/IBidOfferData";
import BidOfferTableFilters from "./BidOfferTableFilters";
import BidOfferTable from "./BidOfferTable";

const BidOfferTableWrapper: React.FC<IBidOfferTableWrapper> = ({ data }) => {
    const [filteredData, setFilteredData] = useState<IBidOfferData[]>(data)
    const [showDynamicData, setShowDynamicData] = useState < boolean>(false)

    return (
        <Box width="100%">
            <Paper>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid xs={12}>
                        <BidOfferTableFilters unfilteredData={data} setFilteredData={setFilteredData} showDynamicData={showDynamicData} setShowDynamicData={setShowDynamicData} />
                    </Grid>
                    <Grid xs={12}>
                        <BidOfferTable items={filteredData} showDynamicData={showDynamicData} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
export interface IBidOfferTableWrapper {
    data: IBidOfferData[]
}

export default BidOfferTableWrapper;