import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Moment } from 'moment';
import { IAdvancedModalOptions, useModalWindowContext } from '../../../ModalWindow';
import OutageFormDate from '../../../operate/outage-forms/OutageFormDate';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import OutageFormTime from '../../../operate/outage-forms/OutageFormTime';
import moment from 'moment';

const ImbalanceTimeSelectorPopup: React.FC<IImbalanceTimeSelector> = ({ selectedTime, setSelectedTime, nowSelected, setNowSelected }) => {
    const [internalNowSelected, setInternalNowSelected] = useState<boolean>(nowSelected)
    const [internalSelectedTime, setInternalSelectedTime] = useState<Moment>(selectedTime)

    useEffect(() => {
        setInternalNowSelected(nowSelected)
    }, [nowSelected])

    useEffect(() => {
        setInternalSelectedTime(selectedTime)
    }, [selectedTime])

    const clickedSetToNow = () => {
        setInternalNowSelected(true)
        setNowSelected(true)
    }

    const handleDateTimeChange = (newDateTime: Moment) => {
        setNowSelected(false)
        setInternalNowSelected(false)
        setSelectedTime(newDateTime)
        setInternalSelectedTime(newDateTime)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={2} padding={2}>
                <Grid xs={6}>
                    <OutageFormDate label="Selected Date" date={internalSelectedTime} setDate={handleDateTimeChange} minDate={moment.utc().subtract(1, "month")} maxDate={moment.utc()} />
                </Grid>
                <Grid xs={6}>
                    <OutageFormTime label="Selected Time (UTC)" time={internalSelectedTime} setTime={handleDateTimeChange} />
                </Grid>
                <Grid xs={12}>
                    <Button sx={{ width: "100%" }} disabled={internalNowSelected} onClick={clickedSetToNow}>Set To Now</Button>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}

const ImbalanceTimeSelector: React.FC<IImbalanceTimeSelector> = ({ selectedTime, setSelectedTime, nowSelected, setNowSelected }) => {
    const theme = useTheme()
    const { openModal } = useModalWindowContext();

    const openFormHandler = () => {
        const popupComponent = (
            <ImbalanceTimeSelectorPopup selectedTime={selectedTime} setSelectedTime={setSelectedTime} nowSelected={nowSelected} setNowSelected={setNowSelected} />
        );

        const options: IAdvancedModalOptions = {
            title: "Date Selector",
            contentComponent: popupComponent,
            noText: "Close",
            fullWidth: true,
            maxWidth: 'sm',
            canSubmit: true,
        }

        openModal(options);
    }

    return (
        <Box
            onClick={openFormHandler}
            sx={{
                backgroundColor: theme.palette.primary.main,
                width: "200px",
                borderRadius: "10px",
                p: 1,
                border: "2px solid white",
                cursor: "pointer"
            }}>
            <Stack width="100%" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="white">{nowSelected ? "NOW" : selectedTime.format("YYYY-MM-DD HH:mm")}</Typography>
            </Stack>
        </Box>
    );
}
export interface IImbalanceTimeSelector {
    selectedTime: Moment
    setSelectedTime: (selectedTime: Moment) => void
    nowSelected: boolean
    setNowSelected: (nowSelected: boolean) => void
}

export default ImbalanceTimeSelector;