import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import SPPicker from "./SPPicker";
import { INIVData } from "../../../../shared/types/analytics/trading-analytics/INIVData";
import NIVTable from "./NIVTable";
import { GetNIVData, GetBidData, GetOfferData, GetFormattedBSADData } from "../../helpers/TradingAnalyticsData";
import { IBidOfferData } from "../../../../shared/types/analytics/trading-analytics/IBidOfferData";
import BidOfferTableWrapper from "./BidOfferTableWrapper";
import { IBSADDataFormatted } from "../../../../shared/types/analytics/trading-analytics/IBSADData";
import BSADTable from "./BSADTable";

const BidAndOfferWrapper: React.FC<IBidAndOfferWrapper> = ({ }) => {
    const [selectedSP, setSelectedSP] = useState<number>(1)
    const [showBSADData, setShowBSADData] = useState<boolean>(false)
    const [nivData, setNIVData] = useState<INIVData>()
    const [bidData, setBidData] = useState<IBidOfferData[]>()
    const [offerData, setOfferData] = useState<IBidOfferData[]>()
    const [bsadData, setBSADData] = useState<IBSADDataFormatted[]>()

    const loadData = useCallback(() => {
        setNIVData(GetNIVData())
        setBidData(GetBidData())
        setOfferData(GetOfferData())
        setBSADData(GetFormattedBSADData())
    }, [])

    useEffect(() => {
        loadData()
        const intervalId = setInterval(() => {
            loadData();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadData])
    
    return (
        <Box>
            <Grid 
                container
                rowSpacing={4}
            >
                <Grid xs={12}>
                    <SPPicker setSelectedSP={setSelectedSP} showBSADData={showBSADData} setShowBSADData={setShowBSADData} />
                </Grid>{
                    showBSADData ?
                        <Grid xs={12}>
                            {bsadData && <BSADTable items={bsadData} />}
                        </Grid>
                        :
                        <>
                            <Grid xs={12}>
                                {nivData && <NIVTable nivData={nivData} />}
                            </Grid>
                            <Grid xs={12}>
                                {bidData && <BidOfferTableWrapper data={bidData} />}
                            </Grid>
                            <Grid xs={12}>
                                {offerData && <BidOfferTableWrapper data={offerData} />}
                            </Grid>
                        </>
                }
            </Grid>
        </Box>
    );
}
export interface IBidAndOfferWrapper {
}

export default BidAndOfferWrapper;