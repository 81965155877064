import { ViewModule } from "@mui/icons-material";
import { Box, Button, CircularProgress, InputLabel, MenuItem, Paper, Select, TextField, Typography, FormControl } from "@mui/material";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import contractListApi from "../../../shared/api/PerfmonStatus";
import { IAuctionProduct, IPeriodPerformanceTooltipData, IStackedService } from "../../../shared/types/dynamic-contracts/IStackedService";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import ServicesRollup from "../../../components/optimise/dynamic-services/ServicesRollup";
import { Dictionary } from "@reduxjs/toolkit";
import SiteEFABlocks from "../../../components/optimise/dynamic-services/SiteEFABlocks";
import MouseTracker from "../../../components/shared/MouseTracker";
import AuctionProductTooltip from "../../../components/optimise/dynamic-services/AuctionProductTooltip";
import SPDataTooltip from "../../../components/optimise/dynamic-services/SPDataTooltip";
import DynamicControlBar from "../../../components/optimise/dynamic-services/DynamicControlBar";
import { BrowserView, isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import getPerfmonFile from "../../../shared/api/FileDownloadApi";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const DynamicServicesPage: React.FC<IDynamicServicesPage> = () => {
    const [allDayData, setAllDayData] = useState<Map<number, Map<string, IStackedService>>>(new Map<number, Map<string, IStackedService>>())
    const [idToName, setIdToName] = useState<Dictionary<string>>({})
    const [selectedAuctionProduct, setSelectedAuctionProduct] = useState<IAuctionProduct | null>(null)
    const [selectedSPTooltipData, setSelectedSPTooltipData] = useState<IPeriodPerformanceTooltipData | null>(null)
    const [querying, setQuerying] = useState<boolean>(false)
    const [selectedFileDate, setSelectedFileDate] = useState<Moment>(moment.utc().startOf("day"))
    const [selectedFileSite, setSelectedFileSite] = useState<string>()
    const siteIds = ["BLKB-1", "GRFLB-1", "SWDN-1", "TORQ-1", "WNCHB-1"]
    const siteWidth = isMobile ? 75 : 100
    const blockWidth = isMobile ? 350 : 600
    const blockPadding = 1

    const [searchParams, setSearchParams] = useSearchParams()

    const getStarterDate = () => {
        const dateFromUrl = searchParams.get("date")
        const date = moment(dateFromUrl, "YYYYMMDD", true)
        if (date.isValid()) { return date }
        else { return moment.utc().startOf("day") }
    }

    const [selectedDate, setSelectedDate] = useState<Moment>(getStarterDate())

    const setDate = (newDate: Moment | null) => {
        if (!newDate) { return }
        setSelectedDate(newDate)
        setSearchParams({ "date": newDate.format("YYYYMMDD") })
    }

    const DownloadPerfmonFile = () => {
        const run = async () => {
            const fileName = `${selectedFileSite}_${selectedFileDate.format("YYYYMMDDHHmmss")}_20Hz_perfmonv1.csv`
            const response = await getPerfmonFile(selectedFileDate, selectedFileSite);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        };

        run();
    }

    useEffect(() => {
        setQuerying(true)
        contractListApi.getStackedServices(selectedDate.clone().subtract(2, "day").format("YYYY-MM-DD"), selectedDate.clone().add(2, "day").format("YYYY-MM-DD"))
            .then((response) => {

                const currentDate = selectedDate.format("YYYY-MM-DD")
                const allDayDict = new Map<number, Map<string, IStackedService>>()
                const idToNameDict: Dictionary<string> = {}
                response.forEach((stackedServiceResponse) => {
                    const stackDate = moment(stackedServiceResponse.contractPeriod.efaDateOnly).format("YYYY-MM-DD")
                    const stackBlock = stackedServiceResponse.contractPeriod.efa
                    const stackID = stackedServiceResponse.contractPeriod.unitID
                    const stackUnitName = stackedServiceResponse.contractPeriod.unitName
                    if (stackDate === currentDate) {
                        let existingBlock = allDayDict.get(stackBlock) || new Map<string, IStackedService>()
                        existingBlock.set(stackID, stackedServiceResponse)
                        allDayDict.set(stackBlock, existingBlock)
                    }
                    idToNameDict[stackID] = stackUnitName
                })
                setAllDayData(allDayDict)
                setIdToName(idToNameDict)
            })
            .finally(() => {
                setQuerying(false)
            })
    }, [selectedDate])

    return (
        <PageWrapper title="Dynamic Services" loaded={true}>
            <MouseTracker offset={{ x: -220, y: -220 }}>
                <AuctionProductTooltip auctionProduct={selectedAuctionProduct} />
            </MouseTracker>
            <MouseTracker offset={{ x: -220, y: -305 }}>
                <SPDataTooltip data={selectedSPTooltipData} />
            </MouseTracker>
            <Paper
                sx={{ padding: "10px", marginBottom: "10px" }}
            >
                <Typography variant="h6" paddingBottom="10px">File Downloads</Typography>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            label="Date & Time"
                            inputFormat="DD/MM/YYYY HH:mm"
                            value={selectedFileDate}
                            views={["year", "month", "day", "hours"]}
                            onChange={(newValue) => { if (!newValue) { return; } else { setSelectedFileDate(newValue) } }}
                            renderInput={(params) => <TextField sx={{marginRight: "10px"}} {...params} />}
                        />
                    </LocalizationProvider>
                    <FormControl sx={{ width: "150px", marginRight: "10px"}}>
                        <InputLabel id="site-id-select-label">Site</InputLabel>
                        <Select
                            labelId="site-id-select-label"
                            value={selectedFileSite}
                            label="Select Site ID"
                            onChange={(newValue) => { if (!newValue) { return; } else { setSelectedFileSite(newValue.target.value as string) } }}
                        >
                            {siteIds.map(siteId => (
                                <MenuItem value={siteId}>{siteId}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        onClick={DownloadPerfmonFile}
                        disabled={!selectedFileSite || !selectedFileDate}
                        sx={{ height: "55px" }}
                    >
                        Download file
                    </Button>
                </Box>
            </Paper>
            <Paper
                sx={{ padding: "10px", height: "calc(100% - 1px)"}}
                onTouchStart={() => {
                    setSelectedAuctionProduct(null)
                    setSelectedSPTooltipData(null)
                }}
            >
                <DynamicControlBar querying={querying} selectedDate={selectedDate} setSelectedDate={setDate} />
                <Box sx={{ overflowX: "auto", overflowY: "auto", height: "calc(100% - 55px)"}}>
                        { querying ? 
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            /> :
                            <>
                                <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                    <Box sx={{ display: "inline-block", width: siteWidth }} />
                                    {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                        <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                            <Typography variant="h5" align="center">EFA Block {blockNumber}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <BrowserView>
                                    <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                        <Box sx={{ display: "inline-block", width: siteWidth }} />
                                        {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                            <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                                <ServicesRollup currentBlocks={allDayData.get(blockNumber)!} />
                                            </Box>
                                        )}
                                    </Box>
                                </BrowserView>
                                {Object.keys(idToName).sort().map((id) => {
                                    const siteName = idToName[id] || ""
                                    return (
                                        <Box sx={{ width: 6 * blockWidth + siteWidth }}>
                                            <Box sx={{ display: "inline-block", width: siteWidth }}>
                                                <Typography>{isMobile ? id : siteName}</Typography>
                                            </Box>
                                            {Array.from(allDayData.keys()).sort().map((blockNumber) =>
                                                <Box sx={{ width: blockWidth, display: "inline-block", p: blockPadding }}>
                                                    <SiteEFABlocks
                                                        key={id}
                                                        id={id}
                                                        currentBlock={allDayData.get(blockNumber)!.get(id) || null}
                                                        siteName={siteName}
                                                        setSelectedAuctionProduct={setSelectedAuctionProduct}
                                                        setSelectedSPRollupData={setSelectedSPTooltipData}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                )}
                            </>
                    }
                </Box>
            </Paper>
        </PageWrapper>
    );
}

export interface IDynamicServicesPage {

}

const DynamicServicesPageConfig: IPortalRouteOptions = {
    relativeUrl: "dynamic-services",
    page: <DynamicServicesPage />,
    navDisplay: {
        title: "Dynamic Services",
        icon: <ViewModule />
    }
}

export default DynamicServicesPageConfig;
