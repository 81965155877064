import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../../components/operate/LoadingSymbol";
import { Label, Legend, LineChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../../components/ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateTimeFormatter } from "./helpers/DateFormatter"

const GasSystemLengthChart: React.FC<IGasSystemLengthChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<IAnalyticsChartDataItem[]>([]);
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();
    

    // Helper function to generate the bounds
    const generateBounds = () => {
        const lowerBounds = new Array<IAnalyticsChartDataItem>();
        for (let j = -1; j<= 0; j++) {
            let time = moment.utc().startOf("day").add(5, "hour").add(j, "days")

            // To disconnect the days
            const temp = time.add(-1, "second")
            let newItem: IAnalyticsChartDataItem = {
                dateTime: temp.valueOf()
            }
            lowerBounds.push(newItem)
            time.add(1, "second")


            let value = -12
            for (let i = 0; i <= 4; i++) {
                newItem = {
                    dateTime: time.valueOf(),
                    lowerBound: value,
                    upperBound: 0 - value
                }
                lowerBounds.push(newItem)
                time = time.add(1, "hour")
            }
            for (let i = 5; i <= 10; i++) {
                value += 0.5
                newItem = {
                    dateTime: time.valueOf(),
                    lowerBound: value,
                    upperBound: 0 - value
                }
                lowerBounds.push(newItem)
                time = time.add(1, "hour")
            }
            for (let i = 11; i <= 15; i++) {
                value += 1
                newItem = {
                    dateTime: time.valueOf(),
                    lowerBound: value,
                    upperBound: 0 - value
                }
                lowerBounds.push(newItem)
                time = time.add(1, "hour")
            }
            value += 0.5
            newItem = {
                dateTime: time.valueOf(),
                lowerBound: value,
                upperBound: 0 - value
            }
            lowerBounds.push(newItem)
            time = time.add(1, "hour")
            value += 0.5
            newItem = {
                dateTime: time.valueOf(),
                lowerBound: value,
                upperBound: 0 - value
            }
            lowerBounds.push(newItem)
            time = time.add(1, "hour")
            for (let i = 18; i <= 23; i++) {
                newItem = {
                    dateTime: time.valueOf(),
                    lowerBound: value,
                    upperBound: 0 - value
                }
                lowerBounds.push(newItem)
                time = time.add(1, "hour")
            }
        }
        return lowerBounds;
    }

    const bounds = useMemo(
        () => generateBounds(),
        []
    )

    const loadGasSystemLength = useCallback(() => {
        setChartData([])
        analyticsApi.getGasSystemLength()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const currentGasDay = moment.utc().startOf('day').add(5, 'hour').valueOf()
                let newGasDay = false
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const chartData = new Array<IAnalyticsChartDataItem>()
                bounds.forEach((bound) => { chartData.push(bound) })
                const dateTimes = new Set<number>()
                response.forEach(d => {
                    d.applicableAt = moment(d.applicableAt).valueOf();
                    if (!dateTimes.has(d.applicableAt)) {
                        dateTimes.add(d.applicableAt)
                        // Add gap between yesterdays data and todays
                        if (!newGasDay && currentGasDay < d.applicableAt) {
                            newGasDay = true
                            const newItem = {
                                dateTime: currentGasDay
                            }
                            chartData.push(newItem)
                        }
                        const newItem = {
                            dateTime: moment(d.applicableAt).valueOf(),
                            value: d.value
                        }
                        chartData.push(newItem)
                    }
                    
                })
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(chartData)
            })
    }, [])

    useEffect(() => {
        loadGasSystemLength()
        const intervalId = setInterval(() => {
            loadGasSystemLength(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadGasSystemLength])

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length && payload[0].payload.value) {
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    "color": (colourMode.isDarkMode ? "white" : "black"),
                    px: 1,
                    py: 0.5
                }} >
                    <p>Date (UTC): {DateTimeFormatter(payload[0].payload.dateTime)}</p>
                    {(payload[0].payload.value ? <p>PCLP1 - OLP: {payload[0].payload.value.toFixed(3)} mscm</p> : <></>)}
                </Box>
            );
        }

        return null;
    };
    return (
        <Box
            sx={{ "height": "100%", "width": "100%"}}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Gas System Length</Typography>
                <ResponsiveContainer height={"87%" }>
                        {(chartData.length > 0 ? 
                        <LineChart data={chartData}>
                            <XAxis dataKey="dateTime" domain={[(displayScreen ? domainStart : beginningOfDay), domainEnd]} scale="time" type="number" tickFormatter={DateTimeFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis domain={[-20, 20]} allowDataOverflow={true}><Label dx={-20} angle={-90}>MSCM</Label></YAxis>
                            {(!displayScreen ? <Legend /> : <></>)}
                            <Tooltip cursor={true} content={<CustomTooltip payload={chartData} />} />
                            {(displayScreen ? <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} /> : <></>)}
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <ReferenceLine y={0} stroke="white" />
                            <Line type="step" name="PCLP1 - OLP" dataKey="value" stroke={(colourMode.isDarkMode || displayScreen ? "white" : "black")} activeDot={{ r: 8 }} dot={true} isAnimationActive={false} />
                            <Line type="basis" name="Lower Bound" dataKey="lowerBound" stroke={NewColorPalette[1]} strokeWidth={5} legendType="plainline" dot={false} activeDot={false} isAnimationActive={false} />
                            <Line type="basis" name="Upper Bound" dataKey="upperBound" stroke={NewColorPalette[1]} strokeWidth={5} legendType="plainline" dot={false} activeDot={false} isAnimationActive={false} />
                            </LineChart> : <LoadingSymbol />)}
                    </ResponsiveContainer>
                </Box>
            </Box>
    );
}

export interface IGasSystemLengthChart {
    displayScreen?: boolean
}

export default GasSystemLengthChart;