import React, { useState } from "react";
import { conradEnergyDeepBlueColour, conradEnergyFreshGreenColour, conradEnergyMagentaColour, conradEnergyRedColour } from "../../../shared/theme/themeBrand";
import { Popover, Stack, TableCell, Typography } from "@mui/material";
import { DateTimeFormatter } from "../../analytics/helpers/DateFormatter";
import BessFileStatus from "../../../shared/types/operate/bess-file-checking/BessFileStatus";
import IBessFileData from "../../../shared/types/operate/bess-file-checking/IBessFileData";

const BessFileCell: React.FC<IBessFileCellProps> = ({ siteName, file }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <TableCell
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                width={55}
                sx={{ background: !!file?.status ? getColourFromFileStatus(file.status) : "transparent" }}
            />
            {
                file && file.status !== "Healthy" &&
                <Popover
                    sx={{ pointerEvents: 'none' }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Stack spacing={1} p={2}>
                        <Typography>{siteName}</Typography>
                        <Typography>{DateTimeFormatter(file.timestamp)}</Typography>
                        <Typography>{formatFileStatus(file.status)}</Typography>
                        <Typography>{file.filename}</Typography>
                    </Stack>
                </Popover>
            }
        </>
    );
}

export interface IBessFileCellProps {
    siteName: string;
    file?: IBessFileData;
}

export default BessFileCell;

const getColourFromFileStatus = (fileStatus: BessFileStatus) => {
    switch (fileStatus) {
        case "Healthy":
            return conradEnergyFreshGreenColour.main;
        case "NoFile":
            return conradEnergyRedColour.main;
        case "NotInRange":
            return conradEnergyMagentaColour.main;
        case "Static":
            return conradEnergyDeepBlueColour.main;
    }
}

const formatFileStatus = (fileStatus: BessFileStatus) => {
    switch(fileStatus) {
        case "Healthy":
            return "Healthy";
        case "NoFile":
            return "File doesn't exist";
        case "NotInRange":
            return "File values not in range";
        case "Static":
            return "File values are static";
    }
}
