import React, { ReactElement, useEffect, useMemo, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import Icon from "@mdi/react";
import { mdiClipboardListOutline } from "@mdi/js";
import { Box, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import BessFileDayPicker from "../../../components/operate/bess-file-checking/BessFileDayPicker";
import { Moment } from "moment";
import moment from "moment";
import { TimeFormatter } from "../../../components/analytics/helpers/DateFormatter";
import { conradEnergyFreshGreenColour, conradEnergyRedColour } from "../../../shared/theme/themeBrand";
import BessFileCell from "../../../components/operate/bess-file-checking/BessFileCell";
import { useColourModeContext } from "../../../components/ColourMode";
import IBessFileData from "../../../shared/types/operate/bess-file-checking/IBessFileData";
import BessFileStatusLegend from "../../../components/operate/bess-file-checking/BessFileStatusLegend";

const generateDummyFileName = (siteName: string, file: IBessFileData) => {
    const timestamp = moment(file.timestamp).toISOString();
    return `${siteName.toLocaleUpperCase()}-1_${timestamp}_20Hz_perfmonv1.csv`;
}

const DailyFileCheckerPage: React.FC<IDailyFileCheckerPageProps> = () => {
    const [dateToDisplay, setDateToDisplay] = useState<Moment>(moment.utc().startOf("day"));
    const [data, setData] = useState<IBessDailyFileData[]>([]);
    const { theme } = useColourModeContext();

    useEffect(() => {
        setData(dummyData);
    }, []);

    const generatedColumnHeaders = useMemo(() => {
        const columnHeaders: string[] = [];

        for (var i = 0; i < 24; i++) {
            if (i === 0) {
                columnHeaders.push("00:00")
                continue;
            }

            if (i === 12) {
                columnHeaders.push("12:00");
                continue;
            }

            if (i === 23) {
                columnHeaders.push("23:00");
                continue;
            }

            columnHeaders.push("");
        }

        return columnHeaders;
    }, []);

    const getLastGoodConnectionInfo = (timestamp: string) => {
        const returnValue = { label: "", colour: "" };

        const date = moment.utc(timestamp);
        const now = moment.utc();

        if (now.diff(date, "hours") >= 24) {
            returnValue.label = date.format("DD/MM/YY");
        } else {
            returnValue.label = TimeFormatter(date);
        }

        if (now.diff(date, "minutes") >= 62) {
            returnValue.colour = conradEnergyRedColour.main;
        } else {
            returnValue.colour = conradEnergyFreshGreenColour.main;
        }

        return returnValue;
    }

    const getFileCells = (siteName: string, files: IBessFileData[]) => {
        const cells: ReactElement[] = [];

        for (var i = 0; i < 24; i++) {
            const file = files[i];

            if (!!file && !file.filename) {
                file.filename = generateDummyFileName(siteName, file);
            }

            cells.push(<BessFileCell siteName={siteName} file={file} />)
        }

        return (
            <>
                {cells}
            </>
        );
    }

    return (
        <PageWrapper title="Daily File Checker" loaded={true}>
            <Stack spacing={2}>
                <Box width="100%" display="flex" justifyContent="center">
                    <BessFileDayPicker date={dateToDisplay} onDateUpdated={setDateToDisplay} />
                </Box>
                <TableContainer sx={{ maxHeight: "calc(100vh - 285px)" }}>
                    <Table component={Paper} sx={{ width: "100%" }} stickyHeader>
                        <TableHead>
                            <TableRow sx={{ [`>.${tableCellClasses.head}`]: { backgroundColor: theme.palette.background.paper } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Server Health</TableCell>
                                {generatedColumnHeaders.map((header) => (
                                    <TableCell padding="none" width={55} align="center">{header}</TableCell>
                                ))}
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => {
                                const lastConnectionInfo = getLastGoodConnectionInfo(row.lastGoodConnectionTimestamp);
                                return (
                                    <TableRow key={row.siteName}>
                                        <TableCell align="center">
                                            {row.siteName}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ background: lastConnectionInfo.colour }}
                                        >
                                            {lastConnectionInfo.label}
                                        </TableCell>
                                        {getFileCells(row.siteName, row.files)}
                                        <TableCell>
                                            {row.files.filter((f) => f.status !== "Healthy").length}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <BessFileStatusLegend />
            </Stack>
        </PageWrapper>
    );
}

export interface IDailyFileCheckerPageProps {

}

const DailyFileCheckerPageConfig: IPortalRouteOptions = {
    relativeUrl: "daily-file-checker",
    page: <DailyFileCheckerPage />,
    navDisplay: {
        title: "Daily Files",
        icon: <Icon path={mdiClipboardListOutline} size={1} />,
    },
}

export default DailyFileCheckerPageConfig;

interface IBessDailyFileData {
    siteName: string;
    lastGoodConnectionTimestamp: string;
    files: IBessFileData[];
}

const dummyData: IBessDailyFileData[] = [
    {
        "siteName": "Blackpool",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 20:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 21:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 22:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 23:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Flitwick",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 20:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 21:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 22:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 23:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Midsomer",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 20:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 21:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 22:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 23:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Swindon",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 20:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 21:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 22:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 23:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Torquay",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 20:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 21:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 22:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 23:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Winchester",
        "lastGoodConnectionTimestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
        "files": [
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 01:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 02:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 03:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 04:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 05:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 06:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 07:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 08:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 09:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 10:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 11:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 12:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 13:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 14:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 15:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 16:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 17:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 18:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 05 2024 19:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
        ]
    }
];
