import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../operate/LoadingSymbol";
import { Area, AreaChart, Label, Legend, ReferenceLine, ResponsiveContainer,  Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../../components/ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import SquareIcon from '@mui/icons-material/Square';
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateTimeFormatter } from "./helpers/DateFormatter"

const FuelTypeChart: React.FC<IFuelTypeChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();


    const loadFuelData = useCallback(() => {
        let formattedData = new Array<IAnalyticsChartDataItem>();
        analyticsApi.getFuelData()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const dateTimes = new Set<number>()
                response.forEach((dataItem) => {
                    const dataMoment = moment(dataItem.dateTimeUTC + "Z").valueOf()
                    if (!dateTimes.has(dataMoment)) {
                        dateTimes.add(dataMoment)
                        const newItem: IAnalyticsChartDataItem = {
                            dateTime: dataMoment,
                            nuclear: dataItem.nuclear,
                            biomass: dataItem.biomass,
                            hydro: dataItem.hydro,
                            wind: dataItem.wind,
                            ccgt: dataItem.ccgt,
                            coal: dataItem.coal,
                            ocgt: dataItem.ocgt,
                            pumpedStorage: dataItem.pumpedStorage,
                            other: dataItem.other,
                        }
                        formattedData.push(newItem)
                    }
                })
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(formattedData)
            })
    }, [])


    useEffect(() => {
        loadFuelData()
        const intervalId = setInterval(() => {
            loadFuelData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadFuelData])

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    px: 1,
                    py: 0.5
                }} >
                    <p style={{ color: (colourMode.isDarkMode ? "white" : "black") }}>Date (UTC): {DateTimeFormatter(data.dateTime)}</p>
                    {(data.nuclear ? <p style={{ color: NewColorPalette[0] }}>Nuclear: {data.nuclear.toFixed(2)} MW</p> : <></>)}
                    {(data.biomass ? <p style={{ color: NewColorPalette[1] }}>Biomass: {data.biomass.toFixed(2)} MW</p> : <></>)}
                    {(data.hydro ? <p style={{ color: NewColorPalette[2] }}>Hydro: {data.hydro.toFixed(2)} MW</p> : <></>)}
                    {(data.wind ? <p style={{ color: NewColorPalette[3] }}>Wind: {data.wind.toFixed(2)} MW</p> : <></>)}
                    {(data.ccgt ? <p style={{ color: NewColorPalette[4] }}>CCGT: {data.ccgt.toFixed(2)} MW</p> : <></>)}
                    {(data.coal ? <p style={{ color: NewColorPalette[5] }}>Coal: {data.coal.toFixed(2)} MW</p> : <></>)}
                    {(data.ocgt ? <p style={{ color: NewColorPalette[6] }}>OCGT: {data.ocgt.toFixed(2)} MW</p> : <></>)}
                    {(data.pumpedStorage ? <p style={{ color: NewColorPalette[7] }}>Pumped Storage: {data.pumpedStorage.toFixed(2)} MW</p> : <></>)}
                    {(data.other ? <p style={{ color: NewColorPalette[8] }}>Other: {data.other.toFixed(2)} MW</p> : <></>)}
                </Box>
            );
        }
        return null
    };
    const CustomLegend = ({ active, payload }: any) => {
        return (
            <Box>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            {payload.map((entry: any) => {
                                return (
                                    <Fragment key={entry.value}>
                                        <SquareIcon htmlColor={entry.payload.fill} />
                                        <span style={{ color: entry.payload.fill }}>{entry.value}</span>
                                    </Fragment>
                                )
                            }) }
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    };
    const renderAreas = () => {
        const dataKeys = ["nuclear", "biomass", "hydro", "wind", "ccgt", "coal", "ocgt", "pumpedStorage", "other"]
        const names = ["Nuclear", "Biomass", "Hydro", "Wind", "CCGT", "Coal", "OCGT", "Pumped Storage", "Other"]
        const areas = dataKeys.map((key, index) => {
            const name = names[index]
            const color = NewColorPalette[index]
            return (
                <Area dataKey={key} key={key} name={name} stackId="1" unit=" MW" stroke="black" fill={color} type="monotone" opacity={1} fillOpacity={1} color={color} isAnimationActive={false} />
            )
        })
        return areas
    }
    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Fuel Type Gen</Typography>
                <ResponsiveContainer height={"87%"}>
                    {(chartData.length > 0 ?
                        <AreaChart data={chartData}>
                            <XAxis name="Time" domain={[domainStart, domainEnd]} dataKey="dateTime" scale="time" type="number" tickFormatter={DateTimeFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis><Label dx={-30} angle={-90}>System Generation (MW)</Label></YAxis>
                            {(!displayScreen ? <Legend iconType="square" content={<CustomLegend payload={chartData} />} />  : <></>)}
                            <Tooltip filterNull={false} content={<CustomTooltip payload={chartData} />} />
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            {renderAreas()}
                        </AreaChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IFuelTypeChart {
    displayScreen?: boolean
}

export default FuelTypeChart;