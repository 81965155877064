import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import DoneIcon from '@mui/icons-material/Done';
import { IIRISHeartbeatData } from "../../../../shared/types/analytics/trading-analytics/IIRISHeartbeatData";

const PortalHeartbeatTable: React.FC<IPortalHeartbeatTable> = ({ data }) => {
    const [sortedData, setSortedData] = useState<IIRISHeartbeatData[]>([])
    const theme = useTheme()

    const outOfDate = (heartbeat: IIRISHeartbeatData) => {
        if (heartbeat.actualAge) {
            return heartbeat.ageTolerance < heartbeat.actualAge
        }
        return false
    }

    useEffect(() => {
        const temp = [...data]
        const dataItems: string[] = []
        data.forEach((heartbeatSource => {
            dataItems.push(heartbeatSource.dataItem)
        }))
        temp.sort((a, b) => {
            const aInError = outOfDate(a)
            const bInError = outOfDate(b)
            if ((aInError && bInError) || (!aInError && !bInError)) {
                return dataItems.indexOf(a.dataItem) < dataItems.indexOf(b.dataItem) ? -1 : 1
            }
            else {
                return aInError ? -1 : 1
            }
        })
        setSortedData(temp)
    }, [data])
    
    return (
        <Box>
            <Grid
                container
                textAlign="center"
                rowSpacing={2}
                columns={25}
            >
                <Grid xs={8} sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography variant="h4">Data Item</Typography>
                </Grid>
                <Grid xs={8} sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography variant="h4">Age Tolerance (Mins)</Typography>
                </Grid>
                <Grid xs={8} sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Typography variant="h4">Actual Age (Mins)</Typography>
                </Grid>
                <Grid xs={1} />
                {sortedData.length ?
                    sortedData.map(heartbeat => {
                        const inError = outOfDate(heartbeat)
                        const backgroundColor = inError ? theme.palette.error.main : "default"
                        return (
                            <Fragment key={"Poral-Heartbeat-" + heartbeat.dataItem}>
                                <Grid xs={8} sx={{ backgroundColor: backgroundColor }}>
                                    <Typography variant="h6">{heartbeat.dataItem}</Typography>
                                </Grid>
                                <Grid xs={8} sx={{ backgroundColor: backgroundColor }}>
                                    <Typography variant="h6">{heartbeat.ageTolerance.toFixed(0)}</Typography>
                                </Grid>
                                <Grid xs={8} sx={{ backgroundColor: backgroundColor }}>
                                    <Typography variant="h6">{heartbeat.actualAge !== undefined && heartbeat.actualAge !== null ? heartbeat.actualAge.toFixed(0) : "NULL"}</Typography>
                                </Grid>
                                <Grid xs={1}>{!inError && <DoneIcon style={{ fill: theme.palette.conradEnergyFreshGreen.main }} />}</Grid>
                            </Fragment>
                        )
                    })
                    :
                    <Grid xs={25}>
                        <CircularProgress
                            size={60}
                            sx={{
                                zIndex: 1,
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}
export interface IPortalHeartbeatTable {
    data: IIRISHeartbeatData[]
}

export default PortalHeartbeatTable;