import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { EditCalendar } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import FailureToDeliverWrapper from "../../../components/analytics/trading-analytics/failure-to-deliver/FailureToDeliverWrapper";

const FailuresToDeliverPage: React.FC<IFailuresToDeliverPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Imbalance Screen - Dummy Data" loaded={loaded} contentOverflow="hidden">
            <Box sx={{ width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden" }}>
                <FailureToDeliverWrapper />
            </Box>
        </PageWrapper>
    );
}


export interface IFailuresToDeliverPage {

}

const FailuresToDeliverPageConfig: IPortalRouteOptions = {
    relativeUrl: "imbalance-screen",
    page: <FailuresToDeliverPage />,
    navDisplay: {
        title: "Imbalance Screen",
        icon: <EditCalendar />
    },
}

export default FailuresToDeliverPageConfig;