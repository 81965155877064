import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import Icon from '@mdi/react';
import { mdiTableLarge } from '@mdi/js';
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import BidAndOfferWrapper from "../../../components/analytics/trading-analytics/bid-and-offer/BidAndOfferWrapper";

const BidAndOfferPage: React.FC<IBidAndOfferPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Bid & Offer Tables - Dummy Data" loaded={loaded}>
            <BidAndOfferWrapper />
        </PageWrapper>
    );
}


export interface IBidAndOfferPage {

}

const BidAndOfferPageConfig: IPortalRouteOptions = {
    relativeUrl: "bid-and-offer",
    page: <BidAndOfferPage />,
    navDisplay: {
        title: "Bid & Offer Tables",
        icon: <Icon path={mdiTableLarge} size={1} />
    },
}

export default BidAndOfferPageConfig;