import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect, useState } from "react";
import { Twitter } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import MarketUpdateWrapper from "../../../components/analytics/trading-analytics/market-update/MarketUpdateWrapper";
import { useGetMarketUpdateSettingsQuery } from "../../../shared/api/TradingAnalyticsApi";
const MarketUpdatePage: React.FC<IMarketUpdatePage> = () => {
    const { data: setting, isSuccess } = useGetMarketUpdateSettingsQuery();

    return (
        <PageWrapper title="Market Update Feed - Dummy Data" loaded={isSuccess} contentOverflow="hidden">
            {setting && <MarketUpdateWrapper setting={setting} />}
        </PageWrapper>
    );
}


export interface IMarketUpdatePage {

}

const MarketUpdatePageConfig: IPortalRouteOptions = {
    relativeUrl: "market-update-feed",
    page: <MarketUpdatePage />,
    navDisplay: {
        title: "Market Update Feed",
        icon: <Twitter />
    },
}

export default MarketUpdatePageConfig;