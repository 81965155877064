import { useEffect, useState } from 'react';
import { Text } from 'react-hexgrid';
import { StatusHiveSiteData } from '../../shared/types/asset/status-hive';

export const HexBatteryStatus = ({ status, activeColour, y = 0, x = 0 }: IHexStatusTextProps): JSX.Element => {
    const [numberOfActiveServices, setNumberOfActiveServices] = useState<number>(0)

    const determineOpacity = (value?: number) => value && value > 0 ? 1 : 0;
    

    useEffect(() => {
        let newNumberOfActiveServices = 0
        if (determineOpacity(status.dch) === 1 || determineOpacity(status.dcl) === 1) {
            newNumberOfActiveServices++;
        }
        if (determineOpacity(status.dmh) === 1 || determineOpacity(status.dml) === 1) {
            newNumberOfActiveServices++;
        }
        if (determineOpacity(status.drh) === 1 || determineOpacity(status.drl) === 1) {
            newNumberOfActiveServices++;
        }
        setNumberOfActiveServices(newNumberOfActiveServices)
    }, [status.dch, status.dcl, status.dmh, status.dml, status.drh, status.drl])

    const height = 2.1;
    const width = 2.7;

    const strokeWidth = 0.1; // The total stroke width of the rectangle
    const lineThickness = strokeWidth / 2; // The thickness of each line
    const fontSizeValue = 0.07;

    const widthOffset = width + (strokeWidth * 2);

    // Y Values
    const yPosValue = -(height / 2) - lineThickness + y;
    const yPosValueTopText = yPosValue + (height / 2) - strokeWidth;
    const yPosValueBottomText = yPosValue + height - strokeWidth*2;

    const yPosTopText = `${yPosValueTopText}em`;
    const yPosBottomText = `${yPosValueBottomText}em`;

    // Centre
    const xPosValueText = 0;
    const xPosText = `${xPosValueText}em`;

    // Left
    const xPosValueLeftText = xPosValueText - widthOffset;
    const xPosLeftText = `${xPosValueLeftText}em`;

    // Right
    const xPosValueRightText = xPosValueText + widthOffset;
    const xPosRightText = `${xPosValueRightText}em`;

    // Left Centre
    const xPosValueLeftCentreText = xPosValueText - (widthOffset / 2);
    const xPosLeftCentreText = `${xPosValueLeftCentreText}em`;

    // Right Centre
    const xPosValueRightCentreText = xPosValueText + (widthOffset / 2);
    const xPosRightCentreText = `${xPosValueRightCentreText}em`;

    // Formatted
    const fontSize = `${fontSizeValue}em`;

    const determineXPosition = (serviceType: string) => {
        switch (serviceType) {
            case "dch":
            case "dcl":
                if (numberOfActiveServices <= 1) { return xPosText }
                else if (numberOfActiveServices === 2) { return xPosLeftCentreText }
                else { return xPosLeftText }
            case "dmh":
            case "dml":
                if (numberOfActiveServices <= 1) { return xPosText }
                else if (numberOfActiveServices === 2 && (determineOpacity(status.dch) >= 1 || determineOpacity(status.dcl) >= 1)) { return xPosRightCentreText }
                else if (numberOfActiveServices === 2) { return xPosLeftCentreText }
                else { return xPosText }
            case "drh":
            case "drl":
                if (numberOfActiveServices <= 1) { return xPosText }
                else if (numberOfActiveServices === 2) { return xPosRightCentreText }
                else { return xPosRightText }
        }
    }
    

    return (
        <>
            <Text x={determineXPosition("dch")} y={yPosTopText} opacity={determineOpacity(status.dch)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DCH</Text>
            <Text x={determineXPosition("dcl")} y={yPosBottomText} opacity={determineOpacity(status.dcl)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DCL</Text>

            <Text x={determineXPosition("dmh")} y={yPosTopText} opacity={determineOpacity(status.dmh)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DMH</Text>
            <Text x={determineXPosition("dml")} y={yPosBottomText} opacity={determineOpacity(status.dml)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DML</Text>

            <Text x={determineXPosition("drh")} y={yPosTopText} opacity={determineOpacity(status.drh)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DRH</Text>
            <Text x={determineXPosition("drl")} y={yPosBottomText} opacity={determineOpacity(status.drl)} fill={activeColour} fontSize={fontSize} strokeWidth="0" fontWeight={600}>DRL</Text>
        </>
    );
}

export interface IHexStatusTextProps {
    status: StatusHiveSiteData;
    activeColour: string;
    y?: number;
    x?: number;
}