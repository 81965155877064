import { Box, Button, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { IAssetInformation } from "../../../shared/types/IAssetInformation";
import IShiftLogFilterData from "../../../shared/types/operate/shift-logging/IShiftLogFilterData";

const ShiftLogTableFilters: React.FC<IShiftLogTableFiltersProps> = ({ assets = [], onFiltersUpdated }) => {
    const [startDate, setStartDate] = useState<Moment>();
    const [endDate, setEndDate] = useState<Moment>();
    const [highPriorityOnly, setHighPriorityOnly] = useState<boolean>(false);
    const [ongoingOnly, setOngoingOnly] = useState<boolean>(false);
    const [selectedSites, setSelectedAssets] = useState<string[]>([])

    const handleStartChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setStartDate(newValue)
    }

    const handleEndChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setEndDate(newValue)
    }

    const clearDates = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    }

    const handleSiteChange = (event: SelectChangeEvent<typeof selectedSites>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        if (newAssets.indexOf("Clear") >= 0) {
            setSelectedAssets([]);
        } else {
            setSelectedAssets(newAssets)
        }
    };

    useEffect(() => {
        const filterData: IShiftLogFilterData = {
            startDate,
            endDate,
            highPriority: highPriorityOnly,
            ongoing: ongoingOnly,
            siteIds: [...selectedSites],
        };

        onFiltersUpdated(filterData);
    }, [startDate, endDate, highPriorityOnly, ongoingOnly, selectedSites, onFiltersUpdated]);

    return (
        <Paper sx={{ p: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={2} direction="row" justifyContent="space-between" useFlexGap flexWrap="wrap">
                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => clearDates()}>
                            Clear Dates
                        </Button>
                        <Box width="200px">
                            <DateTimePicker
                                label="From"
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={startDate}
                                onChange={handleStartChange}
                                renderInput={(params: any) => {
                                    const paramsValue = params.inputProps.value;
                                    params.inputProps.value = !startDate ? "Start Date" : paramsValue;

                                    return <TextField {...params} fullWidth />
                                }}
                            />
                        </Box>
                        <Box width="200px">
                            <DateTimePicker
                                label="To"
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={endDate}
                                onChange={handleEndChange}
                                renderInput={(params: any) => {
                                    const paramsValue = params.inputProps.value;
                                    params.inputProps.value = !endDate ? "End Date" : paramsValue;

                                    return <TextField {...params} fullWidth />
                                }}
                            />
                        </Box>
                    </Stack>

                    <Box width="192px" height="55px" display="flex" flexDirection="column" justifyContent="center">
                        <FormControlLabel
                            control={<Switch checked={highPriorityOnly} onChange={(event, checked) => setHighPriorityOnly(checked)} />}
                            label="High Priority Only"
                        />
                    </Box>

                    <Box width="218px" height="55px" display="flex" flexDirection="column" justifyContent="center">
                        <FormControlLabel
                            control={<Switch checked={ongoingOnly} onChange={(event, checked) => setOngoingOnly(checked)} />}
                            label="Ongoing Events Only" />
                    </Box>

                    <FormControl sx={{ flexGrow: 1, minWidth: "200px" }}>
                        <InputLabel id="site-label">Sites</InputLabel>
                        <Select
                            labelId="site-label"
                            id="multiple-site"
                            multiple
                            label="Sites"
                            value={selectedSites}
                            onChange={handleSiteChange}
                            renderValue={(selected) => {
                                if (selectedSites.length === assets.length) {
                                    return "All Sites"
                                }

                                const maxNumberOfSites = 5
                                const renderedSites: string[] = []
                                selected.sort().slice(0, maxNumberOfSites).forEach((asset) => {
                                    renderedSites.push(assets.find((a) => a.SiteID === asset)?.Name || asset)
                                })
                                if (selected.length > maxNumberOfSites) {
                                    const difference = selected.length - maxNumberOfSites
                                    renderedSites.push(`+${difference}`)
                                }
                                return renderedSites.join(', ')
                            }
                            }
                        >
                            <MenuItem key={"Clear"} value={"Clear"}>Clear Filter</MenuItem>
                            <Divider />
                            {assets?.map((asset) => {
                                return (
                                    <MenuItem key={asset.SiteID} value={asset.SiteID}>
                                        {asset.Name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </LocalizationProvider>
        </Paper>
    );
}

export interface IShiftLogTableFiltersProps {
    assets?: IAssetInformation[];
    onFiltersUpdated: (filterData: IShiftLogFilterData) => void;
}

export default ShiftLogTableFilters;
