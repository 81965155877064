import moment from "moment";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getApiAccessToken } from "../auth/helpers";
import { ITAPortalHeartbeat } from "../types/analytics/trading-analytics/IIRISHeartbeatData";
import { IMarketUpdateSetting } from "../types/analytics/trading-analytics/IMarketUpdateSetting";
import { IMELPNAssetTriple } from "../types/analytics/trading-analytics/IMELPNAssetTriple";
import { ISystemSummaryData } from "../types/analytics/trading-analytics/ISystemSummaryData";
import { IDateTimeRequest } from "../types/shared/IDateTimeRequest";

export const tradingAnalyticsApi = createApi({
    reducerPath: 'tradingAnalyticsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PORTAL_API_URL}`,
        prepareHeaders: async (headers: Headers, { getState }) => {
            const accessToken = await getApiAccessToken();
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        }
    }),
    tagTypes: ["MarketUpdateSettings"],
    endpoints: (builder) => ({
        getMarketUpdateSettings: builder.query<IMarketUpdateSetting, void>({
            query: () => '/tradingAnalytics/market-update-settings',
            providesTags: ["MarketUpdateSettings"],
        }),
        updateMarketUpdateSettings: builder.mutation<void, IMarketUpdateSetting>({
            query: (payload) => ({
                url: '/tradingAnalytics/market-update-settings',
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ["MarketUpdateSettings"]
        }),
        getHeartbeatData: builder.query<ITAPortalHeartbeat<number>, void>({
            query: () => '/tradingAnalytics/get-portal-heartbeat',
        }),
    }),
})
export const { useGetMarketUpdateSettingsQuery, useUpdateMarketUpdateSettingsMutation, useGetHeartbeatDataQuery } = tradingAnalyticsApi

