import moment, { Moment } from "moment"
import { IBalancingSummaryData, IBalancingSummaryFuelValues, IBalancingSummaryPriceValues } from "../../../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
export const CalcXTicks = (domainStart: Moment, domainEnd: Moment, currentTime: Moment) => {
    let tick = moment(domainStart).startOf("hour").add(1, "hour")

    const newXTicks: number[] = []
    while (tick.isSameOrBefore(domainEnd)) {
        newXTicks.push(tick.valueOf())
        tick = moment(tick).add(1, "hour")
    }
    newXTicks.push(currentTime.valueOf())
    newXTicks.sort()
    return newXTicks
};

export const CalcYTicks = (chartData: IBalancingSummaryData[], keys: (keyof IBalancingSummaryFuelValues<any>)[] | (keyof IBalancingSummaryPriceValues<any>)[]) => {
    let max = 0
    let min = 0
    chartData.forEach(dataPoint => {
        let dataPointMax = 0
        let dataPointMin = 0
        keys.forEach(key => {
            const value = dataPoint[key]
            if (value <= 0) {
                dataPointMin += Math.abs(value)
            }
            else {
                dataPointMax += value
            }
        })
        max = max < dataPointMax ? dataPointMax : max
        min = min < dataPointMin ? dataPointMin : min
    })

    let absoluteMax = min < max ? max : min
    const interval = absoluteMax > 500 ? 500 :100
    absoluteMax = Math.round(absoluteMax / interval) * interval
    let minimum = 0 - absoluteMax
    const newYTicks: number[] = []
    let tick = minimum
    while (tick <= absoluteMax) {
        newYTicks.push(tick)
        tick += interval
    }
    return newYTicks
};