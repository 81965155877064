import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useAddAttributeSettingMutation, useDeleteAttributeSettingMutation, useUpdateAttributeSettingMutation } from '../../../shared/api/MetadataApi';
import { IMetadataAttributeSetting } from '../../../shared/types/system-admin/IMetadataAttributeSetting';
import { IAdvancedModalOptions, useModalWindowContext } from '../../ModalWindow';
import SiteMetadataAttributeModal from './SiteMetadataAttributeModal';
import TableToolbar, { ITableToolbarButton } from '../../shared/table/TableToolbar';

interface Props {
    params: GridRenderCellParams;
}

const SiteMetadataAttributesGrid: React.FC<ISiteMetadataAttributesGrid> = ({ settings }) => {
    const [selectedAttribute, setSelectedAttribute] = useState<IMetadataAttributeSetting | null>(null);
    const [updateAttribute] = useUpdateAttributeSettingMutation();
    const [addAttribute] = useAddAttributeSettingMutation();
    const [deleteAttribute] = useDeleteAttributeSettingMutation();
    const modal = useModalWindowContext();

    const columns: GridColDef[] = [
        { field: "name", headerName: "Name", minWidth: 250 },
        { field: "attributeName", headerName: "AF Attribute Name", minWidth: 350 },
        { field: "timeout", headerName: "Timeout (seconds)", minWidth: 200 },
        { field: "getRangedValue", headerName: "Get Last 30 mins of values", minWidth: 200, renderCell: (c) => c.value ? <Typography>True</Typography> : <Typography>False</Typography> },
        { field: "actions", type: "actions", headerName: "Actions",  renderCell: (c) => <ActionsCell params={c} />, disableColumnMenu: true, sortable: false },       
    ];

    const handleEditClick = (attribute: IMetadataAttributeSetting) => {
        setSelectedAttribute(attribute);        
    };

    const handleDialogClose = () => {
        setSelectedAttribute(null);
    };

    const handleDialogSave = (model: IMetadataAttributeSetting, isNew: boolean) => {        
        if (isNew) {
            addAttribute(model);
        }
        else {
            updateAttribute(model);
        }
    };

    const handleDeleteClick = async (model: IMetadataAttributeSetting) => {
        const options = {
            title: "Delete Attribute",
            content: `Delete ${model.name} attribute.  Are you sure?`,       
            yesText: "Yes",
            noText: "No"
        } as IAdvancedModalOptions;
        const modalResult = await modal.openModal(options);
        if (!modalResult) {
            return;
        }

        deleteAttribute(model);
    }

    const handleAddAttributeSetting = () => {
        setSelectedAttribute({} as IMetadataAttributeSetting);   
    }

    const ActionsCell = ({ params }: Props) => {
        const { name, attributeName, timeout } = params.row;
        const model = { name, attributeName, timeout } as IMetadataAttributeSetting;

        return (
            <Stack direction="row">
                <IconButton size="small" title="Edit attribute" onClick={(e) => handleEditClick(model)}>
                    <Edit />
                </IconButton>
                <IconButton size="small" title="Delete attribute" onClick={(e) => handleDeleteClick(model)}>
                    <Delete />
                </IconButton>
            </Stack>
        );
    };

    const actionButtons: ITableToolbarButton[] = [
        { tooltipText: "Add Attribute Setting", icon: <AddCircle />, action: handleAddAttributeSetting }
    ];

    return (
        <Paper>
            <TableToolbar title="Attribute Settings" buttons={actionButtons} />
            {settings && (
                <DataGrid
                    autoHeight
                    density="compact"
                    getRowId={(row) => row.name}
                    rows={settings}
                    columns={columns}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}                    
                />
            )}

            {selectedAttribute && (
                <SiteMetadataAttributeModal
                    attribute={selectedAttribute}
                    handleClose={handleDialogClose}
                    handleSave={handleDialogSave}
                />)
            }
        </Paper>
    );
}

export interface ISiteMetadataAttributesGrid {
    settings: IMetadataAttributeSetting[] | [];
}

export default SiteMetadataAttributesGrid;