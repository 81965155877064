import React, { useEffect } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { IImbalanceREMIT } from '../../../../shared/types/analytics/trading-analytics/IImbalanceREMIT';
import { formatFuelTypeToKey, GetExtendedFuelBackgroundColours, GetExtendedFuelTextColours, IFuelColoursDictionaryExtended } from '../../helpers/TradingAnalyticsFuelColours';
import moment from 'moment';

const headCells: HeadCell[] = [
    {
        id: "assetName",
        label: "Site Name"
    },
    {
        id: "startTime",
        label: "Outage Start Time"
    },
    {
        id: "endTime",
        label: "Outage End Time"
    },
    {
        id: "unavailableMW",
        label: "Unavailable MW"
    },
    {
        id: "link",
        label: "Link"
    }
]

const ImbalanceREMITTable: React.FC<IImbalanceREMITTable> = ({ items }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(true);
    const [orderCategory, setOrderCategory] = React.useState<keyof IImbalanceREMIT>("unavailableMW");

    const defaultTextColour = "default"
    const defaultBackgroundColour = "default"
    const fuelBackgroundColoursDictionary = GetExtendedFuelBackgroundColours()
    const fuelTextColoursDictionary = GetExtendedFuelTextColours()

    const sortItems = (inputArray: IImbalanceREMIT[], category: keyof IImbalanceREMIT, descending: boolean) => {
        const outputArray = [...inputArray]
        outputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            outputArray.reverse()
        }
        return outputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IImbalanceREMIT[]>(sortItems(items, orderCategory, orderDescending))

    const handleSort = (categoryID: keyof IImbalanceREMIT) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    const elexonLink = (bmuID: string) => {
        const url = "https://bmrs.elexon.co.uk/balancing-mechanism-bmu-view?bmuId=" + bmuID
        window.open(url);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => { if (headCell.id !== "link") { handleSort(headCell.id) } }}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {internalItems
                            .map((item) => {
                                const rowKey = "Fail-To-Deliv-table-key-" + item.assetID + "-" + item.dateTime + "-" + item.unavailableMW

                                let backgroundColour = defaultBackgroundColour
                                let textColour = defaultTextColour
                                const fuelKey = formatFuelTypeToKey(item.genType)
                                if (fuelKey in fuelBackgroundColoursDictionary) {
                                    const colourKey = fuelKey as keyof IFuelColoursDictionaryExtended
                                    backgroundColour = fuelBackgroundColoursDictionary[colourKey]
                                    textColour = fuelTextColoursDictionary[colourKey]
                                }

                                return (
                                    <TableRow key={rowKey}>
                                        {
                                            headCells.map((headCell) => {
                                                const cellValue = headCell.id === "link" ? "Link" : item[headCell.id]
                                                let cellContents = <>{cellValue.toString()}</>

                                                if (headCell.id === "link") {
                                                    cellContents = <Button onClick={() => elexonLink(item.assetID)}>{cellValue}</Button>
                                                }
                                                else if (headCell.id === "startTime" || headCell.id === "endTime") {
                                                    const dateTime = moment(cellValue).utc()
                                                    if (moment(dateTime).startOf("day").isSame(moment().utc().startOf("day"))) {
                                                        cellContents = <>{dateTime.format("HH:mm")}</>
                                                    }
                                                    else {
                                                        cellContents = <>{dateTime.format("YYYY-MM-DD HH:mm")}</>
                                                    }
                                                }
                                                return (
                                                    <TableCell
                                                        key={rowKey + "-" + headCell.id}
                                                        align="center"
                                                        sx={{
                                                            color: textColour,
                                                            backgroundColor: backgroundColour
                                                        }}
                                                    >
                                                        {cellContents}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
interface HeadCell {
    id: keyof IImbalanceREMIT | "link",
    label: string
}
export interface IImbalanceREMITTable {
    items: IImbalanceREMIT[]
}

export default ImbalanceREMITTable;