import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Add, Remove } from '@mui/icons-material';
import { IMarketUpdateSetting } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateSetting';

export const MessagesDisplayedSetter: React.FC<IMessagesDisplayedSetter> = ({ numberDisplayed, numberCanDisplay, dictKey, handleSettingChange }) => {
    const [disableAdd, setDisableAdd] = useState<boolean>(false)
    const [disableSubtract, setDisableSubtract] = useState<boolean>(false)

    const addClicked = () => {
        handleSettingChange(dictKey, numberDisplayed + 1)
    }

    const subtractClicked = () => {
        handleSettingChange(dictKey, numberDisplayed - 1)
    }

    useEffect(() => {
        setDisableAdd(numberDisplayed >= numberCanDisplay)
    }, [numberDisplayed, numberCanDisplay])

    useEffect(() => {
        setDisableSubtract(numberDisplayed <= 0)
    }, [numberDisplayed])

    return (
        <Stack justifyContent="center" direction="row" alignItems="center">
            <IconButton aria-label="add" onClick={addClicked} disabled={disableAdd} sx={{ fillOpacity: disableAdd ? 0.5 : 1 }}>
                <Add />
            </IconButton>
            <IconButton aria-label="subtract" onClick={subtractClicked} disabled={disableSubtract} sx={{ fillOpacity: disableSubtract ? 0.5 : 1 }}>
                <Remove />
            </IconButton>
        </Stack>
    );
}

export interface IMessagesDisplayedSetter {
    numberDisplayed: number,
    numberCanDisplay: number,
    dictKey: keyof IMarketUpdateSetting,
    handleSettingChange: (key: keyof IMarketUpdateSetting, value: number) => void
}

export const MELLimitSetter: React.FC<IMELLimitSetter> = ({ limit, handleSettingChange }) => {
    const [disableSubtract, setDisableSubtract] = useState<boolean>(false)
    const theme = useTheme()

    const addClicked = () => {
        handleSettingChange("melLimit", limit + 10)
    }

    const subtractClicked = () => {
        handleSettingChange("melLimit", limit - 10)
    }

    useEffect(() => {
        setDisableSubtract(limit <= 0)
    }, [limit])

    return (
        <Stack justifyContent="center" direction="row" alignItems="center">
            <IconButton aria-label="subtract" onClick={subtractClicked} disabled={disableSubtract} sx={{ fillOpacity: disableSubtract ? 0.5 : 1 }}>
                <Remove style={{ fill: theme.palette.error.main }}/>
            </IconButton>
            <>&gt;{limit} MW</>
            <IconButton aria-label="add" onClick={addClicked}>
                <Add style={{ fill: theme.palette.error.main }} />
            </IconButton>
        </Stack>
    );
}

export interface IMELLimitSetter {
    limit: number,
    handleSettingChange: (key: keyof IMarketUpdateSetting, value: number) => void
}

export const MarketUpdateTableCell: React.FC<IMarketUpdateTableCell> = ({ contents }) => {
    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            border: "2px solid white",
            borderRadius: "10px",
            p: 1
        }}>
            <Stack height="100%" justifyContent="center" width="100%">
                {contents.map(content =>
                    <Typography noWrap>{content}</Typography>
                )}
            </Stack>
        </Box>
    );
}

export interface IMarketUpdateTableCell {
    contents: JSX.Element[]
}