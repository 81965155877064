import { Box, Stack, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataFeedHealthLevel } from "../../../../shared/types/analytics/trading-analytics/DataFeedHealthLevel";
import Grid from '@mui/material/Unstable_Grid2';

const DataHealthFeedButton: React.FC<IDataHealthFeedButton> = ({ }) => {
    const theme = useTheme()
    const [healthLevel, setHealthLevel] = useState<DataFeedHealthLevel>(DataFeedHealthLevel.Healthy)
    const [warningColour, setWarningColour] = useState<string>(theme.palette.error.main)

    useEffect(() => {
        if (healthLevel === DataFeedHealthLevel.Healthy) {
            setWarningColour(theme.palette.conradEnergyFreshGreen.main)
        }
        else if (healthLevel === DataFeedHealthLevel.Warning) {
            setWarningColour(theme.palette.conradEnergyYellow.light)
        }
        else {
            setWarningColour(theme.palette.error.main)
        }
    }, [healthLevel])

    return (
        <Grid container spacing={0} height="100%">
            <Grid xs={8}>
                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    sx={{ height: "100%", width: "100%" }}
                    p={1}
                >
                    Data Feed Health:
                </Stack>
            </Grid>
            <Grid xs={4}>
                <Stack
                    direction="row"
                    justifyContent="left"
                    alignItems="center"
                    sx={{ height: "100%", width: "100%" }}
                    p={1}
                >
                    <Box sx={{ height: "50%", width: "80%", backgroundColor: warningColour, borderRadius: "10px" }} />
                </Stack>
            </Grid>
        </Grid>
    );
}
export interface IDataHealthFeedButton {
}

export default DataHealthFeedButton;