import { Box, Paper, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
const BalancingSummaryToggle: React.FC<IBalancingSummaryToggle> = ({ value, setValue, trueLabel, falseLabel }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" height="100%">
            <Typography variant="h6">{falseLabel}</Typography>
            <Switch
                checked={value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant="h6">{trueLabel}</Typography>
        </Stack>
    );
}
interface IBalancingSummaryToggle {
    value: boolean,
    setValue: (value: boolean) => void,
    trueLabel: string,
    falseLabel: string
}

const BalancingSummaryHeaderCell: React.FC<IBalancingSummaryHeaderCell> = ({ value, label }) => {
    return (
        <Box position="relative">
            <Paper sx={{ ml: "0.71em", mt: "-0.71em", px: "0.44em", zIndex: 2, position: "absolute", fontSize: "0.75em", boxShadow: "none" }}>{label}</Paper>
            <Box sx={{ height: "50px", borderRadius: 1, border: "1px solid white"}}>
                <Stack justifyContent="center" direction="column" alignItems="center" height="100%">
                    <Typography variant="h5">{value} MWh</Typography>
                </Stack>
            </Box>
        </Box>
    );
}
interface IBalancingSummaryHeaderCell {
    value: string,
    label: string
}

const BalancingSummaryHeader: React.FC<IBalancingSummaryHeader> = ({ currentNIV, currentSIP, currentBSAD, priceView, setPriceView }) => {
    
    return (
        <Grid container spacing={2} p={1}>
            <Grid xs={2}>
                <BalancingSummaryHeaderCell label={"Current NIV"} value={currentNIV.toFixed(0)} />
            </Grid>
            <Grid xs={2}>
                <BalancingSummaryHeaderCell label={"Current SIP"} value={currentSIP.toFixed(0)} />
            </Grid>
            <Grid xs={2}>
                <BalancingSummaryHeaderCell label={"Current BSAD"} value={currentBSAD.toFixed(0)} />
            </Grid>
            <Grid xs={6}>
                <BalancingSummaryToggle value={priceView} setValue={setPriceView} trueLabel={"Price"} falseLabel={"Fuel"} />
            </Grid>
        </Grid>
    );
}
export interface IBalancingSummaryHeader {
    currentNIV: number,
    currentSIP: number,
    currentBSAD: number,
    priceView: boolean,
    setPriceView: (priceView: boolean) => void
}

export default BalancingSummaryHeader;