import { Hexagon } from "react-hexgrid";
import { AssetType, ISiteStatus, SiteCommsStatus, SiteRunningStatus } from "../types/ISiteStatus";
import { CubicCoords, HexGridData, HexGridTile, HiveGenerationMode } from "../types/shared/hex-grid";
import { BatteryChargingFull, Bolt, GasMeter, Propane, WbSunny, WindPower } from "@mui/icons-material";
import { IStatusHivePlacementResult, StatusHiveDataTile, StatusHiveSiteData, StatusHiveTileStatus } from "../types/asset/status-hive";

const isIdle = (status: ISiteStatus): boolean => {
    return status.siteRunningStatus === SiteRunningStatus.NoPNNoRunAuto;
}

const getTileStatus = (status: ISiteStatus): StatusHiveTileStatus => {
    if (!isIdle(status)) { return StatusHiveTileStatus.Active; }

    const hourLimit = 12;
    let noDispatchPlan = true
    status.dispatchPlanNextThirtyMins.forEach(dispatchPlan => {
        if (dispatchPlan.value !== 0) {
            noDispatchPlan = false
        }
    })
    if (status.minutesToDispatch / 60 < hourLimit && (status.minutesToDispatch !== 0 || noDispatchPlan)) {
        return StatusHiveTileStatus.Pending;
    }

    // Idle if dipsatching in more than 12 hours or currently dispatching to a dispatch plan of 0
    return StatusHiveTileStatus.Idle;
}

const getAssetTypeIcon = (type?: AssetType) => {
    switch (type) {
        case AssetType.Battery:
            return BatteryChargingFull;
        case AssetType.Gas:
            return Propane;
        case AssetType.Diesel:
            return GasMeter;
        case AssetType.Solar:
            return WbSunny;
        case AssetType.Wind:
            return WindPower;
        default:
            return Bolt;
    }
}

const getCommsStatusIconData = (status?: SiteCommsStatus) => {
    switch (status) {
        case SiteCommsStatus.Healthy:
            return { colour: HiveColours.Running, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.Warning:
            return { colour: HiveColours.Warning, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.Error:
            return { colour: HiveColours.Error, tooltip: SiteCommsStatus[status] }
        case SiteCommsStatus.InMaintenance:
            return { colour: HiveColours.Manual, tooltip: "In Maintenance" }
        case SiteCommsStatus.OutOfService:
            return { colour: HiveColours.Idle, tooltip: "Out of Service" }
        default:
            return { colour: HiveColours.Idle, tooltip: "Unknown" }
    }
}

const getDispatchText = (minutesToDispatch: number): string => {
    if (minutesToDispatch === 0) {
        return `Dispatching...`;
    }

    let minutes = minutesToDispatch;
    let hours = 0;
    while (minutes >= 60) {
        minutes -= 60;
        hours += 1;
    }

    const hoursText = hours > 0 ? `${hours}hr ` : '';
    const minutesText = minutes > 0 || hours === 0 ? `${minutes}mins` : '';

    return `T - ${hoursText}${minutesText}`;
}

export const generateStatusHiveSiteData = (status: ISiteStatus): StatusHiveSiteData => {
    const siteData: StatusHiveSiteData = {
        ...status,
        priority: Number.MAX_SAFE_INTEGER,
        colour: HiveColours.Warning,
        statusHumanReadable: "Unknown",
        tileStatus: getTileStatus(status),
        assetIcon: getAssetTypeIcon(status.assetType),
        commsIconData: getCommsStatusIconData(status.commsHealthStatus),
        dispatchTimeHumanReadable: getDispatchText(status.minutesToDispatch),
        displayFrequencyServices: false,
        isManual: false,
        underOffer: false,
        flash: false,
        opacity: 1
    }

    siteData.isManual = status.manualDispatchStartSignal !== 0 || status.manualStart || status.manualStop
    siteData.underOffer = status.siteRunningStatus === SiteRunningStatus.GeneratingToOfferAuto

    let siteWarning = false;
    if (status.assetType !== AssetType.Battery && status.activePowerExportLastThirtyMins && status.dispatchPlanLastThirtyMins && status.activePowerExportLastThirtyMins.length > 0 && status.dispatchPlanLastThirtyMins.length > 0) {
        let activePowerValues = [...status.activePowerExportLastThirtyMins]
        let dispatchPlanValues = [...status.dispatchPlanLastThirtyMins]
        if (dispatchPlanValues.filter(x => x.value > 0).length > 0) {
            activePowerValues = activePowerValues.sort((a, b) => a.datetime < b.datetime ? -1 : 1)
            dispatchPlanValues = dispatchPlanValues.sort((a, b) => a.datetime < b.datetime ? -1 : 1)
            let activePowerIndex = 0
            let dispatchPlanIndex = 0
            let loadFactorBeenAboveNinety = false
            let alwaysGenerating = true
            while (activePowerIndex < activePowerValues.length && dispatchPlanIndex < dispatchPlanValues.length) {
                let activePowerValue = activePowerValues[activePowerIndex]
                let dispatchPlanValue = dispatchPlanValues[dispatchPlanIndex]

                let activePower = Math.abs(activePowerValue.value)
                let dispatchPlan = Math.abs(dispatchPlanValue.value)
                if (activePower <= 0) {
                    alwaysGenerating = false;
                    break
                }
                if (dispatchPlan > 0) {
                    let loadFactor = activePower / dispatchPlan
                    if (loadFactor > 0.9) {
                        loadFactorBeenAboveNinety = true
                        break
                    }
                }
                if (activePowerValue.datetime < dispatchPlanValue.datetime) {
                    if (activePowerIndex === activePowerValues.length) { dispatchPlanIndex++ }
                    else { activePowerIndex++ }
                }
                else {
                    if (dispatchPlanIndex === dispatchPlanValues.length) { activePowerIndex++ }
                    else { dispatchPlanIndex++ }
                }

            }

            if (alwaysGenerating && !loadFactorBeenAboveNinety) {
                siteWarning = true
            }
        }
    }

    if (siteData.assetType === AssetType.Battery) {
        siteData.priority = 1;
        if (siteData.underOffer) { // Site under offer
            siteData.colour = HiveColours.UnderOffer;
            siteData.statusHumanReadable = (status.boaAmount !== undefined ? (status.boaAmount || 0).toFixed(1) : "?") + " MW (Offer)";
        }
        else if (status.activePowerExport > 0.1 || Math.abs(status.activePowerImport) > 0.1) { // If battery is running
            siteData.displayFrequencyServices = true
            siteData.colour = HiveColours.Running;
            if (status.siteRunningStatus === SiteRunningStatus.UnScheduledRunAuto) { // If battery is running not uncontracted
                siteData.borderColour = HiveColours.Manual;
            }
        }
        else { // Battery isn't running
            siteData.colour = HiveColours.Idle;
            siteData.statusHumanReadable = "Idle";
            siteData.opacity = 0.5;
        }
    }
    else if (siteData.isManual) { // Site running manual
        siteData.priority = 3;
        siteData.colour = HiveColours.Manual;
        siteData.statusHumanReadable = "Manual";
        siteData.flash = true;
    }
    else if (siteData.underOffer) { // Site under offer
        siteData.priority = 5;
        siteData.colour = HiveColours.UnderOffer;
        siteData.statusHumanReadable = (status.boaAmount !== undefined ? (status.boaAmount || 0).toFixed(1) : "?") + " MW (Offer)";
    }
    else if (status.siteRunningStatus === SiteRunningStatus.DNODispatch) { // Site under DNO
        siteData.priority = 4;
        siteData.colour = HiveColours.DNO;
        siteData.statusHumanReadable = "DNO";
    }
    else if (status.siteRunningStatus === SiteRunningStatus.UnScheduledRunAuto) { // Site unscheduled
        siteData.priority = 4;
        siteData.colour = HiveColours.Unscheduled;
        siteData.statusHumanReadable = "Uncontracted";
    }
    else if (status.siteRunningStatus === SiteRunningStatus.FailedPNRunAuto) { // Site failed PN Run
        siteData.priority = 4;
        siteData.colour = HiveColours.Error;
        siteData.statusHumanReadable = "Unavailable";
    }
    else if (status.siteRunningStatus === SiteRunningStatus.GeneratingToPNAuto) {
        if (siteWarning) {// Site is failing to deliver
            siteData.priority = 4;
            siteData.colour = HiveColours.FailedToDeliver;
            siteData.statusHumanReadable = (status.activePowerExport || 0).toFixed(1) + " MW";
        }
        else {
            siteData.priority = 2;
            siteData.colour = HiveColours.Running;
            siteData.statusHumanReadable = (status.activePowerExport || 0).toFixed(1) + " MW";
        }
    }
    else {
        siteData.colour = HiveColours.Idle;
        if (status.siteRunningStatus === SiteRunningStatus.NoPNNoRunAuto) {
            if (siteData.tileStatus === StatusHiveTileStatus.Idle) {
                siteData.statusHumanReadable = "Idle";
                siteData.priority = 6;
                siteData.opacity = 0.5;
            } else {
                siteData.priority = 5; // Tiles displaying dispatch times are higher priority
                siteData.valueTextColour = HiveColours.Running;
                siteData.statusHumanReadable = siteData.dispatchTimeHumanReadable;
            }
        }
        else {
            siteData.priority = 6;
            siteData.statusHumanReadable = "Unknown";
            siteData.opacity = 0.5;
        }
    }

    return siteData;
}

export const createBackgroundTile = (tile: HexGridTile): JSX.Element => {
    return (
        <Hexagon
            q={tile.coords.q}
            r={tile.coords.r}
            s={tile.coords.s}
            fill={tile.isOffscreen ? "hatching" : "none"}
            stroke="black"
            strokeOpacity={0.25}
            strokeWidth={"0.04em"}
            key={tile.coords.value}
            id={tile.coords.value}
        />
    );
}

const sortSiteStatusData = (a: StatusHiveSiteData, b: StatusHiveSiteData) => {
    if (a.priority < b.priority) {
        return -1;
    }
    if (a.priority > b.priority) {
        return 1;
    }

    if (a.siteId < b.siteId) {
        return -1;
    }
    if (a.siteId > b.siteId) {
        return 1;
    }

    return 0;
}

export const processData = (statuses: ISiteStatus[]): StatusHiveSiteData[] => {
    const data: StatusHiveSiteData[] = statuses.map((status) => {
        return { ...status, ...generateStatusHiveSiteData(status) }
    });

    return data.sort(sortSiteStatusData);
}

export const generateDataTiles = (siteData: StatusHiveSiteData[], grid: HexGridData, generationMode: HiveGenerationMode, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    switch (generationMode) {
        case HiveGenerationMode.Clusters:
            return placeTilesClusters(siteData, grid, reserveCentreTile);
        case HiveGenerationMode.Dispatch:
            return placeTilesDispatch(siteData, grid, reserveCentreTile);
        case HiveGenerationMode.Hive:
        default:
            return placeTilesHive(siteData, grid, reserveCentreTile);
    }
}

const tilePlacement = (data: StatusHiveSiteData, getViableTiles: (data: StatusHiveSiteData) => HexGridTile[], grid: HexGridData) => {
    const currentViableTiles = getViableTiles(data);
    let tileToPlace: HexGridTile | undefined;
    for (var i = currentViableTiles.length; i--;) {
        if (currentViableTiles[i].canPlace()) {
            tileToPlace = currentViableTiles.pop();
            break;
        }

        currentViableTiles.splice(i, 1);
    }

    if (!tileToPlace) {
        return null;
    }

    const neighbourCoords = tileToPlace.getNeighbours();
    currentViableTiles.unshift(...grid.getTilesAtCoords(neighbourCoords));

    const dataTile = new StatusHiveDataTile(tileToPlace.coords, data);
    tileToPlace.isOccupied = true;
    return dataTile;
}

const failedTilePlacement = (failed: StatusHiveSiteData[], mapped: Set<string>, grid: HexGridData) => {
    if (failed.length <= 0) { return [] };

    const placed: StatusHiveDataTile[] = [];

    const unoccupiedTiles = grid.getUnoccupiedTiles();
    for (var i = failed.length; i--;) {
        if (unoccupiedTiles.length <= 0) { break; }

        const data = failed[i];

        if (mapped.has(data.siteId)) {
            console.warn("Duplicate site ID found.");
            continue;
        }

        const tileToPlace = unoccupiedTiles.pop();
        if (!tileToPlace) {
            continue;
        }

        const dataTile = new StatusHiveDataTile(tileToPlace.coords, data);
        placed.push(dataTile);
        tileToPlace.isOccupied = true;
        mapped.add(data.siteId);

        failed.pop();
    };

    return placed;
}

const generateHexCoordArray = (xStart: number, xEnd: number, yStart: number, yEnd: number, secondRowBigger: boolean = false): CubicCoords[] => {
    const coords: CubicCoords[] = []
    for (let y = yStart; y < yEnd; y++) {
        let x = xStart
        if ((y % 2 === 0) === secondRowBigger) {
            x += 0.5
        }
        while (x < xEnd) {
            coords.push(cartesianToHexCoords(x, y))
            x++;
        }
    }
    return coords
}

const mapSiteToCoord = (sites: StatusHiveSiteData[], coords: CubicCoords[]) => {
    const dataTiles: StatusHiveDataTile[] = [];
    const failed: StatusHiveSiteData[] = [];
    for (let i = 0; i < sites.length; i++) {
        const data = sites[i]
        if (i >= coords.length) {
            failed.push(data)
        }
        else {
            const coord = coords[i]
            const dataTile = new StatusHiveDataTile(coord, data)
            dataTiles.push(dataTile);
        }
    }
    return { dataTiles, failed }
}


const placeTilesDispatch = (siteData: StatusHiveSiteData[], grid: HexGridData, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    const { height: gridHeight } = grid.getGridDimenions()
    const { centre, topLeft, topRight } = grid.getAnchorPoints();

    // Getting X coordinates for centre and topRight hexes
    const { x: centreX } = hexToCartesianCoords(centre)
    const { x: topRightX } = hexToCartesianCoords(topRight)

    // Filtering duplicate sites
    const mapped = new Set<string>();
    const filteredSites: StatusHiveSiteData[] = []
    siteData.forEach(site => {
        if (!mapped.has(site.siteId)) {
            mapped.add(site.siteId)
            filteredSites.push(site)
        }
    })

    filteredSites.sort((a,b) => a.siteId < b.siteId ? -1 : 1)

    // Splitting BMU vs nonBMU sites
    const bmuSites = filteredSites.filter(site => site.bmu !== "NONBM" && site.bmu !== "")
    const nonBmuSites = filteredSites.filter(site => site.bmu === "NONBM" || site.bmu === "")

    // Creating array of hex coords for BMU and nonBMU sites
    let bmuCoords: CubicCoords[] = []
    let nonBmuCoords: CubicCoords[] = []

    const divideX = centreX - 2

    bmuCoords = generateHexCoordArray(0, divideX, 0, gridHeight)
    nonBmuCoords = generateHexCoordArray(divideX + 1, topRightX+ 0.5, 0, gridHeight, true)


    // Filter out the centre tile and the dispatch button tile
    bmuCoords = bmuCoords.filter(coord => !coord.equals(centre) && !coord.equals(topLeft))
    nonBmuCoords = nonBmuCoords.filter(coord => !coord.equals(centre) && !coord.equals(topLeft))

    // Map sites to coords
    const { dataTiles: bmuTiles, failed: bmuFailed } = mapSiteToCoord(bmuSites, bmuCoords)
    const { dataTiles: nonBmuTiles, failed: nonBmuFailed } = mapSiteToCoord(nonBmuSites, nonBmuCoords)
    const dataTiles = [...bmuTiles, ...nonBmuTiles];
    const failed = [...bmuFailed, ...nonBmuFailed];
    
    return { placed: dataTiles, failed };
}

const cartesianToHexCoords = (x: number, y: number) => {
    const r = y
    const s = (0 - x) - (0.5 * y)
    const q = (0 - r) - s
    return new CubicCoords(q,r,s)
}

const hexToCartesianCoords = (cubicCoords: CubicCoords) => {
    const q = cubicCoords.q
    const r = cubicCoords.r
    const s = cubicCoords.s
    if (q + r + s !== 0) {
        return { x: 0, y: 0 }
    }
    const y = r
    const x = 0 - ((0.5 * r) + s)
    return { x: x, y: y }
}

const placeTilesHive = (siteData: StatusHiveSiteData[], grid: HexGridData, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    const dataTiles: StatusHiveDataTile[] = [];

    const currentViableTiles: HexGridTile[] = [];

    const { centre } = grid.getAnchorPoints();
    const centreTile = grid.getTileAtCoords(centre);
    if (!centreTile) {
        return { placed: [], failed: siteData };
    }

    if (!reserveCentreTile) {
        currentViableTiles.push(centreTile);
    } else {
        centreTile.isOccupied = true;
        const neighbourCoords = centreTile.getNeighbours();
        currentViableTiles.unshift(...grid.getTilesAtCoords(neighbourCoords));
    }

    const mapped = new Set<string>();
    const failed: StatusHiveSiteData[] = [];
    siteData.forEach((data) => {
        if (mapped.has(data.siteId)) {
            console.log("Duplicate site ID found.");
            return;
        }

        const dataTile = tilePlacement(data, () => currentViableTiles, grid);
        if (!dataTile) {
            failed.push(data);
        } else {
            mapped.add(data.siteId);
            dataTiles.push(dataTile);
        }
    });

    const bruteForced = failedTilePlacement(failed, mapped, grid);
    dataTiles.push(...bruteForced);

    return { placed: dataTiles, failed };
}

const placeTilesClusters = (siteData: StatusHiveSiteData[], grid: HexGridData, reserveCentreTile?: boolean): IStatusHivePlacementResult => {
    const dataTiles: StatusHiveDataTile[] = [];

    const viableClusterC: HexGridTile[] = [];
    const viableClusterTL: HexGridTile[] = [];
    const viableClusterTR: HexGridTile[] = [];
    const viableClusterBL: HexGridTile[] = [];
    const viableClusterBR: HexGridTile[] = [];

    const { centre, topLeft, topRight, bottomLeft, bottomRight } = grid.getAnchorPoints();
    const centreTile = grid.getTileAtCoords(centre);

    if (centreTile) {
        if (!reserveCentreTile) {
            viableClusterC.push(centreTile);
        } else {
            centreTile.isOccupied = true;
            const neighbourCoords = centreTile.getNeighbours();
            viableClusterC.unshift(...grid.getTilesAtCoords(neighbourCoords));
        }
    }


    const topLeftTile = grid.getTileAtCoords(topLeft);
    if (topLeftTile) {
        topLeftTile.isOccupied = true;
        const neighbourCoords = topLeftTile.getNeighbours();
        viableClusterTL.unshift(...grid.getTilesAtCoords(neighbourCoords));
    }

    const topRightTile = grid.getTileAtCoords(topRight);
    topRightTile && viableClusterTR.push(topRightTile);

    const bottomLeftTile = grid.getTileAtCoords(bottomLeft);
    bottomLeftTile && viableClusterBL.push(bottomLeftTile);

    const bottomRightTile = grid.getTileAtCoords(bottomRight);
    bottomRightTile && viableClusterBR.push(bottomRightTile);

    if (centreTile && reserveCentreTile) {
        centreTile.isOccupied = true;
    }

    const getCluster = (data: StatusHiveSiteData): HexGridTile[] => {
        switch (data.siteRunningStatus) {
            case SiteRunningStatus.GeneratingToBidAuto:
            case SiteRunningStatus.GeneratingToOfferAuto:
                return viableClusterTR;
            case SiteRunningStatus.DNODispatch:
                return viableClusterTL;
            case SiteRunningStatus.UnScheduledRunAuto:
                return viableClusterBL;
            case SiteRunningStatus.FailedPNRunAuto:
                return viableClusterBR;
            default:
                return viableClusterC;
        }
    }

    const mapped = new Set<string>();
    const failed: StatusHiveSiteData[] = [];
    siteData.forEach((data) => {
        if (mapped.has(data.siteId)) {
            console.log("Duplicate site ID found.");
            return;
        }

        const dataTile = tilePlacement(data, getCluster, grid);
        if (!dataTile) {
            failed.push(data);
        } else {
            mapped.add(data.siteId);
            dataTiles.push(dataTile);
        }
    });

    const bruteForced = failedTilePlacement(failed, mapped, grid);
    dataTiles.push(...bruteForced);

    return { placed: dataTiles, failed };
}

export const HiveColours = {
    Running: "#32CD32",
    Manual: "#00BFFF",
    DNO: "#9932CC",
    RunningBid: "#4169E1",
    RunningOffer: "#F08080",
    Unscheduled: "#FFD700",
    Idle: "#808080",
    Error: "#FF4500",
    Warning: "#FFA500",
    ION: "#F15B24",
    UnderOffer: "#FF0000",
    FailedToDeliver: "#FFC000"
}

export const GetUKLayout = (centreCoords: CubicCoords): CubicCoords[] => {
    const returnVal = [centreCoords];

    returnVal.push(new CubicCoords(centreCoords.q, centreCoords.r - 1, centreCoords.s + 1));
    returnVal.push(new CubicCoords(centreCoords.q, centreCoords.r + 1, centreCoords.s - 1));
    returnVal.push(new CubicCoords(centreCoords.q - 1, centreCoords.r + 1, centreCoords.s));

    return returnVal;
}
