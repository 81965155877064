import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import Icon from '@mdi/react';
import { mdiChartBellCurveCumulative } from '@mdi/js';
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import BalancingSummaryWrapper from "../../../components/analytics/trading-analytics/balancing-summary/BalancingSummaryWrapper";

const BalancingSummaryPage: React.FC<IBalancingSummaryPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Balancing Summary - Dummy Data" loaded={loaded} contentOverflow={"hidden"}>
            <BalancingSummaryWrapper />
        </PageWrapper>
    );
}


export interface IBalancingSummaryPage {

}

const BalancingSummaryPageConfig: IPortalRouteOptions = {
    relativeUrl: "balancing-summary",
    page: <BalancingSummaryPage />,
    navDisplay: {
        title: "Balancing Summary",
        icon: <Icon path={mdiChartBellCurveCumulative} size={1} />
    },
}

export default BalancingSummaryPageConfig;