import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { IFailureToDeliverDataFormatted } from '../../../../shared/types/analytics/trading-analytics/IFailureToDeliverData';

const CurrentImbalanceDisplay: React.FC<ICurrentImbalanceDisplay> = ({ data }) => {
    const theme = useTheme()
    const minorError = theme.palette.error.light
    const error = theme.palette.error.main
    const majorError = theme.palette.error.dark

    const [backgroundColor, setBackgroundColor] = useState<string>("white")

    useEffect(() => {
        if (data.total < 200) {
            setBackgroundColor("white")
        }
        else if (data.total < 500) {
            setBackgroundColor(minorError)
        }
        else if (data.total < 1000) {
            setBackgroundColor(error)
        }
        else {
            setBackgroundColor(majorError)
        }
    }, [data])

    return (
        <Box sx={{
            backgroundColor: backgroundColor,
            width: "200px",
            borderRadius: "10px",
            p: 1,
            border: "2px solid white"
        }}>
            <Stack width="100%" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="black">Current Imbalance</Typography>
                <Typography variant="h6" color="black">{data.total} MW</Typography>
            </Stack>
        </Box>
    );
}
export interface ICurrentImbalanceDisplay {
    data: IFailureToDeliverDataFormatted
}

export default CurrentImbalanceDisplay;