import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Text } from 'react-hexgrid';
import { useColourModeContext } from '../ColourMode';

export const HexMultiLineText: React.FC<IHexMultiLineTextProps> = ({ id = crypto.randomUUID(), text: siteName, colour, x = 0, y = 0, backgroundColor, borderColor, fontSize = 0.09, fontWeight, maxChars = 12, breakSingleWord = false, onClick }) => {
    const { isDarkMode } = useColourModeContext();
    const theme = useTheme();
    const [internalBackgroundColor, setInternalBackgroundColor] = useState<string>()
    const bottomLineLength = maxChars;
    let topLine = "";
    let bottomLine = "";
    if (!breakSingleWord && !siteName.includes(' ')) { // Don't break the text at all if there are no spaces and breakSingleWord is disabled
        bottomLine = siteName;
    } else if (siteName.length <= bottomLineLength) { // Don't do anything if the full string is less than the character limit
        bottomLine = siteName;
    } else { // Split the text into two lines
        const spaceIndex = siteName.indexOf(" ", siteName.length - (bottomLineLength + 1));
        bottomLine = siteName.slice(spaceIndex, siteName.length).trimStart();
        topLine = siteName.slice(0, spaceIndex).trimEnd();
    }

    const wordSpacing = 1.25;

    const bottomY = topLine ? y + (wordSpacing / 2) : y;
    const topY = y - (wordSpacing / 2);

    useEffect(() => {
        if (backgroundColor) { setInternalBackgroundColor(backgroundColor) }
        else { setInternalBackgroundColor(isDarkMode ? theme.palette.common.black : theme.palette.common.white) }
    }, [backgroundColor, isDarkMode, theme])

    return (
        <>
            {borderColor &&
                <defs>
                    <filter x="-0.5" y="0" width="2" height="1" id={id + "filter"}>
                        <feFlood floodColor={internalBackgroundColor} result="bg" />
                        <feMerge>
                            <feMergeNode in="bg" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter x="-0.6" y="-0.1" width="2.2" height="1.2" id={id + "border"}>
                        <feFlood floodColor={borderColor} />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
            }
            <g filter={`url(#${id + "border"})`} style={{ cursor: "pointer"} }>
                <Text style={{ border: "1px solid white" }} filter={`url(#${id + "filter"})`} fontWeight={fontWeight} x={`${x}em`} y={`${topY}em`} width={20} fill={colour} fontSize={`${fontSize}em`} strokeWidth={"0"} onClick={onClick}>
                    {topLine}
                </Text>
            </g>
            <g filter={`url(#${id + "border"})`} style={{ cursor: "pointer" }}>
                <Text style={{ border: "1px solid white" }} filter={`url(#${id + "filter"})`} fontWeight={fontWeight} x={`${x}em`} y={`${bottomY}em`} width={20} fill={colour} fontSize={`${fontSize}em`} strokeWidth={"0"} onClick={onClick}>
                    {bottomLine}
                </Text>
            </g>
        </>
    );
}

export interface IHexMultiLineTextProps {
    id?: string;
    text: string;
    colour?: string;
    x?: number;
    y?: number;
    backgroundColor?: string;
    borderColor?: string;
    fontSize?: number;
    fontWeight?: string | number;
    maxChars?: number;
    breakSingleWord?: boolean;
    onClick?: () => void
}
