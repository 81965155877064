import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { INIVData } from "../../../../shared/types/analytics/trading-analytics/INIVData";
import NIVTableActualForecastPicker from "./NIVTableActualForecastPicker";

const NIVTableCell: React.FC<PropsWithChildren<INIVTableCell>> = ({ children, xs, header = false, backgroundColor = "default" }) => {
    const theme = useTheme()
    const headerColour = theme.palette.primary.main
    return (

        <Grid
            xs={xs}
        >
            <Typography
                textAlign="center"
                sx={{
                    border: header ? "default" : "2px solid white",
                    borderRadius: "10px",
                    backgroundColor: header ? headerColour : backgroundColor,
                    height: "100%",
                    width: "100%", 
                }}
            >
                {children}
            </Typography>
        </Grid>
    );
}

interface INIVTableCell {
    xs: number
    header?: boolean
    backgroundColor?: string
}

const NIVTable: React.FC<INIVTable> = ({ nivData }) => {
    const theme = useTheme()
    const [shortProbColour, setShortProbColor] = useState<string>(theme.palette.error.main)
    const [actualOrForecast, setActualOrForecast] = useState<string>("Actual")

    useEffect(() => {
        const shortProb = nivData.probOfShort
        if (shortProb <= 0.4) {
            setShortProbColor(theme.palette.error.main)
        }
        else if (shortProb <= 0.6) {
            setShortProbColor(theme.palette.conradEnergyFreshGreen.light)
        }
        else if (shortProb <= 0.8) {
            setShortProbColor(theme.palette.conradEnergyFreshGreen.main + "90")
        }
        else {
            setShortProbColor(theme.palette.conradEnergyFreshGreen.dark + "80")
        }
    }, [nivData])

    return (
        <Box width="100%">
            <Paper >
                <Grid
                    container
                    columns={14}
                    spacing={2}
                    sx={{ padding: 1 }}
                >
                    <NIVTableActualForecastPicker actualOrForecast={actualOrForecast} setActualOrForecast={setActualOrForecast} />
                    <NIVTableCell xs={2} header={true}>-500MW</NIVTableCell>
                    <NIVTableCell xs={2} header={true}>-200MW</NIVTableCell>
                    <NIVTableCell xs={2} header={true}>-100MW</NIVTableCell>
                    <NIVTableCell xs={1}>{nivData.actualPower + "MW"}</NIVTableCell>
                    <NIVTableCell xs={1}>{nivData.forecastPower + "MW"}</NIVTableCell>
                    <NIVTableCell xs={2} header={true}>+100MW</NIVTableCell>
                    <NIVTableCell xs={2} header={true}>+200MW</NIVTableCell>
                    <NIVTableCell xs={2} header={true}>+500MW</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.minusFivePrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.minusTwoPrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.minusOnePrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={1}>&pound;{nivData.actualPrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={1}>&pound;{nivData.forecastPrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.plusOnePrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.plusTwoPrice.toFixed(2)}</NIVTableCell>
                    <NIVTableCell xs={2}>&pound;{nivData.plusFivePrice.toFixed(2)}</NIVTableCell>
                    <Grid xs={6} />
                    <NIVTableCell xs={2} header={true}>Probability of Short</NIVTableCell>
                    <Grid xs={6} />
                    <Grid xs={6} />
                    <NIVTableCell xs={2} backgroundColor={shortProbColour}>{(nivData.probOfShort*100).toFixed(2)}%</NIVTableCell>
                    <Grid xs={6} />
                </Grid>
            </Paper>
        </Box>
    );
}
export interface INIVTable {
    nivData: INIVData
}

export default NIVTable;