import React from "react";
import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";

const OutageFormDate: React.FC<IOutageFormDate> = ({ date, setDate, disabled = false, minDate, maxDate, label = "" }) => {

    const handleDateChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setDate(newValue)
    }

    return (
        <Box>
            <DatePicker
                label={label}
                inputFormat="DD/MM/YYYY"
                value={date}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleDateChange}
                disabled={disabled}
                renderInput={(params) => <TextField {...params}
                    fullWidth
                />}
            />
        </Box>
    );
}
export interface IOutageFormDate {
    label?: string
    date: Moment
    setDate: (date: Moment) => void
    disabled?: boolean
    minDate?: Moment
    maxDate?: Moment
}

export default OutageFormDate;