import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";

const FuelSelector: React.FC<IFuelSelector> = ({ selectedTypes, setSelectedTypes, fullFuelList = false }) => {
    const [fuelOptions, setFuelOptions] = useState<string[]>([])

    const handleTypesChange = (event: SelectChangeEvent<typeof selectedTypes>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        setSelectedTypes(newAssets)
    };

    const fuelTypes = [
        "Flex Gen",
        "OCGT",
        "Pumped",
        "Battery",
        "Biomass",
        "Coal",
        "BSAD",
        "CCGT",
        "Wind",
        "Hydro"
    ]

    const extraFuelTypes = [
        "Diesel",
        "Nuclear",
        "Gas",
        "Solar",
        "Interconnectors",
        "Oil"
    ]

    useEffect(() => {
        let newFuelOptions = fuelTypes
        if (fullFuelList) {
            newFuelOptions = [...fuelTypes, ...extraFuelTypes]
        }
        newFuelOptions.sort()
        setFuelOptions(newFuelOptions)
    }, [fullFuelList])

    return (
        <FormControl fullWidth sx={{ height: "100%"} }>
            <InputLabel id="fuel-type-label">Fuel Type</InputLabel>
            <Select
                sx={{ height: "100%"} }
                labelId="fuel-type-label"
                id="multiple-fuel-type"
                multiple
                label="Fuel Types:"
                value={selectedTypes}
                onChange={handleTypesChange}
                renderValue={(selected) => {
                    if (selected.indexOf('All') > -1) { return 'All' }
                    let shownTypes: string[] = []
                    fuelTypes.forEach((type) => {
                        if (selected.indexOf(type) > -1) {
                            shownTypes.push(type)
                        }
                    })
                    return shownTypes.join(', ')
                }
                }
            >
                <MenuItem key={'All'} value={'All'}>{'All'}</MenuItem>
                {
                    fuelOptions.map((type) =>
                        <MenuItem key={"fuel-type-option-" + type} value={type} disabled={selectedTypes.indexOf('All') > -1}>{type}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    );
}

interface IFuelSelector {
    selectedTypes: string[],
    setSelectedTypes: (selectedTypes: string[]) => void,
    fullFuelList? : boolean
}

export default FuelSelector;