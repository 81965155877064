import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSymbol from "../../../operate/LoadingSymbol";
import { Area, AreaChart, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useColourModeContext } from "../../../../components/ColourMode";
import { DateTimeFormatter, TimeFormatter } from "../../helpers/DateFormatter"
import { IFailureToDeliverDataFormatted } from "../../../../shared/types/analytics/trading-analytics/IFailureToDeliverData";
import { GetExtendedFuelBackgroundColours } from "../../helpers/TradingAnalyticsFuelColours";
import moment, { Moment } from "moment";
import FailureToDeliverPopup from "./FailureToDeliverPopup";
import { IAdvancedModalOptions, useModalWindowContext } from "../../../ModalWindow";
import { GetLiveImbalanceData } from "../../helpers/TradingAnalyticsData";

const FailureToDeliverChart: React.FC<IFailureToDeliverChart> = ({ chartData, currentTime }) => {
    const colourMode = useColourModeContext();
    const { openModal } = useModalWindowContext();
    const colourDict = GetExtendedFuelBackgroundColours();
    const [domainStart, setDomainStart] = useState<number>(moment.utc().startOf("hour").subtract(2, "hours").valueOf())
    const [domainEnd, setDomainEnd] = useState<number>(moment.utc().startOf("hour").add(1, "hours").valueOf())
    const [ticks, setTicks] = useState<number[]>([])

    useEffect(() => {
        // Setting domain
        const newStart = moment(currentTime).subtract(8, "hours").valueOf()
        const newEnd = moment(currentTime).add(1, "hours").valueOf()
        setDomainStart(newStart)
        setDomainEnd(newEnd)

        // Setting ticks
        let tick = moment(newStart)
        if (tick.minutes() >= 30) {
            tick = moment(tick).endOf("hour")
        }
        else {
            tick = moment(tick).startOf("hour").add(30, "minutes")
        }

        const newTicks = []
        while (tick.isSameOrBefore(newEnd)) {
            newTicks.push(tick.valueOf())
            tick = moment(tick).add(30, "minutes")
        }
        setTicks(newTicks)
    }, [currentTime])

    const displayKeys: (keyof IFailureToDeliverDataFormatted)[] = [
        "pumped",
        "battery",
        "oil",
        "flexgen",
        "peat",
        "ccgt",
        "coal",
        "biomass",
        "hydro",
        "wind",
        "solar",
        "nuclear",
        "synchronouscondenser",
        "total",
        "interconnectors"
    ]

    const displayedNames = [
        "Pumped Storage",
        "Battery",
        "Oil",
        "Flex Gen",
        "Peat",
        "CCGT",
        "Coal",
        "Biomass",
        "Hydro",
        "Wind",
        "Solar",
        "Nuclear",
        "Synchronous Condenser",
        "Total (Less Interconnectors)",
        "Interconnectors"
    ]

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload
            return (
                <Box sx={{
                    "backgroundColor": (colourMode.isDarkMode ? "#262626" : "white"),
                    "borderRadius": 5,
                    "border": (colourMode.isDarkMode ? "1px solid white" : "1px solid #262626"),
                    px: 1,
                    py: 0.5
                }} >
                    <p style={{ color: (colourMode.isDarkMode ? "white" : "black") }}>Date (Local Time): {DateTimeFormatter(data.dateTime)}</p>
                    {displayKeys.map((key, index) => {
                        if (key !== "dateTime") {
                            const name = displayedNames[index]
                            const color = colourDict[key]
                            const value = data[key].toFixed(0)
                            return (
                                <p key={"Imbalance-tooltip-" + name}>
                                    <Stack direction="row" spacing={0.5}>
                                        <span style={{ backgroundColor: color, width: "20px" }}></span>
                                        <span>{name}: {value} MW</span>
                                    </Stack>
                                </p>
                            )
                        }
                        return (<></>)
                    })}
                </Box>
            );
        }
        return null
    };

    const handleClick = async (clickedData: IFailureToDeliverDataFormatted) => {
        const dateTime = clickedData.dateTime
        const imbalanceData = GetLiveImbalanceData(dateTime)
        const popupComponent = (
            <FailureToDeliverPopup failuresAtTime={imbalanceData} />
        );

        const options: IAdvancedModalOptions = {
            title: "Failures to deliver at " + DateTimeFormatter(dateTime),
            contentComponent: popupComponent,
            noText: "Close",
            fullWidth: true,
            maxWidth: 'lg',
            canSubmit: true,
        }

        await openModal(options);
    }

    const CustomizedDot = (props: any) => {
        const { cx, cy, payload } = props;
        return (
            <circle cx={cx - 10} cy={cy - 10} r={5000} opacity={0} onClick={async () => await handleClick(payload)} />
        );
    };

    const renderAreas = () => {
        const areas = displayKeys.map((key, index) => {
            if (key !== "dateTime") {
                const name = displayedNames[index]
                const color = colourDict[key]
                if (key === "total") {
                    return (
                        <Area dataKey={key} key={key} name={name} stackId={"2"} unit=" MW" fill={color} stroke={color} strokeWidth={5} type="monotone" opacity={1} fillOpacity={0} isAnimationActive={false} activeDot={<CustomizedDot />} />
                    )
                }
                else {
                    return (
                        <Area dataKey={key} key={key} name={name} stackId={"1"} unit=" MW" fill={color} stroke={color} type="monotone" opacity={1} fillOpacity={0.7} isAnimationActive={false} activeDot={false} />
                    )
                }
            }
            return (<></>)
        })
        return areas
    }

    const ReferenceLabel = (props: any) => {
        const {
            viewBox,
        } = props;
        const width = 55
        const height = 28
        const x = viewBox.x - width / 2
        const y = viewBox.y;
        return (
            <foreignObject x={x} y={y} width={width} height={height}>
                <Typography
                    color="black"
                    width={width}
                    height={height}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        border: "1px solid black",
                        fontWeight: "bold",
                        alignText: "center",
                        justifyContent: "center",
                        px: 1
                    }}>NOW</Typography>
            </foreignObject>
        )
    }

    return (
        <Box
            height="100%"
            width="100%"
            pl={1}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <ResponsiveContainer height={"100%"}>
                {(chartData.length > 0 ?
                    <AreaChart data={chartData}>
                        <XAxis name="Time" domain={[domainStart, domainEnd]} onClick={() => console.log("Click")} dataKey="dateTime" scale="time" type="number" ticks={ticks} tickFormatter={TimeFormatter} allowDataOverflow={true} />
                        <YAxis type="number" domain={[0, 1200]} allowDataOverflow={true}> <Label dx={-30} angle={-90}>Failed To Deliver (MW)</Label></YAxis>
                        <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltip payload={chartData} />} />
                        <ReferenceLine x={currentTime.valueOf()} stroke={"White"} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} label={<ReferenceLabel />} />
                        {renderAreas()}
                    </AreaChart> :
                    <LoadingSymbol />
                )}
            </ResponsiveContainer>
        </Box>
    );
}
export interface IFailureToDeliverChart {
    chartData: IFailureToDeliverDataFormatted[]
    currentTime: Moment
}

export default FailureToDeliverChart;