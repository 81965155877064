import { Box, Paper } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSystemSummaryData } from "../../helpers/TradingAnalyticsData";
import Grid from '@mui/material/Unstable_Grid2';
import { ISystemSummaryData } from "../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
import SystemSummaryControls from "./SystemSummaryControls";
import moment, { Moment } from "moment";
import SystemSummaryChart from "./SystemSummaryChart";

const PortalHeartbeatWrapper: React.FC<IPortalHeartbeatWrapper> = ({ }) => {
    const [data, setData] = useState<ISystemSummaryData[]>([])
    const [filteredData, setFilteredData] = useState<ISystemSummaryData[]>([])
    const defaultStartTime = useMemo(() => moment.utc().startOf("day"), []);
    const defaultEndTime = useMemo(() => moment.utc().endOf("day").add(1, "second"), []);
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime)
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime)

    const loadData = useCallback(() => {
        const newData = GetSystemSummaryData()
        setData(newData)
    }, [])

    useEffect(() => {
        loadData()
        const intervalId = setInterval(() => {
            loadData();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadData])

    return (
        <Paper sx={{ height: "100%", width: "100%" }}>
            <Grid container>
                <Grid xs={12}>
                    <SystemSummaryControls unfilteredData={data} setFilteredData={setFilteredData} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid xs={12}>
                    <Box height={600}>
                        <SystemSummaryChart chartData={filteredData} domainStart={startDate} domainEnd={endDate} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}
export interface IPortalHeartbeatWrapper {
}

export default PortalHeartbeatWrapper;