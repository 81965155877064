import { Box, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { GetBalancingSummaryData, GetBidData, GetCurrentBSAD, GetCurrentNIV, GetCurrentSIP, GetOfferData } from "../../helpers/TradingAnalyticsData";
import Grid from '@mui/material/Unstable_Grid2';
import BalancingSummaryHeader from "./BalancingSummaryHeader";
import { IBalancingSummaryData } from "../../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
import BalancingSummaryChart from "./BalancingSummaryChart";
import { Moment } from "moment";
import moment from "moment";
import BalancingSummaryControls from "./BalancingSummaryControls";
import { IBidOfferData } from "../../../../shared/types/analytics/trading-analytics/IBidOfferData";
import BalancingSummaryBOATableWrapper from "./BalancingSummaryBOATableWrapper";

const BalancingSummaryWrapper: React.FC<IBalancingSummaryWrapper> = ({ }) => {
    const [currentNIV, setCurrentNIV] = useState<number>(0)
    const [currentSIP, setCurrentSIP] = useState<number>(0)
    const [currentBSAD, setCurrentBSAD] = useState<number>(0)
    const [priceView, setPriceView] = useState<boolean>(false)
    const [chartData, setChartData] = useState<IBalancingSummaryData[]>([])
    const [selectedTime, setSelectedTime] = useState<Moment>(moment.utc())
    const [nowSelected, setNowSelected] = useState<boolean>(true)
    const [bidData, setBidData] = useState<IBidOfferData[]>([])
    const [offerData, setOfferData] = useState<IBidOfferData[]>([])

    const loadData = useCallback(() => {
        const niv = GetCurrentNIV()
        const sip = GetCurrentSIP()
        const bsad = GetCurrentBSAD()
        const newChartData = GetBalancingSummaryData()
        const newBidData = GetBidData()
        const newOfferData = GetOfferData()
        setCurrentNIV(niv)
        setCurrentSIP(sip)
        setCurrentBSAD(bsad)
        setChartData(newChartData)
        setBidData(newBidData)
        setOfferData(newOfferData)
    }, [])

    useEffect(() => {
        loadData()
        const intervalId = setInterval(() => {
            loadData();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadData])

    const handleChartSelectedTime = (newSelectedTime: Moment) => {
        setNowSelected(false)
        setSelectedTime(newSelectedTime)
    }
    
    return (
        <Paper sx={{ height: "100%", width: "100%", overflowY: "auto", overflowX: "hidden" }}>
            <Grid container>
                <Grid xs={12}>
                    <BalancingSummaryHeader currentNIV={currentNIV} currentSIP={currentSIP} currentBSAD={currentBSAD} priceView={priceView} setPriceView={setPriceView} />
                </Grid>
                <Grid xs={12}>
                    <Box height={600}>
                        <BalancingSummaryChart chartData={chartData} currentTime={selectedTime} priceView={priceView} setSelectedTime={handleChartSelectedTime} />
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <BalancingSummaryControls selectedTime={selectedTime} setSelectedTime={setSelectedTime} nowSelected={nowSelected} setNowSelected={setNowSelected} />
                </Grid>
                <Grid xs={12}>
                    <BalancingSummaryBOATableWrapper bidData={bidData} offerData={offerData} />
                </Grid>
            </Grid>
        </Paper>
    );
}
export interface IBalancingSummaryWrapper {
}

export default BalancingSummaryWrapper;