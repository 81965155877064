import { PortalArea } from "../types/shared/routes/routeHelperClasses";
import ConradOperateLogo from '../../images/Conrad_Operate_Icon.png';
import { IPortalAreaOptions } from "../types/shared/routes/routeTypes";
import { OperatePermissions } from "../types/shared/auth/permissionsTypes";
import OperateHomePageConfig from "../../pages/operate/OperateHomePage";
import ForecastReportPageConfig from "../../pages/operate/ForecastReportPage";
import EngineOutagesPageConfig from "../../pages/operate/EngineOutagesPage";
import DeclareOutagePageConfig from "../../pages/operate/DeclareOutagePage";
import UpdateOutagePagePageConfig from "../../pages/operate/UpdateOutagePage";
import SiteAvailabilityPageConfig from "../../pages/operate/SiteAvailabilityPage";
import HistoricEngineOutagesPageConfig from "../../pages/operate/HistoricEngineOutagesPage";
import EngineTripsPagePageConfig from "../../pages/operate/EngineTripsPage";
import EngineStatusPageConfig from "../../pages/operate/EngineStatusPage";
import NotificationsArchivePageConfig from "../../pages/operate/NotificationsArchivePage";
import Icon from "@mdi/react";
import { mdiClipboardListOutline } from "@mdi/js";
import DailyFileCheckerPageConfig from "../../pages/operate/bess-file-checking/DailyFileCheckerPage";
import MonthlyFileCheckerPageConfig from "../../pages/operate/bess-file-checking/MonthlyFileCheckerPage";
import MonthlyAvailabilityFileCheckerPageConfig from "../../pages/operate/bess-file-checking/MonthlyAvailabilityFileCheckerPage";
import ShiftLoggingPageConfig from "../../pages/operate/shift-logging/ShiftLoggingPage";

const areaOptions: IPortalAreaOptions = {
    title: "Operate",
    description: "Availability, Engineering Information",
    iconImage: ConradOperateLogo,
    allValidPermissions: { Operate: OperatePermissions.AllPermissions }
}

// Initial pages
const OperatePortalArea = new PortalArea(areaOptions, OperateHomePageConfig);
const AvailabilitySubMenu = OperatePortalArea.addChildRoute(DeclareOutagePageConfig);
OperatePortalArea.addChildRoute(ForecastReportPageConfig);
OperatePortalArea.addChildRoute(NotificationsArchivePageConfig);

// Availability submenu
AvailabilitySubMenu.addChildRoute(EngineOutagesPageConfig);
AvailabilitySubMenu.addChildRoute(HistoricEngineOutagesPageConfig);
AvailabilitySubMenu.addChildRoute(SiteAvailabilityPageConfig);
AvailabilitySubMenu.addChildRoute(EngineTripsPagePageConfig);
AvailabilitySubMenu.addChildRoute(EngineStatusPageConfig);

// Pages hidden from the nav menu
AvailabilitySubMenu.addChildRoute(UpdateOutagePagePageConfig);

const BessFileCheckingSubmenu = OperatePortalArea.addChildRoute(
    {
        relativeUrl: "bess-file-checking",
        navDisplay: {
            title: "BESS File Checking",
            icon: <Icon path={mdiClipboardListOutline} size={1} />,
        }
    }
);

BessFileCheckingSubmenu.addChildRoute(DailyFileCheckerPageConfig);
BessFileCheckingSubmenu.addChildRoute(MonthlyFileCheckerPageConfig);
BessFileCheckingSubmenu.addChildRoute(MonthlyAvailabilityFileCheckerPageConfig);

OperatePortalArea.addChildRoute(ShiftLoggingPageConfig);

export default OperatePortalArea;
