import { StatusHiveSiteData } from "../../shared/types/asset/status-hive";
import { HexMultiLineText } from "./HexMultiLineText";

export const HexStatusText = ({ status, colour, y }: IHexStatusTextProps): JSX.Element => {
    const fontSize = 0.07;

    return (
        <HexMultiLineText text={status.statusHumanReadable} colour={colour} y={y} fontSize={fontSize} maxChars={19} fontWeight={600} />
    );
}

export interface IHexStatusTextProps {
    status: StatusHiveSiteData;
    colour: string;
    y: number;
}