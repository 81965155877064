import React, { useEffect } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { IBSADDataFormatted } from '../../../../shared/types/analytics/trading-analytics/IBSADData';

const headCells: HeadCell[] = [
    {
        id: "assetID",
        label: "Unit ID",
        dataType: "String"
    },
    {
        id: "location",
        label: "Location",
        dataType: "String"
    },
    {
        id: "fuelType",
        label: "Fuel Type",
        dataType: "String"
    },
    {
        id: "price",
        label: "BOA Price",
        dataType: "Price"
    },
    {
        id: "volume",
        label: "BOA Volume",
        dataType: "Power"
    }
]

const BSADTable: React.FC<IBSADTable> = ({ items }) => {
    const [orderDescending, setOrderDescending] = React.useState<boolean>(false);
    const [orderCategory, setOrderCategory] = React.useState<keyof IBSADDataFormatted>("price");
    const theme = useTheme()
    const defaultTextColour = theme.palette.common.black
    const defaultBackgroundColour = theme.palette.conradEnergyMagenta.light
    const systemFlagBackgroundColour = theme.palette.conradEnergyYellow.main

    const sortItems = (inputArray: IBSADDataFormatted[], category: keyof IBSADDataFormatted, descending: boolean) => {
        const outputArray = [...inputArray]
        outputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            outputArray.reverse()
        }
        return outputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IBSADDataFormatted[]>(sortItems(items, orderCategory, orderDescending))

    const handleSort = (categoryID: keyof IBSADDataFormatted) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {internalItems
                            .map((item) => {
                                const rowKey = "BSAD-table-key-" + item.assetID + "-" + item.settlementPeriod + "-" + item.price
                                const backgroundColour = item.soFlag ? systemFlagBackgroundColour : defaultBackgroundColour
                                return (
                                    <TableRow key={rowKey}>
                                        {
                                            headCells.map((headCell) => {
                                                const cellValue = item[headCell.id]
                                                let cellContents = <>{cellValue.toString()}</>
                                                if (headCell.dataType === "Price") {
                                                    cellContents = <>&pound;{parseFloat(cellValue.toString()).toFixed(2).toString()}</>
                                                }
                                                else if (headCell.dataType === "Power") {
                                                    cellContents = <>{parseFloat(cellValue.toString()).toFixed(0).toString()}MW</>
                                                }
                                                return (
                                                    <TableCell
                                                        key={rowKey + "-" + headCell.id}
                                                        align="center"
                                                        sx={{
                                                            color: defaultTextColour,
                                                            backgroundColor: backgroundColour
                                                        }}
                                                    >
                                                        {cellContents}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
interface HeadCell {
    id: keyof IBSADDataFormatted,
    label: string,
    dataType: "String"|"Price"|"Power"
}
export interface IBSADTable {
    items: IBSADDataFormatted[]
}

export default BSADTable;