import React, { useEffect, useState } from 'react';
import { IAssetAvailability } from "../../shared/types/operate/IAssetAvailability";
import { Area, AreaChart, ComposedChart, Customized, Label, Rectangle, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { IEngineOutage } from '../../shared/types/operate/IEngineOutage';
import moment from 'moment';
import { IAnalyticsChartDataItem } from '../../shared/types/analytics/IAnalyticsChartDataItem';
import { DateTimeFormatter } from "../analytics/helpers/DateFormatter";
import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material';
import MouseTracker from '../shared/MouseTracker';
import { isMobile } from 'react-device-detect';

const EngineOutagePopup: React.FC<IEngineOutagePopup> = ({ selectedOutage }) => {
    const theme = useTheme();
    return (
        <Paper hidden={selectedOutage === null} sx={{ border: "3px solid", borderColor: (selectedOutage?.restriction ? theme.palette.conradEnergyCyan.main : theme.palette.conradEnergyYellow.main), borderRadius: 2, paddingLeft: 2, paddingRight: 2, height: "fit-content" }}>
                <Grid xs={12}>
                    <Typography align="center" variant="body1">{(parseInt(selectedOutage?.remitID || "0") > 0 ? "Remit ID: " + selectedOutage?.remitID + " | " : "") + selectedOutage?.engineID + " | " + selectedOutage?.mwOutage.toFixed(1) + " MW" + " | " + (selectedOutage?.restriction ? "Restriction" : "Outage")}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography align="center" variant="body1">
                        <b>{DateTimeFormatter(moment(selectedOutage?.outageStart).valueOf())} (Local Time) </b>
                        to 
                        <b> {DateTimeFormatter(moment(selectedOutage?.outageEnd).valueOf())} (Local Time)</b>
                    </Typography>
                </Grid>
        </Paper>
    )
}
interface IEngineOutagePopup {
    selectedOutage: IEngineOutage|null
}

const AssetAvailabilityChart: React.FC<IAssetAvailabilityChartProps> = ({ items, capacity, outages = [] }) => {
    const [chartData, setChartData] = useState<IAnalyticsChartDataItem[]>([])
    const [outageStartPoints, setOutageStartPoints] = useState<IAnalyticsChartDataItem[]>([])
    const [outageEndPoints, setOutageEndPoints] = useState<IAnalyticsChartDataItem[]>([])
    const [chartStart, setChartStart] = useState<number>(moment().utc().startOf("day").valueOf())
    const [chartEnd, setChartEnd] = useState<number>(moment().utc().endOf("day").valueOf())
    const [selectedOutage, setSelectedOutage] = useState<IEngineOutage | null>(null)
    const [internalOutageData, setInternalOutageData] = useState<IEngineOutage[]>([])
    const [maxRank, setMaxRank] = useState<number>(0)
    const [ticks, setTicks] = useState<number[]>([])
    const [lastTap, setLastTap] = useState<number>(new Date().getTime())
    const navigate = useNavigate();
    const theme = useTheme();


    useEffect(() => {
        let max = 0
        outageStartPoints.forEach((outageStart) => {
            if (outageStart.startRank && outageStart.startRank > max) { max = outageStart.startRank as number }
        })
        outageEndPoints.forEach((outageEnd) => {
            if (outageEnd.endRank && outageEnd.endRank > max) { max = outageEnd.endRank as number }
        })
        setMaxRank(max)
    }, [outageStartPoints, outageEndPoints])


    const fetchOutages = () => {
        if (items.length === 0) {
            setChartData([])
            setOutageStartPoints([])
            setOutageEndPoints([])
        }
        else {
            const newChartData = new Array<IAnalyticsChartDataItem>();
            items.forEach((item) => {
                const newItem: IAnalyticsChartDataItem = {
                    dateTime: moment(item.startGMT, "YYYY-MM-DDTHH:mm:ssZ").valueOf(),
                    export: item.export
                }
                newChartData.push(newItem)
            })
            newChartData.sort((a, b) => {
                return a.dateTime < b.dateTime ? -1 : 1
            })
            const rangeGreaterThanADay = Math.abs(moment(newChartData[0].dateTime).diff(moment(newChartData[newChartData.length - 1].dateTime), "days")) > 1
            const newTicks = new Array<number>()
            newChartData.forEach((item, index) => {
                if (index === 0 || index === items.length - 1 || (moment(item.dateTime).minute() === 0 && (!rangeGreaterThanADay || moment(item.dateTime).hour() === 0))) {
                    newTicks.push(item.dateTime)
                }

            })
            setChartData(newChartData)
            setTicks(newTicks)
            const newChartStart = newChartData[0].dateTime
            const newChartEnd = newChartData[newChartData.length - 1].dateTime
            setChartStart(newChartStart)
            setChartEnd(newChartEnd)
            internalOutageData.sort((a, b) => {
                const aDifference = moment(a.outageEnd).valueOf() - moment(a.outageStart).valueOf()
                const bDiffernce = moment(b.outageEnd).valueOf() - moment(b.outageStart).valueOf()
                return aDifference < bDiffernce ? -1 : 1
            })
            const newStartOutageData = new Array<IAnalyticsChartDataItem>();
            const newEndOutageData = new Array<IAnalyticsChartDataItem>();
            const outageRankings = new Array<number>();
            for (let i = 0; i < internalOutageData.length; i++) {
                const overlappingOutageIndices = new Array<number>();
                const currentOutage = internalOutageData[i]
                let outageRanking = 1;
                const currentStart = moment(currentOutage.outageStart).valueOf()
                const currentEnd = moment(currentOutage.outageEnd).valueOf()
                for (let j = 0; j < i; j++) {
                    const previousOutage = internalOutageData[j]
                    const previousStart = moment(previousOutage.outageStart).valueOf()
                    const previousEnd = moment(previousOutage.outageEnd).valueOf()
                    if (!((currentStart < previousStart && currentEnd <= previousStart) || (currentStart >= previousEnd && currentEnd > previousEnd))) {
                        overlappingOutageIndices.push(j)
                    }
                }
                overlappingOutageIndices.forEach((index) => {
                    const ranking = outageRankings[index]
                    if (ranking >= outageRanking) { outageRanking = ranking + 1 }
                })
                outageRankings.push(outageRanking)
            }
            internalOutageData.forEach((outage, index) => {
                let outageStartValue = moment(outage.outageStart).valueOf()
                if (outageStartValue < newChartStart) {
                    outageStartValue = newChartStart
                }
                let outageEndValue = moment(outage.outageEnd).valueOf()
                if (outageEndValue > newChartEnd) {
                    outageEndValue = newChartEnd
                }
                const newOutageStart: IAnalyticsChartDataItem = {
                    dateTime: outageStartValue,
                    startRank: outageRankings[index],
                    remitID: parseInt(outage.remitID)
                }
                const newOutageEnd: IAnalyticsChartDataItem = {
                    dateTime: outageEndValue,
                    endRank: outageRankings[index],
                    remitID: parseInt(outage.remitID)
                }
                newStartOutageData.push(newOutageStart)
                newEndOutageData.push(newOutageEnd)
            })
            setOutageStartPoints(newStartOutageData)
            setOutageEndPoints(newEndOutageData)
        }
    }


    useEffect(() => {
        fetchOutages()
    }, [items, internalOutageData])


    useEffect(() => {
        const newOutages = new Array<IEngineOutage>()
        outages.forEach((outage) => {
            newOutages.push(outage)
        })
        newOutages.forEach((outage) => {
            if (!outage.remitID) {
                outage.remitID = Math.floor(Math.random() * -1000000).toString()
            }
        })
        setInternalOutageData(newOutages)
    }, [outages])


    const CustomErrorBars = ({ formattedGraphicalItems }: any) => {
        const firstSeries = formattedGraphicalItems[0]
        const secondSeries = formattedGraphicalItems[1]
        let maxRank = 0
        firstSeries.props.points.forEach((point: any) => {
            if (point.startRank > maxRank) { maxRank = point.startRank }
        })
        return firstSeries.props.points.map((firstSeriesPoint: any, index: number) => {
            const remitID = firstSeriesPoint.payload.remitID
            let currentOutage: IEngineOutage|null = null
            internalOutageData.forEach((outage) => {
                if (outage.remitID == remitID.toString()) {
                    currentOutage = outage
                }
            })
            let secondSeriesPoint = firstSeriesPoint;
            secondSeries.props.points.forEach((point: any) => {
                if (point.payload.remitID === remitID) {
                    secondSeriesPoint = point
                }
            })
            const startMidpoint = (firstSeriesPoint.x + firstSeriesPoint.width / 2.0)
            const endMidpoint = (secondSeriesPoint.x + secondSeriesPoint.width / 2.0)
            const width = endMidpoint - startMidpoint
            const height = 20
            const dblTouchTapMaxDelay = 300
            return (
                    <Rectangle
                    key={"start" + index}
                    width={width}
                    height={height}
                    x={startMidpoint}
                    y={firstSeriesPoint.y}
                    radius={5}
                    fill={(currentOutage !== null && currentOutage['restriction'] ? theme.palette.conradEnergyCyan.main : theme.palette.conradEnergyYellow.main)}
                    stroke={theme.palette.text.primary}
                    strokeWidth="1"
                    cursor={(remitID > 0 ? "pointer" : "default")}
                    onMouseEnter={() => {
                        if (currentOutage !== null && !isMobile) {
                            setSelectedOutage(currentOutage)
                        }
                    }}
                    onMouseLeave={() => setSelectedOutage(null)}
                    onClick={(e) => {
                        if (!isMobile) {
                            if (remitID > 0) {
                                navigateToUpdateOutage(remitID)
                            }
                        }
                        else {
                            const currentTap = new Date().getTime()
                            const isDoubleTap = currentTap - lastTap < dblTouchTapMaxDelay
                            setLastTap(currentTap)
                            if (isDoubleTap) {
                                if (remitID > 0) {
                                    navigateToUpdateOutage(remitID)
                                }
                            }
                            else {
                                setTimeout(() => setSelectedOutage(currentOutage), dblTouchTapMaxDelay)
                                e.stopPropagation()
                            }
                        }
                        
                    }}
                    >
                    </Rectangle>
                )
        })
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length && payload[0].payload) {
            return (
                <Box sx={{
                    "backgroundColor": theme.palette.background.paper,
                    "borderRadius": 5,
                    "border": "1px solid " + theme.palette.text.primary,
                    "color": theme.palette.text.primary,
                    px: 1,
                    py: 0.5
                }} >
                    <p>Date (Local Time): {DateTimeFormatter(payload[0].payload.dateTime)}</p>
                    <p>Availability: {payload[0].payload.export.toFixed(2)} MW</p>
                </Box>
            );
        }

        return null;
    };

    const CustomDateFormatter = (date: number) => {
        if (date === chartEnd || date === chartStart) {
            return DateTimeFormatter(date);
        }
        else {
            return ""
        }
    };

    const navigateToUpdateOutage = (remitId: string) => {
        navigate(`/operate/availability/update-engine-outage?RemitID=${remitId}`);
    }

    return (
        <Box
            width="100%"
            height="100%"
            sx={{ overflowY: "auto" }}
            onTouchStart={() => setSelectedOutage(null)}
        >
            <MouseTracker offset={isMobile ? { x: 0, y: 0 } : { x: 30, y: 30 }}>
                <EngineOutagePopup selectedOutage={selectedOutage} />
            </MouseTracker>
            <Box width="95%" height={(maxRank * 30) + "px"} onMouseLeave={() => setSelectedOutage(null)}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart margin={{ left: 5, bottom: 5, top: 5, right: 20 }}>
                        <XAxis dataKey="dateTime" scale="time" type="number" hide={true} domain={[chartStart, chartEnd]} allowDataOverflow={true} />
                        <YAxis type="number" tick={false} axisLine={false}>
                            <Label value="" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
                        </YAxis>
                        <Scatter name="start" dataKey="startRank" data={outageStartPoints} label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                        <Scatter name="end" dataKey="endRank" data={outageEndPoints} label={false} legendType="none" tooltipType="none" shape={<></>} isAnimationActive={false} />
                        <Customized component={CustomErrorBars} />
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
            <Box width="95%" height="90%">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={chartData} margin={{ left: 5, bottom: 25, top: 0, right: 20 }}>
                        <defs>
                            <linearGradient id="colorAvailable" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={theme.palette.conradEnergyFreshGreen.main} stopOpacity={0.4} />
                                <stop offset="95%" stopColor={theme.palette.conradEnergyFreshGreen.main} stopOpacity={0.1} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="dateTime" scale="time" type="number" minTickGap={0} ticks={ticks} interval={0} tick={{ fontSize: "0.75rem", dy: 3 }} tickFormatter={CustomDateFormatter} domain={[chartStart, chartEnd]} allowDataOverflow={true}>
                            <Label value="Time" position="insideBottom" offset={-10} />
                        </XAxis>
                        <Tooltip content={<CustomTooltip payload={chartData} />} />
                        <YAxis tick={{ fontSize: "0.75rem" }} domain={[0, capacity.toFixed(2)]}>
                            <Label value="MW" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
                        </YAxis>
                        <Area isAnimationActive={false} type="stepAfter" name="MW Export" dataKey="export" stroke={theme.palette.conradEnergyFreshGreen.dark} dot={false} fillOpacity={1} fill="url(#colorAvailable)" />
                        <ReferenceLine y={capacity} stroke={theme.palette.text.primary} strokeDasharray="20 10" strokeWidth="3" />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );

}
export interface IAssetAvailabilityChartProps {
    items: IAssetAvailability[],
    capacity: number,
    outages: IEngineOutage[]
}

export default AssetAvailabilityChart;