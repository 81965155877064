import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar, ComposedChart, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TimeFormatter } from "../../helpers/DateFormatter"
import moment, { Moment } from "moment";
import { ISystemSummaryCategories, ISystemSummaryData, ISystemSummaryPowerData, ISystemSummaryPriceData } from "../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
import { GetSystemSummaryBackgroundColors } from "../../helpers/TradingAnalyticsFuelColours";
import SystemSummaryChartTooltip from "./SystemSummaryChartTooltip";
import SystemSummaryChartLegend from "./SystemSummaryChartLegend";
import { CalcXTicks, CalcYTicks } from "./helpers/SystemSummaryTickCalculators";

const SystemSummaryChart: React.FC<ISystemSummaryChart> = ({ chartData, domainStart, domainEnd }) => {
    const [currentTime, setCurrentTime] = useState<number>(moment.utc().valueOf())
    const [xTicks, setXTicks] = useState<number[]>([])
    const defaultLeftYDomain = 1500
    const defaultRightYDomain = 150
    const leftYAxisIntervals = 250
    const rightYAxisIntervals = 10
    const rightYAxisMin = -10
    const [leftYDomain, setLeftYDomain] = useState<number[]>([defaultLeftYDomain, 0 - defaultLeftYDomain])
    const [rightYDomain, setRightYDomain] = useState<number[]>([rightYAxisMin, defaultRightYDomain])
    const [leftYTicks, setLeftYTicks] = useState<number[]>([])
    const [rightYTicks, setRightYTicks] = useState<number[]>([])
    const [enabledData, setEnabledData] = useState<ISystemSummaryCategories<boolean>>({
        imbalNGC: true,
        disBSAD: true,
        ispStack: true,
        nivForecast: true,
        totalBalMechLevel: true,
        ndf: true,
        tsdf: true,
        indo: true,
        itsdo: true,
        mid: true
    })

    const powerKeys = useMemo(() => [
        "imbalNGC",
        "disBSAD",
        "ispStack",
        "nivForecast",
        "totalBalMechLevel"
    ] as (keyof ISystemSummaryPowerData<any>)[], []);

    const priceKeys = useMemo(() => [
        "ndf",
        "tsdf",
        "indo",
        "itsdo",
        "mid"
    ] as (keyof ISystemSummaryPriceData<any>)[], []);

    const nameDict: ISystemSummaryCategories<string> = {
        imbalNGC: "IRIS_IMBALNGC",
        disBSAD: "IRIS_DISBSAD",
        ispStack: "Net Imbalance Volumes Published",
        nivForecast: "NIV Forecast",
        totalBalMechLevel: "Total Balancing Mechanism Level",
        ndf: "IRIS_NDF",
        tsdf: "IRIS_TSDF",
        indo: "IRIS_INDO",
        itsdo: "IRIS_ITSDO",
        mid: "IRIS_MID"
    }

    const coloursDict = GetSystemSummaryBackgroundColors()

    const updateReferenceLine = useCallback(() => {
        setCurrentTime(moment.utc().valueOf())
    }, [])


    useEffect(() => {
        updateReferenceLine()
        const intervalId = setInterval(() => {
            updateReferenceLine(); // Update refernce line every minute
        }, 60000);

        return () => clearInterval(intervalId);
    }, [updateReferenceLine])

    useEffect(() => {
        // Setting x ticks
        setXTicks(CalcXTicks(domainStart, domainEnd))
    }, [domainStart, domainEnd])

    useEffect(() => {
        // Setting y ticks
        let leftTicks = CalcYTicks(chartData, powerKeys, enabledData, leftYAxisIntervals, defaultLeftYDomain)
        let rightTicks = CalcYTicks(chartData, priceKeys, enabledData, rightYAxisIntervals, defaultRightYDomain, rightYAxisMin)
        leftTicks = leftTicks.reverse()
        setLeftYDomain([leftTicks[0], leftTicks[leftTicks.length - 1]])
        setRightYDomain([rightTicks[0], rightTicks[rightTicks.length - 1]])
        setLeftYTicks(leftTicks)
        setRightYTicks(rightTicks)
    }, [chartData, enabledData, powerKeys, priceKeys, rightYAxisMin])

    const renderBars = () => {
        const bars = powerKeys.map((key) => {
            if (enabledData[key]) {
                const name = nameDict[key]
                const color = coloursDict[key]
                return (
                    <Bar dataKey={key} yAxisId="left" name={name} fill={color} key={key} stroke={color} isAnimationActive={false} />
                )
            }
            return (<></>)
        })
        return bars
    }

    const renderLines = () => {
        const lines = priceKeys.map((key) => {
            if (enabledData[key]) {
                const name = nameDict[key]
                const color = coloursDict[key]
                return (
                    <Line type="monotone" yAxisId="right" dataKey={key} key={key} name={name} stroke={color} strokeWidth={3} dot={false} activeDot={false} isAnimationActive={false} />
                )
            }
            return (<></>)
        })
        return lines
    }

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload as ISystemSummaryData
            return (
                <SystemSummaryChartTooltip data={data} powerKeys={powerKeys} priceKeys={priceKeys} enabledData={enabledData} nameDict={nameDict} coloursDict={coloursDict} />
            );
        }
        return null
    };

    return (
        <Box
            height="100%"
            width="100%"
            pl={1}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <ResponsiveContainer height={"100%"}>
                <ComposedChart data={chartData}>
                    <XAxis name="Time" domain={[domainStart.valueOf(), domainEnd.valueOf()]} dataKey="dateTime" scale="time" type="number" ticks={xTicks} tickFormatter={TimeFormatter} allowDataOverflow={true} />
                    <YAxis type="number" interval={0} yAxisId="left" domain={leftYDomain} ticks={leftYTicks} allowDataOverflow={true}> <Label dx={-30} angle={-90}>MW</Label></YAxis>
                    <YAxis type="number" interval={0} yAxisId="right" orientation="right" domain={rightYDomain} ticks={rightYTicks} allowDataOverflow={true}><Label dx={20} angle={-90}>&#163;/MWh</Label></YAxis>
                    {renderBars()}
                    {renderLines()}
                    <ReferenceLine x={currentTime.valueOf()} yAxisId="left" stroke={"Grey"} opacity={100} isFront={true} strokeWidth={3} />
                    <ReferenceLine y={0} yAxisId="left" stroke={"white"} opacity={0.5} isFront={true} strokeWidth={1} />
                    <Legend content={<SystemSummaryChartLegend powerKeys={powerKeys} priceKeys={priceKeys} enabledData={enabledData} setEnabledData={setEnabledData} nameDict={nameDict} coloursDict={coloursDict} />} />
                    <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltip payload={chartData} />} />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}
export interface ISystemSummaryChart {
    chartData: ISystemSummaryData[]
    domainStart: Moment
    domainEnd: Moment
}

export default SystemSummaryChart;