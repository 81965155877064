import { Hexagon } from 'react-hexgrid';
import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { AssetType, ISiteStatus } from '../../shared/types/ISiteStatus';
import { CellTower } from '@mui/icons-material';
import { HexagonMouseEventHandler } from 'react-hexgrid/lib/Hexagon/Hexagon';
import { HexIndicatorBar } from './HexIndicatorBar';
import { HexMultiLineText } from './HexMultiLineText';
import { HexIcon } from './HexIcon';
import { HexStatusText } from './HexStatusText';
import { HexBatteryStatus } from './HexBatteryStatus';
import { IDispatchRequest, SiteManualStatus, StatusHiveSiteData, StatusHiveTileStatus } from '../../shared/types/asset/status-hive';
import { useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { useColourModeContext } from '../ColourMode';
import { useEffect, useState } from 'react';
import { useToastAlertContext } from '../ToastAlert';

const HiveIndicatorBar: React.FC<IHiveIndicatorBar> = ({ status, colour }) => {
    const [value, setValue] = useState<number>(0)
    const [maxValue, setMaxValue] = useState<number>(0)
    const [label, setLabel] = useState<string>("")


    useEffect(() => {
        if (status.underOffer) {
            setValue(status.boaAmount || 0)
            setMaxValue(status.siteCapacity || 0)
            setLabel("Offer MW v. Capacity MW")
        }
        else if (status.assetType !== AssetType.Battery || status.activePowerExport > 0.1) {
            setValue(status.activePowerExport || 0)
            setMaxValue(status.availableExport || 0) // Check if should be available, PN or dispatch plan
            setLabel("Export MW v. Declared MW")
        }
        else {
            setValue(Math.abs(status.activePowerImport || 0))
            setMaxValue(Math.abs(status.availableImport || 0))
            setLabel("Import MW v. Declared MW")
        }
    }, [status])

    return (
        <HexIndicatorBar
            x={0}
            y={0}
            width={0.75}
            height={0.05}
            fill={colour}
            value={value}
            maxValue={maxValue}
            fillHorizontally={true}
            valueTooltipLabel={label}
            unit={"MW"}
        />
    )
}

interface IHiveIndicatorBar {
    status: StatusHiveSiteData
    colour: string
}

export const StatusHiveTile: React.FC<IStatusHiveTileProps> = ({ coords, status, onClick, dispatchMode, updateStatus, flashSync, dispatchSite }) => {
    const [borderColour, setBorderColour] = useState<string>(status.colour)
    const theme = useTheme();
    const { popToast } = useToastAlertContext();
    const contentColour = status.colour;
    const opacity = status.tileStatus === StatusHiveTileStatus.Idle ? 0.5 : 1;
    const { isDarkMode } = useColourModeContext();
    const dispatchButtonsX = 2

    const dispatch = async (newManualStatus: SiteManualStatus) => {
        const request: IDispatchRequest = {
            siteID: status.siteId,
            siteName: status.siteName,
            newManualStatus: newManualStatus
        }
        return await dispatchSite(request)
    }

    const handleOnClick = async () => {
        let newManualStatus = SiteManualStatus.On
        if (status.manualStart) {
            newManualStatus = SiteManualStatus.NotManual
        }
        var response = await dispatch(newManualStatus)
        if (response) {
            let newStatus = status
            newStatus.manualStart = newManualStatus === SiteManualStatus.On
            newStatus.manualStop = false
            popToast("Dispatch signal to " + status.siteName + " sent")
            updateStatus(newStatus)
        }
        else {
            popToast("Failed to dispatch " + status.siteName)
        }
    }

    const handleOffClick = async () => {
        let newManualStatus = SiteManualStatus.Off
        if (status.manualStop) {
            newManualStatus = SiteManualStatus.NotManual
        }
        var response = await dispatch(newManualStatus)
        if (response) {
            let newStatus = status
            newStatus.manualStop = newManualStatus === SiteManualStatus.Off
            newStatus.manualStart = false
            popToast("Dispatch signal to " + status.siteName + " sent")
            updateStatus(newStatus)
        }
        else {
            popToast("Failed to dispatch " + status.siteName)
        }
    }

    const onButtonColor = theme.palette.conradEnergyFreshGreen.dark
    const offButtonColor = theme.palette.error.light

    const [showDispatch, setShowDispatch] = useState<boolean>(false)

    useEffect(() => {
        setShowDispatch(dispatchMode && status.assetType === AssetType.Gas)
    }, [dispatchMode, status])

    useEffect(() => {
        if (!status.flash || flashSync) {
            if (status.borderColour) {
                setBorderColour(status.borderColour)
            }
            else {
                setBorderColour(status.colour)
            }
        }
        else {
            setBorderColour("none")
        }
    }, [status.flash, flashSync, status.borderColour, status.colour])

    return (
        <Hexagon q={coords.q} r={coords.r} s={coords.s} fill="hex-background" opacity={opacity} stroke={borderColour} strokeWidth={"0.01em"} className="data-hex" onClick={onClick}>
            <HexIcon Icon={status.assetIcon} fontSize={0.125} colour={contentColour} y={-0.44} tooltip={AssetType[status.assetType]} />

            <HexMultiLineText text={status.siteName || status.siteId} colour={contentColour} y={showDispatch ? -2.5 : -1.1} fontSize={0.09} fontWeight={800} />

            {showDispatch &&
                <>
                    <HexMultiLineText onClick={handleOnClick} borderColor={onButtonColor} backgroundColor={status.manualStart ? onButtonColor : undefined} text={"On"} colour={isDarkMode ? common.white : common.black} x={dispatchButtonsX} y={-1} fontSize={0.09} fontWeight={800} />
                    <HexMultiLineText onClick={handleOffClick} borderColor={offButtonColor} backgroundColor={status.manualStop ? offButtonColor : undefined} text={"Off"} colour={isDarkMode ? common.white : common.black} x={0 - dispatchButtonsX} y={-1} fontSize={0.09} fontWeight={800} />
                </>
            }

            {status.displayFrequencyServices ?
                <HexBatteryStatus status={status} activeColour={contentColour} y={2.25} />
                :
                <HexStatusText status={status} colour={status.valueTextColour ? status.valueTextColour : contentColour} y={2.45} />
            }

            <HexIcon Icon={CellTower} fontSize={0.125} colour={status.commsIconData.colour} y={0.32} tooltip={status.commsIconData.tooltip} />

            <HiveIndicatorBar status={status} colour={contentColour} />
        </Hexagon>
    );
}

export interface IStatusHiveTileProps {
    coords: CubicCoords;
    status: StatusHiveSiteData;
    onClick?: HexagonMouseEventHandler<SVGElement>;
    dispatchMode: boolean;
    updateStatus: (newStatus: ISiteStatus) => void
    flashSync: boolean;
    dispatchSite: (request: IDispatchRequest) => Promise<boolean>
}