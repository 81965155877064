import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import Grid from '@mui/material/Unstable_Grid2';

const NIVTableActualForecastButton: React.FC<INIVTableActualForecastButton> = ({ value, actualOrForecast, setActualOrForecast }) => {
    const theme = useTheme()
    return (
        <Box
            height="5vh"
        >
            <Button
                sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: value === actualOrForecast  ? theme.palette.conradEnergyDeepBlue.dark : "default",
                    border: value === actualOrForecast ? "2px solid white" : "default"
                }}
                onClick={() => setActualOrForecast(value)}
            >
                <Typography variant="subtitle2">{value}</Typography>
            </Button>
        </Box>
    )
}

interface INIVTableActualForecastButton {
    value: string
    actualOrForecast: string,
    setActualOrForecast: (actualOrForecast: string) => void
}

const NIVTableActualForecastPicker: React.FC<INIVTableActualForecastPicker> = ({ actualOrForecast, setActualOrForecast }) => {
    return (
        <>
            <Grid xs={6} />
            <Grid xs={1}>
                <NIVTableActualForecastButton value={"Actual"} actualOrForecast={actualOrForecast} setActualOrForecast={setActualOrForecast} />
            </Grid>
            <Grid xs={1}>
                <NIVTableActualForecastButton value={"Forecast"} actualOrForecast={actualOrForecast} setActualOrForecast={setActualOrForecast} />
            </Grid>
            <Grid xs={6} />
        </>
    );
}
export interface INIVTableActualForecastPicker {
    actualOrForecast: string,
    setActualOrForecast: (actualOrForecast: string) => void
}

export default NIVTableActualForecastPicker;