import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { IBidOfferData } from "../../../../shared/types/analytics/trading-analytics/IBidOfferData";
import { formatFuelTypeToKey } from "../../helpers/TradingAnalyticsFuelColours";
import { Dictionary } from "@reduxjs/toolkit";
import moment from "moment";
import FuelSelector from "../shared/FuelSelector";

const BidOfferToggle: React.FC<IBidOfferToggle> = ({ value, setValue, label }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
    };

    return (

        <Grid xs={1}>
            <FormGroup sx={{
                p: 1,
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <FormControlLabel control={<Switch checked={value} onChange={handleChange} />} label={label} />
            </FormGroup>
        </Grid>
    );
}

interface IBidOfferToggle {
    value: boolean,
    setValue: (value: boolean) => void
    label: string
}

const BidOfferFuelSelect: React.FC<IBidOfferFuelSelect> = ({ selectedTypes, setSelectedTypes }) => {

    return (
        <Grid xs={1}>
            <Box sx={{
                p: 1,
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <FuelSelector selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} />
            </Box>
        </Grid>
    );
}

interface IBidOfferFuelSelect {
    selectedTypes: string[],
    setSelectedTypes: (selectedTypes: string[]) => void
}



const BidOfferTableFilters: React.FC<IBidOfferTableFilters> = ({ unfilteredData, setFilteredData, showDynamicData, setShowDynamicData }) => {
    const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>(["All"])
    const [currentlyOfferedSites, setCurrentlyOfferedSites] = useState<boolean>(false)
    const [firstPair, setFirstPair] = useState<boolean>(false)
    const [excludeExtremeOffers, setExcludeExtremeOffers] = useState<boolean>(false)
    const [excludeUndeliverableOffers, setExcludeUndeliverableOffers] = useState<boolean>(false)

    const isValidBid = (bid: IBidOfferData) => {
        const minutesLeftInSP = 29 - (moment.utc().minutes() % 30)
        if (bid.nto > minutesLeftInSP || (bid.ndz > minutesLeftInSP && bid.bmAdjustedFPN === 0)) {
            return false
        }

        if (bid.bmAdjustedFPN === 0) {
            unfilteredData.forEach((bidOrOffer) => {
                if (bidOrOffer.bid && bidOrOffer.siteID === bid.siteID && bidOrOffer.bmAdjustedFPN > 0) {
                    const currentCall = moment(bid.latestCall)
                    const previousCall = moment(bidOrOffer.latestCall)
                    if (currentCall.isAfter(previousCall)) {
                        const difference = moment.duration(currentCall.diff(previousCall));
                        const differenceMinutes = difference.asMinutes();
                        if (bid.mzt > differenceMinutes) {
                            return false
                        }
                    }
                }
            })
        }

        return true
    }

    const isValidOffer = (offer: IBidOfferData) => {
        const minutesLeftInSP = 29 - (moment.utc().minutes() % 30)
        if (offer.ntb > minutesLeftInSP) {
            return false
        }

        if (offer.bmAdjustedFPN > 0) {
            unfilteredData.forEach((bidOrOffer) => {
                if (!bidOrOffer.bid && bidOrOffer.siteID === offer.siteID && bidOrOffer.bmAdjustedFPN === 0) {
                    const currentCall = moment(offer.latestCall)
                    const previousCall = moment(bidOrOffer.latestCall)
                    if (currentCall.isAfter(previousCall)) {
                        const difference = moment.duration(currentCall.diff(previousCall));
                        const differenceMinutes = difference.asMinutes();
                        if (offer.mnzt < differenceMinutes) {
                            return false
                        }
                    }
                }
            })
        }

        return true
    }

    const applyFilters = () => {
        let filteredData: IBidOfferData[] = []
        const selectedFuels = new Set<string>();
        selectedFuelTypes.forEach((fuelType) => {
            selectedFuels.add(formatFuelTypeToKey(fuelType))
        })

        unfilteredData.forEach((bidOrOffer) => {
            if (selectedFuels.has("all") || selectedFuels.has(formatFuelTypeToKey(bidOrOffer.fuelType))) {
                if (!currentlyOfferedSites || bidOrOffer.boa > 0) {
                    if (!excludeExtremeOffers || (bidOrOffer.price < 9999 && bidOrOffer.price > -9999)) {
                        filteredData.push(bidOrOffer)
                    }
                }
            }
        })

        if (excludeUndeliverableOffers) {
            const newFilteredData: IBidOfferData[] = []
            filteredData.forEach((bidOrOffer) => {
                if (bidOrOffer.bid) {
                    if (isValidBid(bidOrOffer)) {
                        newFilteredData.push(bidOrOffer)
                    }
                }
                else {
                    if (isValidOffer(bidOrOffer)) {
                        newFilteredData.push(bidOrOffer)
                    }
                }
            })
            filteredData = newFilteredData
        }

        if (firstPair) {
            const bestBOADict: Dictionary<IBidOfferData> = {}
            filteredData.forEach((bidOrOffer) => {
                const existingBOA = bestBOADict[bidOrOffer.siteID]
                if (existingBOA === undefined || existingBOA.price < bidOrOffer.price) {
                    bestBOADict[bidOrOffer.siteID] = bidOrOffer
                }
            })
            const newFilteredData: IBidOfferData[] = []
            Object.keys(bestBOADict).sort().forEach((siteID) => {
                newFilteredData.push(bestBOADict[siteID]!)
            })
            filteredData = newFilteredData
        }
        return filteredData
    }

    useEffect(() => {
        setFilteredData(applyFilters())
    }, [unfilteredData, selectedFuelTypes, currentlyOfferedSites, firstPair, excludeExtremeOffers, excludeUndeliverableOffers])

    return (
        <Box width="100%">
            <Grid
                container
                spacing={0}
                columns={6}
                alignItems="stretch"
            >
                <BidOfferFuelSelect selectedTypes={selectedFuelTypes} setSelectedTypes={setSelectedFuelTypes} />
                <BidOfferToggle value={currentlyOfferedSites} setValue={setCurrentlyOfferedSites} label={"Currently Offered Sites"} />
                <BidOfferToggle value={firstPair} setValue={setFirstPair} label={"Show First Pair"} />
                <BidOfferToggle value={showDynamicData} setValue={setShowDynamicData} label={"Show Dynamic Data"} />
                <BidOfferToggle value={excludeExtremeOffers} setValue={setExcludeExtremeOffers} label={"Exclude Extreme Offers"} />
                <BidOfferToggle value={excludeUndeliverableOffers} setValue={setExcludeUndeliverableOffers} label={"Exclude Undeliverable Offers"} />
            </Grid>
        </Box>
    );
}
export interface IBidOfferTableFilters {
    unfilteredData: IBidOfferData[]
    setFilteredData: (filteredData: IBidOfferData[]) => void
    showDynamicData: boolean
    setShowDynamicData: (showDynamicData: boolean) => void
}

export default BidOfferTableFilters;