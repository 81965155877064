import { Paper } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IIRISHeartbeatData, ITAPortalHeartbeat } from "../../../../shared/types/analytics/trading-analytics/IIRISHeartbeatData";
import PortalHeartbeatTable from "./PortalHeartbeatTable";
import { useToastAlertContext } from "../../../ToastAlert";
import { useGetHeartbeatDataQuery } from "../../../../shared/api/TradingAnalyticsApi";

const PortalHeartbeatWrapper: React.FC<IPortalHeartbeatWrapper> = ({ }) => {
    const { data: heartbeatData, refetch } = useGetHeartbeatDataQuery()
    const { popToast } = useToastAlertContext();
    const [data, setData] = useState<IIRISHeartbeatData[]>([])
    let oldData = useRef<IIRISHeartbeatData[]>([]);

    const loadData = useCallback(async () => {
        refetch()
    }, [popToast])

    useEffect(() => {
        const intervalId = setInterval(() => {
            loadData();
        }, 60000);

        return () => clearInterval(intervalId);
    }, [loadData])

    const keys = useMemo(() => [
        "irisMel",
        "irisSel",
        "irisBod",
        "irisBoalf",
        "irisPn",
        "irisImbalng",
        "irisLolpdrm",
        "irisDisbsad",
        "nivCashoutModel",
        "systemPricePublication"
    ] as (keyof ITAPortalHeartbeat<any>)[], []);

    const nameDict: ITAPortalHeartbeat<string> = {
        irisBod: "IRIS_BOD",
        nivCashoutModel: "NIV_CASHOUT_MODEL",
        irisLolpdrm: "IRIS_LOLPDRM",
        irisMel: "IRIS_MEL",
        irisSel: "IRIS_SEL",
        irisBoalf: "IRIS_BOALF",
        irisPn: "IRIS_PN",
        irisImbalng: "IRIS_IMBALNG",
        irisDisbsad: "IRIS_DISBSAD",
        systemPricePublication: "SYSTEM_PRICE_PUBLICATION"
    }

    const thresholdDict: ITAPortalHeartbeat<number> = {
        irisBod: 40,
        nivCashoutModel: 10,
        irisLolpdrm: 40,
        irisMel: 30,
        irisSel: 30,
        irisBoalf: 20,
        irisPn: 40,
        irisImbalng: 40,
        irisDisbsad: 20,
        systemPricePublication: 40
    }

    const formatHeartbeatData = (incomingHeartbeatData: ITAPortalHeartbeat<number>) => {
        const formattedData: IIRISHeartbeatData[] = []
        keys.forEach(key => {
            const newName = nameDict[key]!
            const newValue = incomingHeartbeatData[key]
            const threshold = thresholdDict[key]!
            formattedData.push({
                dataItem: newName,
                ageTolerance: threshold,
                actualAge: newValue
            })
        })
        return formattedData
    }

    
    useEffect(() => {
        if (heartbeatData !== undefined) {
            const newData = formatHeartbeatData(heartbeatData)
            newData.forEach(newDataPoint => {
                if (oldData.current.length > 0) {
                    oldData.current.forEach(oldDataPoint => {
                        if (newDataPoint.actualAge && oldDataPoint.actualAge && oldDataPoint.dataItem === newDataPoint.dataItem && newDataPoint.ageTolerance < newDataPoint.actualAge && oldDataPoint.ageTolerance >= oldDataPoint.actualAge) {
                            popToast(`Data point [${oldDataPoint.dataItem}] has exceeded its threshold age`, "error")
                        }
                    })
                }
            })
            oldData.current = newData
            setData(newData)
        }
    }, [heartbeatData])
        
    return (
        <Paper sx={{ height: "100%", width: "100%" }}>
            <PortalHeartbeatTable data={data} />
        </Paper>
    );
}
export interface IPortalHeartbeatWrapper {
}

export default PortalHeartbeatWrapper;