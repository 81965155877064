import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { IBalancingSummaryCategories } from "../../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
const BalancingSummaryChartLegendCell: React.FC<IBalancingSummaryChartLegendCell> = ({ name, color, square }) => {
    const [parser] = useState<DOMParser>(new DOMParser())
    return (
        <Box
            sx={{ px: 1, "transition": "all .2s", "color": "inherit" }}
        >
            <Stack direction="row" spacing={0.5} alignItems="center">
                <span style={{ backgroundColor: color, width: "20px", height: square ? "20px" : "2px" }}></span>
                <span>{parser.parseFromString(name, "text/html").body.innerHTML}</span>
            </Stack>
        </Box>
    )
}

interface IBalancingSummaryChartLegendCell {
    name: string,
    color: string,
    square: boolean
}

const BalancingSummaryChartLegend: React.FC<IBalancingSummaryChartLegend> = ({ keys, nameDict, coloursDict }) => {

    return (
        <Box sx={{ mb: 1 }}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                {keys.map((keyArray, index) =>
                    <Grid xs={12} key={"Balancing-Summary-Chart-Legend-Row-" + index}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexWrap: 'wrap'
                        }}>
                            {keyArray.map((key) => {
                                const name = nameDict[key]
                                const color = coloursDict[key]
                                return (
                                    <BalancingSummaryChartLegendCell key={"Balancing-Summary-Legend-" + key} name={name} color={color} square={true} />
                                )
                            })}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
export interface IBalancingSummaryChartLegend {
    keys: (keyof IBalancingSummaryCategories<any>)[][]
    nameDict: IBalancingSummaryCategories<string>,
    coloursDict: IBalancingSummaryCategories<string>
}

export default BalancingSummaryChartLegend;