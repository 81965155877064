import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Tab, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useGetLiveAssetsQuery } from "../../shared/api/AssetApi";
import { Dictionary } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { BrokenImage, Download } from '@mui/icons-material';
import CSVButton from "../../components/data/CSVButton";
import { IEngineTrip } from "../../shared/types/operate/IEngineTrip";
import availabilityApi from "../../shared/api/AvailabilityApi";
import EngineTripTable from "../../components/operate/EngineTripTable";
import EngineTripGanttChart from "../../components/operate/EngineTripGanttChart";
import LoadingSymbol from "../../components/operate/LoadingSymbol";
import { IPortalRouteOptions } from "../../shared/types/shared/routes/routeTypes";
import { isMobile } from "react-device-detect";

const EngineTripsPage: React.FC<IEngineTripsPage> = () => {
    const [loaded, setLoaded] = React.useState(false);
    const { data: assets } = useGetLiveAssetsQuery();
    const [selectedAssets, setSelectedAssets] = useState<string[]>([])
    const [siteIdToName, setSiteIdToName] = useState<Dictionary<string>>({})

    const defaultStartTime = useMemo(() => moment().startOf("day"), []);
    const defaultEndTime = useMemo(() => moment().startOf("day").add(1, "day"), []);
    const [startDate, setStartDate] = useState<Moment>(defaultStartTime);
    const [endDate, setEndDate] = useState<Moment>(defaultEndTime);
    const [loadedData, setLoadedData] = useState<IEngineTrip[]>([])
    const [loadingTrips, setLoadingTrips] = useState<boolean>(false)
    const [resultsPanel, setResultsPanel] = useState<string>("Graph");

    const loadComplete = () => {
        setLoaded(true);
    }

    useEffect(() => {
        fetchPageData()
    }, [assets])

    const fetchPageData = () => {
        if (assets !== undefined) {
            var dictName: Dictionary<string> = {}
            assets?.forEach((item) => {
                dictName[item.SiteID] = item.Name
            });
            setSiteIdToName(dictName);

            var searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("assetid") !== "") {
                const searchAsset = searchParams.get("assetid")
                if (searchAsset !== null) {
                    setSelectedAssets([searchAsset]);
                }
            }
            loadComplete();
        }
    }

    const handleGatherDataClick = () => {
        setLoadingTrips(true)
        const filteredAssetsList = structuredClone(selectedAssets)
        const allIndex = selectedAssets.indexOf("All")
        if (allIndex >= 0) {
            filteredAssetsList.splice(allIndex, 1)
        }
        availabilityApi.getEngineTrips(moment(startDate).utc().format(), moment(endDate).utc().format(), filteredAssetsList)
            .then((response) => {
                response.forEach((trip) => {
                    trip.duration = moment(trip.tripEnd).diff(moment(trip.tripStart))
                })
                setLoadedData(response)
                setLoadingTrips(false)
            })
    }
    const columnsCsv = [
        { key: "siteID", label: "Site ID" },
        { key: "engineID", label: "Engine ID" },
        { key: "tripStart", label: "Trip Start" },
        { key: "tripEnd", label: "Trip End" },
        { key: "duration", label: "Duration (ms)"}
    ]

    const handleAssetChange = (event: SelectChangeEvent<typeof selectedAssets>) => {
        const {
            target: { value },
        } = event;
        const newAssets = typeof value === 'string' ? value.split(',') : value
        if (newAssets.indexOf("All") >= 0) {
            setSelectedAssets(["All", ...Object.keys(siteIdToName).sort()])
        }
        else {
            setSelectedAssets(newAssets)
        }
    };


    const handleStartChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setStartDate(newValue)
    }
    const handleEndChange = (newValue: Moment | null) => {
        if (!newValue) { return; }
        setEndDate(newValue)
    }
    const handleResultsPanelChange = (event: any, newValue: string) => {
        setResultsPanel(newValue)
    };
  return (
      <PageWrapper title="Engine Trips" loaded={loaded}>
          <Grid container>
              <Grid xs={12}>
                  <Paper sx={{ p: 2 }}>
                      <Grid container spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                              <Grid xs={isMobile ? 6 : 2}>
                                  <Box>
                                      <DateTimePicker
                                          label="Start Date (Local Time)"
                                          inputFormat="DD/MM/YYYY HH:mm"
                                          value={startDate}
                                          onChange={handleStartChange}
                                          renderInput={(params: any) => <TextField {...params} fullWidth />}
                                      />
                                  </Box>
                              </Grid>
                              <Grid xs={isMobile ? 6 : 2}>
                                  <Box>
                                      <DateTimePicker
                                          label="End Date (Local Time)"
                                          inputFormat="DD/MM/YYYY HH:mm"
                                          value={endDate}
                                          onChange={handleEndChange}
                                          renderInput={(params: any) => <TextField {...params} fullWidth />}
                                      />
                                  </Box>
                              </Grid>
                              <Grid xs={isMobile ? 12 : 2}>
                                  <Box >
                                      <FormControl fullWidth>
                                          <InputLabel id="asset-label">Assets</InputLabel>
                                          <Select
                                              labelId="asset-label"
                                              id="multiple-asset"
                                              multiple
                                              label="Assets"
                                              value={selectedAssets}
                                              onChange={handleAssetChange}
                                              renderValue={(selected) => {
                                                  if (selected.indexOf("All") >= 0) {
                                                      return "All"
                                                  }
                                                  const maxNumberOfAssets = 5
                                                  const renderedAssets: string[] = []
                                                  selected.sort().slice(0, maxNumberOfAssets).forEach((asset) => {
                                                      renderedAssets.push(siteIdToName[asset] || asset)
                                                  })
                                                  if (selected.length > maxNumberOfAssets) {
                                                      renderedAssets.push("...")
                                                  }
                                                  return renderedAssets.join(', ')
                                              }
                                              }
                                          >
                                              <MenuItem key={"All"} value={"All"}>All</MenuItem>
                                              {
                                                  Object.keys(siteIdToName).sort().map((item, index) =>
                                                      <MenuItem key={item} value={item}>{siteIdToName[item]}</MenuItem>
                                                  )
                                              }
                                          </Select>
                                      </FormControl>
                                  </Box>
                              </Grid>
                              <Grid xs={isMobile ? 6 : 3}>
                                  <Box
                                      sx={{ height: "100%" }}>
                                      <Button
                                          variant="contained"
                                          startIcon={<Download />}
                                          onClick={handleGatherDataClick}
                                          fullWidth
                                          size="large"
                                          sx={{ height: "55px" }}
                                          disabled={selectedAssets.length === 0 || loadingTrips}
                                      >
                                          Get Trips
                                      </Button>
                                  </Box>
                              </Grid>
                              <CSVButton data={loadedData} disabled={loadedData.length === 0 || loadingTrips} columns={columnsCsv} filename={'EngineTrips' + moment(startDate).format("YYYYMMDD") + '_to_' + moment(endDate).format("YYYYMMDD") + '.csv'} xs={isMobile ? 6 : 3} />
                          </LocalizationProvider>
                      </Grid>
                  </Paper>
              </Grid>
              <Grid xs={12}>
                  <Paper sx={{ p: 2, height: "100%", width: "100%", marginTop: 2 }}>
                  {!loadingTrips ? (
                      <TabContext value={resultsPanel}>
                          <TabList onChange={handleResultsPanelChange} aria-label="lab API tabs example" variant="fullWidth">
                              <Tab label="Graph" value="Graph" />
                              <Tab label="Table" value="Table" />
                          </TabList>
                          <TabPanel value="Graph">
                                  <Box height="fit-content">
                                      <EngineTripGanttChart trips={loadedData} siteIDToName={siteIdToName} startDate={startDate} endDate={endDate} />
                                  </Box>
                          </TabPanel>
                              <TabPanel value="Table" sx={{ p: "5px 0px 0px 0px" }}>
                              <Box height="fit-content">
                                  <EngineTripTable items={loadedData} siteIdToName={siteIdToName} searchBar={true} />
                              </Box>
                          </TabPanel>
                      </TabContext>
                      ) : <LoadingSymbol />}
                  </Paper>
              </Grid>
          </Grid>
    </PageWrapper>
  );
}

export interface IEngineTripsPage {

}

const EngineTripsPagePageConfig: IPortalRouteOptions = {
    relativeUrl: "engine-trips",
    page: <EngineTripsPage />,
    navDisplay: {
        title: "Engine Trips",
        icon: <BrokenImage />
    }
}

export default EngineTripsPagePageConfig;