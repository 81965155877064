import PageWrapper from "../../../components/PageWrapper";
import React, { useEffect } from "react";
import { Favorite } from "@mui/icons-material";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import PortalHeartbeatWrapper from "../../../components/analytics/trading-analytics/portal-heartbeat/PortalHeartbeatWrapper";

const PortalHeartbeatPage: React.FC<IPortalHeartbeatPage> = () => {
    const [loaded, setLoaded] = React.useState(false);

    const loadComplete = () => {
        setLoaded(true);

    }

    useEffect(() => {
        loadComplete()
    }, []);

    return (
        <PageWrapper title="Portal Heartbeat" loaded={loaded}>
            <PortalHeartbeatWrapper />
        </PageWrapper>
    );
}


export interface IPortalHeartbeatPage {

}

const PortalHeartbeatPageConfig: IPortalRouteOptions = {
    relativeUrl: "portal-heartbeat",
    page: <PortalHeartbeatPage />,
    navDisplay: {
        title: "Portal Heartbeat",
        icon: <Favorite />
    },
}

export default PortalHeartbeatPageConfig;