import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import Icon from "@mdi/react";
import { mdiClipboardListOutline } from "@mdi/js";
import { Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Moment } from "moment";
import moment from "moment";
import { DateTimeFormatter } from "../../../components/analytics/helpers/DateFormatter";
import { useColourModeContext } from "../../../components/ColourMode";

const MonthlyAvailabilityFileCheckerPage: React.FC<IMonthlyAvailabilityFileCheckerPageProps> = () => {
    const { theme } = useColourModeContext();
    const [lastRunDate, setLastRunDate] = useState<Moment>();
    const [data, setData] = useState<IBessMonthlyAvailabilityDataPoint[]>([]);

    useEffect(() => {
        const loadedData = dummyData;
        setLastRunDate(moment(loadedData.lastRunTimestamp));
        setData(loadedData.data);
    }, []);

    return (
        <PageWrapper title="Monthly Availability File Checker" loaded={true}>
            <Stack spacing={2}>
                <Typography variant="h6">Check last run {DateTimeFormatter(lastRunDate)}</Typography>
                <TableContainer sx={{ height: "calc(100vh - 225px)" }}>
                    <Table component={Paper} sx={{ width: "100%" }} stickyHeader>
                        <TableHead>
                            <TableRow sx={{ [`>.${tableCellClasses.head}`]: { backgroundColor: theme.palette.background.paper } }}>
                                <TableCell align="center">Site</TableCell>
                                <TableCell align="center">Timestamp</TableCell>
                                <TableCell align="center">Original Availability</TableCell>
                                <TableCell align="center">Updated Availability</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.map((d) => {
                                    return (
                                        <TableRow key={`${d.siteName}-${d.timestamp}`}>
                                            <TableCell align="center">{d.siteName}</TableCell>
                                            <TableCell align="center">{DateTimeFormatter(d.timestamp)}</TableCell>
                                            <TableCell align="center">{d.originalAvailability}%</TableCell>
                                            <TableCell align="center">{d.updatedAvailability}%</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </PageWrapper>
    );
}

export interface IMonthlyAvailabilityFileCheckerPageProps {

}

const MonthlyAvailabilityFileCheckerPageConfig: IPortalRouteOptions = {
    relativeUrl: "monthly-availability-file-checker",
    page: <MonthlyAvailabilityFileCheckerPage />,
    navDisplay: {
        title: "Monthly Availability",
        icon: <Icon path={mdiClipboardListOutline} size={1} />,
    },
}

export default MonthlyAvailabilityFileCheckerPageConfig;

interface IBessMonthlyAvailabilityData {
    lastRunTimestamp: string;
    data: IBessMonthlyAvailabilityDataPoint[];
}

interface IBessMonthlyAvailabilityDataPoint {
    siteName: string;
    timestamp: string;
    originalAvailability: number;
    updatedAvailability: number;
}

const dummyData: IBessMonthlyAvailabilityData =
{
    lastRunTimestamp: "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
    data: [
        { siteName: "Blackpool", timestamp: "Mon Nov 04 2024 04:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Blackpool", timestamp: "Mon Nov 04 2024 05:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 04:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 05:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 06:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 07:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 08:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 09:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 10:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 11:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 12:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 13:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 14:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 15:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 16:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 17:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 18:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 19:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
        { siteName: "Flitwick", timestamp: "Mon Nov 04 2024 20:00:00 GMT+0000 (Greenwich Mean Time)", originalAvailability: 0, updatedAvailability: 0 },
    ]
}
