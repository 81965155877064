import React, { useEffect, useMemo, useState } from "react";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import { CheckOutlined, WorkHistoryOutlined } from "@mui/icons-material";
import PageWrapper from "../../../components/PageWrapper";
import { Button, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { conradEnergyFreshGreenColour, conradEnergyRedColour } from "../../../shared/theme/themeBrand";
import ShiftLogSubmissionForm from "../../../components/operate/shift-logging/ShiftLogSubmissionForm";
import IShiftLog from "../../../shared/types/operate/shift-logging/IShiftLog";
import ShiftLogTableFilters from "../../../components/operate/shift-logging/ShiftLogTableFilters";
import { useGetAssetsQuery } from "../../../shared/api/AssetApi";
import IShiftLogFilterData from "../../../shared/types/operate/shift-logging/IShiftLogFilterData";

const ShiftLoggingPage: React.FC<IShiftLoggingPageProps> = () => {
    const { data: assets, isLoading } = useGetAssetsQuery();

    const [data, setData] = useState<IShiftLog[]>([]);

    useEffect(() => {
        setData(dummyData);
    }, [dummyData]);

    const [logToEdit, setLogToEdit] = useState<IShiftLog>();

    const columns: GridColDef<IShiftLog>[] = useMemo(() => [
        {
            field: 'openingTimestamp',
            headerName: 'Opening Timestamp',
            headerAlign: "center",
            align: "center",
            flex: 1,
            maxWidth: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Button
                    sx={{ color: "white !important" }}
                    onClick={() => { console.log (params.row); setLogToEdit(params.row); }}
                >
                    {moment(params.value).format("YYYY-MM-DD HH:mm:ss")}
                </Button>
            ),
        },
        {
            field: 'siteName',
            headerName: 'Site',
            headerAlign: "center",
            align: "center",
            flex: 1,
            maxWidth: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography>{params.value}</Typography>
            ),
        },
        {
            field: 'priority',
            headerName: 'Priority',
            headerAlign: "center",
            align: "center",
            flex: 1,
            maxWidth: 200,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography
                    sx={{ color: params.value === "High" ? conradEnergyRedColour.main : "" }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography component="p" dangerouslySetInnerHTML={{ __html: params.value || "" }} />
            ),
        },
        {
            field: 'ongoing',
            headerName: 'Ongoing',
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params: GridRenderCellParams<boolean>) => (
                <>{params.value ? <CheckOutlined sx={{ color: conradEnergyFreshGreenColour.main }} /> : ""}</>
            ),
        },
    ], []);

    const onFiltersUpdated = (filters: IShiftLogFilterData) => {
        // TODO: Handle filtering data in backend request here
        console.log (filters);
    }

    return (
        <PageWrapper title="Shift Logging" loaded={!isLoading}>
            <Stack spacing={2}>
                <ShiftLogTableFilters assets={assets} onFiltersUpdated={onFiltersUpdated} />

                <Paper sx={{ width: "100%", height: "calc(100vh - 460px)", minHeight: "300px" }}>
                    <DataGrid
                        sx={{
                            overflowX: "auto",
                            border: "none",
                            borderRadius: 0,
                            ".MuiDataGrid-columnHeaders": { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
                            ".MuiDataGrid-cell": { whiteSpace: "normal !important", wordWrap: "break-word !important" },
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        }}
                        getRowId={(row) => `${row.openingTimestamp}-${row.siteName}`}
                        getRowHeight={() => 'auto'}
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                pageSize: 100,
                            },
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                        disableSelectionOnClick
                    />
                </Paper>

                <ShiftLogSubmissionForm assets={assets} editingData={logToEdit} onClearForm={() => setLogToEdit(undefined)} />
            </Stack>
        </PageWrapper>
    );
}

export interface IShiftLoggingPageProps {

}

const ShiftLoggingPageConfig: IPortalRouteOptions = {
    relativeUrl: "shift-logging",
    page: <ShiftLoggingPage />,
    navDisplay: {
        title: "Shift Logging",
        icon: <WorkHistoryOutlined />,
    },
}

export default ShiftLoggingPageConfig;

const dummyData: IShiftLog[] = [
    {
        id: 0,
        openingTimestamp: "Tue October 09 2024 15:24:00 GMT+0000 (Greenwich Mean Time)",
        siteId: "FLICK",
        siteName: "Flitwick",
        priority: "High",
        text: "[Sam Brewitt-Taylor, 9/10/24 15:24]<br/>Phone call from Bob Smith, member of the public, 07891 123123, says Flitwick is on fire<br/>[Adam Whitehead, 9/10/24 16:21]<br/>Phone call from Jane Smith, Bob's wife, saying he is drunk and should be ignored.",
        ongoing: true,
    },
    {
        id: 1,
        openingTimestamp: "Tue October 09 2024 15:01:00 GMT+0000 (Greenwich Mean Time)",
        siteId: "ARNTT",
        siteName: "Arncott",
        priority: "Low",
        text: "[Chris Cody, 9/10/24 15:01]<br/>Routine testing at Arncott completed, engineer Joe Smith has left the site.",
        ongoing: false,
    }
];
