import {
    PaletteColor, PaletteColorOptions
} from "@mui/material/styles";
import type { } from "@mui/x-data-grid/themeAugmentation";
import type { } from '@mui/lab/themeAugmentation';

declare module "@mui/material/styles" {
    interface Palette {
        conradEnergyFreshGreen: PaletteColor,
        conradEnergyGrey: PaletteColor,
        conradEnergyLightGrey: PaletteColor,
        conradEnergyMagenta: PaletteColor,
        conradEnergyCyan: PaletteColor,
        conradEnergyDeepBlue: PaletteColor,
        conradEnergyYellow: PaletteColor,
    }
    interface PaletteOptions {
        conradEnergyFreshGreen: PaletteColorOptions,
        conradEnergyGrey: PaletteColorOptions,
        conradEnergyLightGrey: PaletteColorOptions,
        conradEnergyMagenta: PaletteColorOptions,
        conradEnergyCyan: PaletteColorOptions,
        conradEnergyDeepBlue: PaletteColorOptions,
        conradEnergyYellow: PaletteColorOptions,
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        conradEnergyGrey: true,
        conradEnergyLightGrey: true,
        conradEnergyFreshGreen: true,
        conradEnergyMagenta: true,
        conradEnergyCyan: true,
        conradEnergyDeepBlue: true,
        conradEnergyYellow: true,
    }
}

declare module "@mui/material/Typography" {
    interface ButtonPropsColorOverrides {
        conradEnergyFreshGreen: true;
        conradEnergyCyan: true;
        conradEnergyDeepBlue: true;
        conradEnergyYellow: true;
        conradEnergyMagenta: true;
        conradEnergyGrey: true;
        conradEnergyLightGrey: true;
    }
}

export const conradEnergyGreyColour = {
    main: "#B1C0C8",
    light: "#c0ccd3",
    dark: "#7b868c",
    contrastText: "#000000",
    "50": "#e8ecee",
    "100": "#c6d0d4",
    "200": "#b1c0c8",
    "300": "#92a7b2",
    "400": "#7a93a0",
    "500": "#62808f",
    "600": "#56717e",
    "700": "#475d68",
    "800": "#394a53",
    "900": "#28353c",
};

export const conradEnergyLightGreyColour = {
    main: "#E8ECEE",
    light: "#eceff1",
    dark: "#a2a5a6",
    contrastText: "#000000",
    "50": "#e8ecee",
    "100": "#c6d0d4",
    "200": "#a2b1b7",
    "300": "#7d939b",
    "400": "#627d87",
    "500": "#486873",
    "600": "#3d5a64",
    "700": "#30474f",
    "800": "#23353b",
    "900": "#132126"
};

export const conradEnergyFreshGreenColour = {
    main: "#31FE64",
    light: "#5afe83",
    dark: "#22b146",
    contrastText: "#000000",
    "50": "#eaffeb",
    "100": "#cbffcd",
    "200": "#a5ffac",
    "300": "#73ff86",
    "400": "#31fe64",
    "500": "#00fb41",
    "600": "#00e939",
    "700": "#00d22e",
    "800": "#00bd22",
    "900": "#009715"
};

export const conradEnergyMagentaColour = {
    main: "#FF47F4",
    light: "#ff6bf6",
    dark: "#b231aa",
    contrastText: "#000000",
    "50": "#ffe5fd",
    "100": "#ffbcf9",
    "200": "#ff8af6",
    "300": "#ff47f3",
    "400": "#fc00ef",
    "500": "#f000e4",
    "600": "#de00e0",
    "700": "#c600db",
    "800": "#b200d6",
    "900": "#8a00cd"
};

export const conradEnergyCyanColour = {
    main: "#00E5FC",
    light: "#33eafc",
    dark: "#00a0b0",
    contrastText: "#000000",
    "50": "#e0fbfe",
    "100": "#b0f5fd",
    "200": "#75effd",
    "300": "#00e7fc",
    "400": "#00e1fa",
    "500": "#00d9f9",
    "600": "#00c8e4",
    "700": "#00b2c8",
    "800": "#009dae",
    "900": "#00797f"
};

export const conradEnergyDeepBlueColour = {
    main: "#3942FF",
    light: "#6067ff",
    dark: "#272eb2",
    contrastText: "#FFFFFF",
    "50": "#eaeaff",
    "100": "#cac9ff",
    "200": "#a5a7ff",
    "300": "#7c83ff",
    "400": "#5b64ff",
    "500": "#3943ff",
    "600": "#3539f3",
    "700": "#2a2ce6",
    "800": "#1f1bdb",
    "900": "#0000cb",    
};

export const conradEnergyYellowColour = {
    main: "#FCE300",
    light: "#fce833",
    dark: "#b09e00",
    contrastText: "#000000",
    "50": "#fffce4",
    "100": "#fff6be",
    "200": "#fef092",
    "300": "#fdeb65",
    "400": "#fce63d",
    "500": "#fce300",
    "600": "#fbd000",
    "700": "#fab700",
    "800": "#f99e00",
    "900": "#f77200"
};

export const conradEnergyRedColour = {
    main: "#ff5347",
    light: "#f4746e",
    dark: "#ff4227",
    contrastText: "#000000",
    "50": "#ffebee",
    "100": "#ffced1",
    "200": "#fb9c97",
    "300": "#f4746e",
    "400": "#ff5347",
    "500": "#ff4227",
    "600": "#f63828",
    "700": "#e42c23",
    "800": "#d7231b",
    "900": "#c7160c"
};
