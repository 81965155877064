import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { utcToSP } from '../../../../shared/utils/SPCalcUtils';
import moment from "moment";
import DataHealthFeedButton from "./DataHealthFeedButton";

const SPSelectorButton: React.FC<ISPSelectorButton> = ({ value, spDifference, setSPDifference, disabled = false }) => {
    const theme = useTheme()
    return (
        <Box
            height="10vh"
        >
            <Button
                sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: value === spDifference || disabled ? theme.palette.conradEnergyDeepBlue.dark : "default",
                    border: value === spDifference && !disabled ? "2px solid white" : "default"
                }}
                onClick={() => setSPDifference(value)}
                disabled={disabled}
            >
                <Typography variant="h6">P{value >= 0 && "+"}{value}</Typography>
            </Button>
        </Box>
    )
}

interface ISPSelectorButton {
    value: number
    spDifference: number
    setSPDifference: (value: number) => void
    disabled?: boolean
}

const BSADSelectorButton: React.FC<IBSADSelectorButton> = ({ showBSADData, setShowBSADData }) => {
    const theme = useTheme()
    return (
        <Box
            height="10vh"
        >
            <Button
                sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: showBSADData ? theme.palette.conradEnergyDeepBlue.dark : "default",
                    border: showBSADData ? "2px solid white" : "default"
                }}
                onClick={() => setShowBSADData(!showBSADData)}
            >
                <Typography variant="h6">BSAD</Typography>
            </Button>
        </Box>
    )
}

interface IBSADSelectorButton {
    showBSADData: boolean
    setShowBSADData: (showBSADData: boolean) => void
}

const SPPicker: React.FC<ISPPicker> = ({ setSelectedSP, showBSADData, setShowBSADData }) => {
    const [spDifference, setSPDifference] = useState<number>(0)
    const [currentSP, setCurrentSP] = useState<number>(24)

    useEffect(() => {
        setSelectedSP(currentSP + spDifference)
    }, [currentSP, spDifference])

    const calcCurrentSP = useCallback(() => {
        const currentTime = moment().utc().toISOString()
        setCurrentSP(utcToSP(currentTime))
    }, [])

    useEffect(() => {
        calcCurrentSP()
        const intervalId = setInterval(() => {
            calcCurrentSP();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [calcCurrentSP])

    return (
        <Box width="100%">
            <Paper>
                <Grid
                    container
                    columns={11}
                    spacing={2}
                >
                    <Grid xs={1.5}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: "100%" }}
                        >
                            SP Selector
                        </Stack>
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={-4} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={-3} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={-2} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={-1} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={0} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={1} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <SPSelectorButton value={2} spDifference={spDifference} setSPDifference={setSPDifference} disabled={showBSADData} />
                    </Grid>
                    <Grid xs={1}>
                        <BSADSelectorButton showBSADData={showBSADData} setShowBSADData={setShowBSADData} />
                    </Grid>
                    <Grid xs={1.5}>
                        <DataHealthFeedButton />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}
export interface ISPPicker {
    setSelectedSP: (selectedSP: number) => void
    showBSADData: boolean
    setShowBSADData: (showBSADData: boolean) => void
}

export default SPPicker;