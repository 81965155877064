import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../operate/LoadingSymbol";
import { Area, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart } from 'recharts';
import { useColourModeContext } from "../../components/ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateTimeFormatter } from "./helpers/DateFormatter"

const WindForecastChart: React.FC<IWindForecastChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();

    const loadWindData = useCallback(() => {
        const formattedData = new Array<IAnalyticsChartDataItem>();
        analyticsApi.getWindData()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const dateTimes = new Set<number>()
                response.forEach((dataItem) => {
                    const dataMoment = moment(dataItem.dateTimeUTC + "Z").valueOf()
                    if (!dateTimes.has(dataMoment)) {
                        dateTimes.add(dataMoment)
                        const newItem: IAnalyticsChartDataItem = {
                            dateTime: dataMoment,
                            nationalGrid: dataItem.nationalGrid,
                            meteoPercFifty: dataItem.perc50,
                            catalystUnrestricted: dataItem.unrestrictedForecast,
                            catalystNormal: dataItem.normalForecast,
                            actual: dataItem.actual,
                            windPrdMinMax: (dataMoment > currentMoment ? [dataItem.perc10, dataItem.perc90] : undefined)
                        }
                        formattedData.push(newItem)
                    }
                })
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(formattedData)
            })
    }, [])


    useEffect(() => {
        loadWindData()
        const intervalId = setInterval(() => {
            loadWindData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadWindData])

    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Wind Forecast</Typography>
                <ResponsiveContainer height={"87%" }>
                    {(chartData.length > 0 ?
                        <ComposedChart data={chartData}>
                            <XAxis name="Time" domain={[domainStart, domainEnd]} dataKey="dateTime" scale="time" type="number" tickFormatter={DateTimeFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis><Label dx={-25} angle={-90}>Wind (MW)</Label></YAxis>
                            {(!displayScreen ? <Legend /> : <></>)}
                            <Tooltip
                                contentStyle={{ backgroundColor: (colourMode.isDarkMode ? "#262626" : "white") }}
                                labelFormatter={value => {
                                    return "Time: " + DateTimeFormatter(value) + " UTC";
                                }}
                            />
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <Line type="monotone" dataKey="nationalGrid" name="National Grid" unit=" MW" stroke="#AAAAAA" dot={false} activeDot={false} isAnimationActive={false} />
                            <Line type="monotone" dataKey="meteoPercFifty" name="Meteo Perc 50" unit=" MW" stroke={NewColorPalette[14]} dot={false} activeDot={false} strokeDasharray="5 5" isAnimationActive={false} />
                            <Line type="monotone" dataKey="catalystUnrestricted" name="Catalyst Unrestricted" unit=" MW" stroke={NewColorPalette[0]} dot={false} activeDot={false} strokeDasharray="5 5" isAnimationActive={false} />
                            <Line type="monotone" dataKey="catalystNormal" name="Catalyst Normal" unit=" MW" stroke={NewColorPalette[7]} dot={false} activeDot={false} strokeDasharray="5 5" isAnimationActive={false} />
                            <Line type="monotone" dataKey="actual" name="Actual" unit=" MW" stroke={NewColorPalette[4]} dot={false} activeDot={false} isAnimationActive={false} />
                            <Area dataKey="windPrdMinMax" name="Meteo Perc 10-90" unit=" MW" stroke="#606060" fill="#606060" type="monotone" label={false} legendType="none" isAnimationActive={false} />
                        </ComposedChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IWindForecastChart {
    displayScreen?: boolean
}

export default WindForecastChart;