import { Box, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Area, ComposedChart, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TimeFormatter } from "../../helpers/DateFormatter"
import moment, { Moment } from "moment";
import { GetBalancingSummaryBackgroundColors } from "../../helpers/TradingAnalyticsFuelColours";
import { CalcXTicks, CalcYTicks } from "./helpers/BalancingSummaryTickCalculators";
import { IBalancingSummaryAggregates, IBalancingSummaryBidValues, IBalancingSummaryCategories, IBalancingSummaryData, IBalancingSummaryFuelValues, IBalancingSummaryOfferValues, IBalancingSummaryPriceValues } from "../../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
import BalancingSummaryChartLegend from "./BalancingSummaryChartLegend";
import BalancingSummaryChartTooltip from "./BalancingSummaryChartTooltip";

const BalancingSummaryChart: React.FC<IBalancingSummaryChart> = ({ chartData, currentTime, priceView, setSelectedTime }) => {
    const theme = useTheme()
    const defaultStartTime = useMemo(() => moment.utc().subtract(4, "hours"), []);
    const defaultEndTime = useMemo(() => moment.utc().add(2, "hours"), []);
    const [domainStart, setDomainStart] = useState<Moment>(defaultStartTime)
    const [domainEnd, setDomainEnd] = useState<Moment>(defaultEndTime)
    const [xTicks, setXTicks] = useState<number[]>([])
    const defaultLeftYDomain = 500
    const rightYAxisMin = -10
    const [leftYDomain, setLeftYDomain] = useState<number[]>([defaultLeftYDomain, 0 - defaultLeftYDomain])
    const [leftYTicks, setLeftYTicks] = useState<number[]>([])

    const coloursDict = GetBalancingSummaryBackgroundColors()

    const fuelKeys = useMemo(() => [
        "biomass",
        "ccgt",
        "flexGen",
        "battery",
        "nuclear",
        "interconnectors",
        "pumped",
        "hydro",
        "bsadUp",
        "bsadDown",
    ] as (keyof IBalancingSummaryFuelValues<any>)[], []);

    const bidKeys = useMemo(() => [
        "bid250Plus",
        "bid200To250",
        "bid170To200",
        "bid140To170",
        "bid120To140",
        "bid100To120",
        "bid80To100",
        "bid65To80",
        "bid50To65",
        "bid0To50",
    ] as (keyof IBalancingSummaryBidValues<any>)[], []);

    const offerKeys = useMemo(() => [
        "offer0Minus",
        "offer0To10",
        "offer10To20",
        "offer20To30",
        "offer30To35",
        "offer35To40",
        "offer40To45",
        "offer45To50",
        "offer50To55",
        "offer55Plus",
    ] as (keyof IBalancingSummaryOfferValues<any>)[], []);

    const priceKeys = useMemo(() => [
        ...bidKeys,
        ...offerKeys
    ] as (keyof IBalancingSummaryPriceValues<any>)[], []);

    const aggregateKeys = useMemo(() => [
        "overallImbalance"
    ] as (keyof IBalancingSummaryAggregates<any>)[], []);

    const nameDict: IBalancingSummaryCategories<string> = {
        biomass: "Biomass",
        ccgt: "CCGT",
        flexGen: "Flex Gen",
        battery: "Battery",
        nuclear: "Nuclear",
        interconnectors: "Interconnectors",
        pumped: "Pumped",
        hydro: "Hydro",
        bsadUp: "BSAD Up",
        bsadDown: "BSAD Down",
        bid250Plus: "&pound;250+",
        bid200To250: "&pound;200-&pound;250",
        bid170To200: "&pound;170-&pound;200",
        bid140To170: "&pound;140-&pound;170",
        bid120To140: "&pound;120-&pound;140",
        bid100To120: "&pound;100-&pound;120",
        bid80To100: "&pound;80-&pound;100",
        bid65To80: "&pound;65-&pound;80",
        bid50To65: "&pound;50-&pound;65",
        bid0To50: "&pound;0-&pound;50",
        offer0Minus: "Less than &pound;0",
        offer0To10: "&pound;0-&pound;10",
        offer10To20: "&pound;10-&pound;20",
        offer20To30: "&pound;20-&pound;30",
        offer30To35: "&pound;30-&pound;35",
        offer35To40: "&pound;35-&pound;40",
        offer40To45: "&pound;40-&pound;45",
        offer45To50: "&pound;45-&pound;50",
        offer50To55: "&pound;50-&pound;55",
        offer55Plus: "&pound;55+",
        overallImbalance: "Overall Imbalance"
    }

    useEffect(() => {
        const copy = moment(currentTime)
        const startOfDay = moment(copy).subtract(4, "hours")
        const endOfDay = moment(copy).add(2, "hours")
        setDomainStart(startOfDay)
        setDomainEnd(endOfDay)
    }, [currentTime])

    useEffect(() => {
        // Setting x ticks
        setXTicks(CalcXTicks(domainStart, domainEnd, currentTime))
    }, [domainStart, domainEnd, currentTime])

    useEffect(() => {
        // Setting y ticks
        const keys = priceView ? priceKeys : fuelKeys
        let leftTicks = CalcYTicks(chartData, keys)
        setLeftYDomain([leftTicks[0], leftTicks[leftTicks.length - 1]])
        setLeftYTicks(leftTicks)
    }, [chartData, fuelKeys, priceKeys, priceView, rightYAxisMin])

    const handleChartClick = async (clickedData: IBalancingSummaryData) => {
        const newSelectedTime = moment(clickedData.dateTime).utc().startOf("minute")
        setSelectedTime(newSelectedTime)
    }

    const CustomizedDot = (props: any) => {
        const { cx, cy, payload } = props;
        return (
            <circle cx={cx - 10} cy={cy - 10} r={5000} opacity={0} onClick={async () => await handleChartClick(payload)} />
        );
    };

    
    const renderAreas = () => {
        const keys = priceView ? priceKeys : fuelKeys 
        const bars = keys.map((key) => {
            const name = nameDict[key]
            const color = coloursDict[key]
            return (
                <Area dataKey={key} key={key} name={name} stackId={"1"} unit=" MW" fill={color} stroke={color} type="monotone" opacity={1} fillOpacity={0.7} isAnimationActive={false} activeDot={false} />
            )
        })
        return bars
    }

    const renderLines = () => {
        const lines = aggregateKeys.map((key) => {
                const name = nameDict[key]
                const color = coloursDict[key]
                return (
                    <Line type="monotone" dataKey={key} key={key} name={name} stroke={color} strokeWidth={3} dot={false} isAnimationActive={false} activeDot={<CustomizedDot />} />
                )
            })
        return lines
    }

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload as IBalancingSummaryData
            return (
                <BalancingSummaryChartTooltip data={data} squareKeys={priceView ? priceKeys : fuelKeys} lineKeys={aggregateKeys} nameDict={nameDict} coloursDict={coloursDict} />
            );
        }
        return null
    };

    return (
        <Box
            height="100%"
            width="100%"
            pl={1}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <ResponsiveContainer height={"100%"}>
                <ComposedChart data={chartData}>
                    <XAxis name="Time" domain={[domainStart.valueOf(), domainEnd.valueOf()]} dataKey="dateTime" scale="time" type="number" ticks={xTicks} tickFormatter={TimeFormatter} allowDataOverflow={true} />
                    <YAxis type="number" interval={0} domain={leftYDomain} ticks={leftYTicks} allowDataOverflow={true}> <Label dx={-30} angle={-90}>MW</Label></YAxis>
                    {renderAreas()}
                    {renderLines()}
                    <ReferenceLine x={currentTime.valueOf()} stroke={theme.palette.conradEnergyDeepBlue.main} opacity={100} isFront={true} strokeWidth={3} />
                    <ReferenceLine y={0} stroke={"white"} opacity={0.5} isFront={true} strokeWidth={1} />
                    <Legend verticalAlign="top" align="center" content={<BalancingSummaryChartLegend keys={priceView ? [bidKeys, offerKeys] : [fuelKeys]} nameDict={nameDict} coloursDict={coloursDict} />} />
                    <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltip payload={chartData} />} />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}
export interface IBalancingSummaryChart {
    chartData: IBalancingSummaryData[]
    currentTime: Moment,
    priceView: boolean,
    setSelectedTime: (selectedTime: Moment) => void
}

export default BalancingSummaryChart;