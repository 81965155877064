import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { Dictionary } from "@reduxjs/toolkit";
import { IEngine } from "../../shared/types/operate/IEngine";
import EngineOutageTable from "./EngineOutageTable";
import { IEngineOutage } from "../../shared/types/operate/IEngineOutage";
import ReasonSelector from "./outage-forms/ReasonSelector";
import availabilityApi from "../../shared/api/AvailabilityApi";
import UpdateConfirm from "./outage-forms/UpdateConfirm";
import { OperateType } from "../../shared/types/operate/OperateType";
import { IAdvancedModalOptions, useModalWindowContext } from "../ModalWindow";
import { isMobile } from "react-device-detect";
import OverlappingOutagesWarning from "./outage-forms/OverlappingOutagesWarning";
import AssetEngineSelector from "./outage-forms/AssetEngineSelector";
import { useNavigate } from "react-router-dom";
import UpdateOutageOption from "./outage-forms/UpdateOutageOption";
import OutageFormDate from "./outage-forms/OutageFormDate";
import OutageFormTime from "./outage-forms/OutageFormTime";
import OutageFormSelect from "./outage-forms/OutageFormSelect";
import OutageFormNumber from "./outage-forms/OutageFormNumber";
import OutageFormString from "./outage-forms/OutageFormString";
import OutageFormSubmitButton from "./outage-forms/OutageFormSubmitButton";
import RepeatingOutageSelector from "./outage-forms/RepeatingOutageSelector";
import { IOutageOperationResponse } from "../../shared/types/operate/IOutageOperationResponse";

const OutageForms: React.FC<IOutageFormsProps> = ({ type, siteIdToName, siteIdToCapacity, siteIDToGenerators, siteIDToType }) => {
    const { openModal } = useModalWindowContext();
    const theme = useTheme();
    const [reason, setReason] = useState<string>("Servicing");
    const defaultStartTime = useMemo(() => moment().startOf("hour"), []);
    const defaultEndTime = useMemo(() => moment().startOf("hour").add(1, "hour"), []);
    const [outageStart, setOutageStart] = useState<Moment>(defaultStartTime);
    const [outageEnd, setOutageEnd] = useState<Moment>(defaultEndTime);
    const [comments, setComments] = useState<string>("");
    const [outageType, setOutageType] = useState<string>("Planned")
    const [error, setError] = useState<string>("");
    const [futureOutage, setFutureOutage] = useState<boolean>(false)
    const [inserting, setInserting] = useState<boolean>(false);
    const [asset, setAsset] = React.useState<string>("")
    const [selectedAssetType, setSelectedAssetType] = useState<string>("")
    const [generator, setGenerator] = React.useState<string>("")
    const [newEngineOutages, setNewEngineOutages] = React.useState<IEngineOutage[]>([])
    var queryRemit = new URLSearchParams(window.location.search).get("RemitID");
    const [remitID, setRemitID] = useState<string>((queryRemit !== null && !isNaN(parseInt(queryRemit)) ? queryRemit : ""));
    const [loadedOnce, setLoadedOnce] = useState<boolean>(false)
    const [loadedOutage, setLoadedOutage] = useState<boolean>(type !== "Update")
    const [initialOutage, setInitialOutage] = useState<IEngineOutage | null>(null);
    const [outageVariety, setOutageVariety] = useState<string>("Outage")
    const [remainingAvailability, setRemainingAvailability] = useState<string>("0")
    const [capacity, setCapacity] = useState<number>(0)
    const [workOrder, setWorkOrder] = useState<string>("")
    const [modifyOrCancel, setModifyOrCancel] = useState<string>("Modify")
    const [repeating, setRepeating] = useState<boolean>(false)
    const [repeatingInterval, setRepeatingInterval] = useState<string>("Day")
    const [repeatUntil, setRepeatUntil] = useState<Moment>(moment().startOf("hour").add(1, "day"))
    const [modifyAll, setModifyAll] = useState<boolean>(false)
    const [loadedRepeatingOutage, setLoadedRepeatingOutage] = useState<boolean>(false)
    const [bucket, setBucket] = useState<string>("")
    const navigate = useNavigate();



    useEffect(() => {
        // Setting right asset type
        setSelectedAssetType(siteIDToType[asset] || "")
    }, [asset])


    useEffect(() => {
        // Error Checking
        const remainingAvailabilityNumerical = parseFloat(remainingAvailability)
        if (asset === "") {
            setError("Please Select an Asset")
        }
        else if (isNaN(remainingAvailabilityNumerical)) {
            setError("Please enter a value for the remaining availability")
        }
        else if (!outageStart.isValid()) {
            setError("Please enter a valid date and time for the outage start")
        }
        else if (!outageEnd.isValid()) {
            setError("Please enter a valid date and time for the outage end")
        }
        else if (outageStart.isAfter(outageEnd)) {
            setError("Start Date must be before End Date")
        }
        else if (type === "Submit" && outageStart.isBefore(defaultStartTime)) {
            setError("Start Date cannot be set to the past")
        }
        else if (outageEnd.isBefore(defaultStartTime)) {
            setError("End Date cannot be set to the past")
        }
        else if (remainingAvailabilityNumerical < 0 || remainingAvailabilityNumerical > capacity) {
            setError("Lost Availability must be between 0 MW and capacity of " + capacity + " MW")
        }
        else if (repeating && outageEnd.clone().startOf("day").isAfter(repeatUntil)) {
            setError("Outage End must be before or on the Repeat Until date")
        }
        else if (bucket === "") {
            setError("Please select a value for bucket")
        }
        else {
            setError("")
        }
    }, [asset, outageStart, outageEnd, remainingAvailability, capacity, bucket, repeating, repeatUntil])


    useEffect(() => {
        // Load outage data for update
        if (!loadedOnce && remitID !== "") {
            setInserting(true);
            availabilityApi.getOutage(remitID)
                .then((outage) => {
                    if (outage && outage.live) {
                        const futureOutage = moment(outage.outageStart) > moment()
                        const outageEnd = futureOutage ? moment(outage.outageEnd) : moment().startOf("hour").add(1, "hour")
                        setVariablesHelper(outage.siteID, outage.engineID, moment(outage.outageStart), outageEnd, outage.outageType, outage.comments, outage.detail, outage.mwOutage, outage.restriction, outage.workOrder, outage.bucket)
                        setLoadedOnce(true)
                        setLoadedOutage(true)
                        setInitialOutage(outage)
                        setFutureOutage(futureOutage)
                        if (outage.isLinked) {
                            setLoadedRepeatingOutage(true)
                            setRepeating(true)
                            setRepeatingInterval(outage.repeatingInterval)
                            setRepeatUntil(moment(outage.repeatUntil))
                        }
                    }
                    else {
                        navigate(-1)
                    }
                })
                .finally(() => {
                    setInserting(false);
                })
        }
    }, [])


    useEffect(() => {
        // Updating capacity on asset/engine change
        const newCapacity = calculateCapacity(asset, generator)
        setCapacity(newCapacity)
    }, [asset, generator])


    useEffect(() => {
        // Resetting remaining availability when setting to "Outage" rather than restriction
        if (outageVariety === "Outage") {
            setRemainingAvailability("0")
        }
    }, [outageVariety])


    
    //// Backend methods

    const performOutageOp = async (writeToDB: boolean) => {

        let operationType = type
        if (operationType !== "Submit") {
            if (modifyOrCancel === "Modify") {
                operationType = "Update"
            }
            else {
                operationType = "Delete"
            }
        }
        const outageAmount = outageVariety === "Restriction" ? capacity - parseFloat(remainingAvailability) : capacity
        return availabilityApi.performOutageOp(operationType, remitID, asset, generator, moment(outageStart).utc().format(), moment(outageEnd).utc().format(), outageAmount, reason, moment.utc().format(), comments, outageType, outageVariety, workOrder, bucket, repeating, repeatingInterval, repeatUntil.utc().format(), modifyAll, writeToDB)
    }


    //// Click handlers

    const clickSubmit = async () => {
        let canPerformOutageOp = true
        if (modifyOrCancel === "Cancel") {
            canPerformOutageOp = await openDeleteOutagePopup()
        }
        if(canPerformOutageOp) {
            const response = await performOutageOp(false)
            let loadConfirmPopup = true
            if (response.overlappingOutages.length > 0) {
                loadConfirmPopup = await openOverlappingOutagesForm(response.overlappingOutages)
            }

            if (loadConfirmPopup) {
                let outageType = type
                if (modifyOrCancel === "Cancel") { outageType = "Delete" }
                const submitOutage = await openUpdateBox(outageType, response)
                if (submitOutage) {
                    const finalResponse = await performOutageOp(true)
                    if (outageType === "Delete") {
                        navigate(-1)
                    }
                    else {
                        const addedOutages = finalResponse.addedOutages
                        setNewEngineOutages(addedOutages)

                        if (outageType === "Update") {
                            const outage = addedOutages[0]
                            setRemitID(outage.remitID)
                            setVariablesHelper(outage.siteID, outage.engineID, moment(outage.outageStart), moment(outage.outageEnd), outage.outageType, outage.comments, outage.detail, outage.mwOutage, outage.restriction, outage.workOrder, outage.bucket)
                            setInitialOutage(outage)
                            openSuccessfulUpdatePopup()
                        }
                    }
                }
            }
        }
    }

    //// Popup openers and handlers

    // Preview of propsed outage op
    const openUpdateBox = async (popupType: OperateType, outageOpData: IOutageOperationResponse) => {
        const newOutage = newOutageCreator()
        const enabled = process.env.REACT_APP_ENGINE_OUTAGE_OPS_ALLOWED?.toLowerCase() === "true";
        const confirmationComponent = (
            <UpdateConfirm initialOutage={initialOutage} newOutage={newOutage} type={popupType} siteIdToName={siteIdToName} siteIdToCapacity={siteIdToCapacity} repeating={repeating} repeatingInterval={repeatingInterval} repeatUntil={repeatUntil} modifyAll={modifyAll} outageOpData={outageOpData} />
        );

        const options: IAdvancedModalOptions = {
            title: "Confirm " + popupType + " Outage",
            contentComponent: confirmationComponent,
            yesText: popupType,
            noText: "Cancel",
            fullWidth: true,
            maxWidth: 'md',
            canSubmit: enabled,
            error: false
        }

        return await openModal(options);
    };

    // Overlapping outages warning
    const openOverlappingOutagesForm = async (overlappingOutages: IEngineOutage[]) => {
        const confirmationComponent = (
            <OverlappingOutagesWarning overlappingOutages={overlappingOutages} siteIdToName={siteIdToName} />
        );

        const options: IAdvancedModalOptions = {
            title: "Overlapping Outages",
            contentComponent: confirmationComponent,
            yesText: "Confirm",
            noText: "Cancel",
            fullWidth: true,
            maxWidth: 'lg',
            canSubmit: true,
            error: false
        }

        return await openModal(options);
    };

    // Delete outage warning
    const openDeleteOutagePopup = async () => {
        const confirmationComponent = (
            <Typography>Cancelling this item(s) will delete it from the system. If needed later it will have to be re-entered from scratch. <br/>Are you sure you wish to continue?</Typography>
        );

        const options: IAdvancedModalOptions = {
            title: "WARNING",
            contentComponent: confirmationComponent,
            yesText: "Submit",
            noText: "Cancel",
            fullWidth: true,
            maxWidth: 'sm',
            canSubmit: true,
            error: false
        }

        return await openModal(options);
    };

    // Update outage confirmation
    const openSuccessfulUpdatePopup = async () => {
        const confirmationComponent = (
            <Typography>Outage has been updated!</Typography>
        );

        const options: IAdvancedModalOptions = {
            title: "Success",
            contentComponent: confirmationComponent,
            yesText: "Go Back",
            fullWidth: true,
            maxWidth: 'sm',
            error: false
        }

        await openModal(options);
        navigate(-1)
    };


    //// Helper methods

    const calculateCapacity = (site: string, gen: string): number => {
        var capacity = 0.0;
        var siteGenerators = siteIDToGenerators.get(site) || []
        var siteCapacity = siteIdToCapacity[site] || 0
        var siteType = siteIDToType[site] || "Gas"
        if (gen === "Whole Site") {
            var total = 0
            siteGenerators.forEach((engine) => {
                total += engine.grossOutput / 1000.0;
            })

            // Solar sites capacity often limited by sum of inverter capacity
            if (siteType === "Solar" && total < siteCapacity) {
                capacity = total
            }
            else {
                capacity = siteCapacity
            }
        }
        else {
            siteGenerators.forEach((engine) => {
                if (engine.engine === gen) {
                    capacity = engine.grossOutput / 1000.0;
                }
            })
        }
        return capacity
    }

    const newOutageCreator = (): IEngineOutage => {
        const newestOutage: IEngineOutage = {
            remitID: remitID,
            siteID: asset,
            engineID: generator,
            outageStart: new Date(outageStart.toString()),
            outageEnd: new Date(outageEnd.toString()),
            mwOutage: outageVariety === "Restriction" ? capacity - parseFloat(remainingAvailability) : capacity,
            comments: reason,
            live: 1,
            created: new Date(),
            modified: new Date(),
            modifiedParent: 0,
            removed: new Date(),
            detail: comments,
            outageType: outageType,
            restriction: outageVariety === "Restriction",
            workOrder: workOrder,
            bucket: bucket
        }
        return newestOutage
    }

    const setVariablesHelper = (asset: string, generator: string, outageStart: Moment, outageEnd: Moment, outageType: string, reason: string, comments: string, outage: number, restriction: boolean, workOrder: string, bucket: string) => {
        setAsset(asset)
        setGenerator(generator)
        setOutageStart(outageStart)
        setOutageEnd(outageEnd)
        setOutageType(outageType)
        setComments(comments || "")
        setReason(reason)
        const currentCapacity = calculateCapacity(asset, generator)
        setRemainingAvailability((currentCapacity - outage).toString())
        setOutageVariety(restriction ? "Restriction" : "Outage")
        setWorkOrder(workOrder || "")
        setBucket(bucket)
    }

    //// Constants

    const outageTypes = [
        "Planned",
        "Reactive"
    ]

    const outageVarieties = [
        "Outage",
        "Restriction"
    ]

    const bucketTypes = [
        "DNO",
        "Capex (Overhaul)",
        "Capex (Electrical)",
        "Short Term Breakdown",
        "Long Term Plan In Place",
        "Long Term Plan To Be Agreed",
        "Construction",
        "Servicing"
    ]

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Typography color={theme.palette.primary.main} flexGrow={1} textAlign="left" sx={{ width: "100%", marginBottom: 1 }}>Select Asset:</Typography>
                    <AssetEngineSelector asset={asset} setAsset={setAsset} generator={generator} setGenerator={setGenerator} siteIdToName={siteIdToName} siteIDToGenerators={siteIDToGenerators} siteIDToType={siteIDToType} disabled={type === "Update"} />
                </Paper>
            </Grid>
            <Grid xs={12} hidden={type !== "Update" || !futureOutage}>
                <UpdateOutageOption modifyOrCancel={modifyOrCancel} setModifyOrCancel={setModifyOrCancel } />
            </Grid>
            <Grid xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Typography flexGrow={1} textAlign="left" fontSize={12} sx={{ width: "100%" }} hidden={type !== "Update" || modifyOrCancel === "Cancel"}>WARNING: Hitting 'Submit Modification' will override existing outage settings.</Typography>
                    <Typography color={theme.palette.primary.main} flexGrow={1} textAlign="left" sx={{ width: "100%", marginBottom: 1 }}>Define Outage:</Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="start"
                            spacing={2}
                            columns={12}
                            justifyContent="flex-start"
                        >
                            <Grid xs={isMobile ? 6 : 3}>
                                <OutageFormDate label="Start Date" date={outageStart} setDate={setOutageStart} disabled={inserting || !loadedOutage || (type === "Update" && !futureOutage) || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 3}>
                                <OutageFormTime label="Start Time (Local Time)" time={outageStart} setTime={setOutageStart} disabled={inserting || !loadedOutage || (type === "Update" && !futureOutage) || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 3}>
                                <OutageFormDate label="End Date" date={outageEnd} setDate={setOutageEnd} disabled={inserting || !loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 3}>
                                <OutageFormTime label="End Time (Local Time)" time={outageEnd} setTime={setOutageEnd} disabled={inserting || !loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                                <ReasonSelector reason={reason} setReason={setReason} disabled={!loadedOutage || modifyOrCancel === "Cancel"} selectedAssetType={selectedAssetType} />
                            <Grid xs={isMobile ? 6 : 4}>
                                <OutageFormSelect label="Outage Type" selected={outageType} setSelected={setOutageType} options={outageTypes} disabled={!loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 4}>
                                <OutageFormSelect label="Outage/Restriction" selected={outageVariety} setSelected={setOutageVariety} options={outageVarieties} disabled={!loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 4}>
                                <OutageFormNumber label="Remaining Availability" value={remainingAvailability} setValue={setRemainingAvailability} units={"MW"} disabled={!loadedOutage || outageVariety === "Outage" || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 4}>
                                <OutageFormNumber label="EAM Work Order Number" value={workOrder} setValue={setWorkOrder} disabled={!loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 4}>
                                <OutageFormSelect label="Bucket" selected={bucket} setSelected={setBucket} options={bucketTypes} disabled={!loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 6 : 8}>
                                <OutageFormString label="Comments (Optional)" value={comments} setValue={setComments} disabled={!loadedOutage || modifyOrCancel === "Cancel"} />
                            </Grid>
                            <Grid xs={isMobile ? 12 : 10}>
                                <RepeatingOutageSelector repeating={repeating} setRepeating={setRepeating} interval={repeatingInterval} setInterval={setRepeatingInterval} untilDate={repeatUntil} setUntilDate={setRepeatUntil} modifyAll={modifyAll} setModifyAll={setModifyAll} modifyOrCancel={modifyOrCancel} loadedRepeatingOutage={loadedRepeatingOutage} type={type} disabled={!loadedOutage} />
                            </Grid>
                            <Grid xs={isMobile ? 12 : 2}>
                                <Tooltip title={error}>
                                    <Box height="100%">
                                        <OutageFormSubmitButton content={type === "Submit" ? "Submit Outage" : modifyOrCancel === "Modify" ? "Submit Modification" : "Cancel Outage"} click={clickSubmit} inserting={false} disabled={inserting || !!error || !loadedOutage} />
                                    </Box>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Paper>
            </Grid>
            {type === "Submit" && 
                <Grid xs={12}>
                    <Paper>
                        <Typography color={theme.palette.primary.main} flexGrow={1} textAlign="left" sx={{ width: "100%", p: 2 }}>Your New Outages:</Typography>
                        <EngineOutageTable items={newEngineOutages} siteIdToName={siteIdToName}></EngineOutageTable>
                    </Paper>
                </Grid>
            }
        </Grid>
    );
}

export interface IOutageFormsProps {
    type: OperateType
    siteIdToName: Dictionary<string>
    siteIdToCapacity: Dictionary<number>
    siteIDToGenerators: Map<string, Array<IEngine>>
    siteIDToType: Dictionary<string>
}

export default OutageForms;