import { Hexagon } from 'react-hexgrid';
import { CubicCoords } from '../../shared/types/shared/hex-grid';
import { PropsWithChildren } from 'react';

export const HexButton: React.FC<PropsWithChildren<IHexButton>> = ({ location, cellStyle, stroke, strokeWidth, style, onClick, children }) => {
    return (
        <Hexagon q={location.q} r={location.r} s={location.s} cellStyle={cellStyle} stroke={stroke} strokeWidth={strokeWidth} style={style} onClick={onClick}>
            {children}
        </Hexagon>
    );
}

export interface IHexButton {
    location: CubicCoords,
    cellStyle?: React.CSSProperties,
    stroke?: string,
    strokeWidth?: string,
    style?: React.CSSProperties,
    onClick: () => void,
}