import { common } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { conradEnergyCyanColour, conradEnergyDeepBlueColour, conradEnergyFreshGreenColour, conradEnergyGreyColour, conradEnergyLightGreyColour, conradEnergyMagentaColour, conradEnergyYellowColour } from "./themeBrand";

const paperColour = "#161819";

export const ConradThemeDark = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: common.black,
            paper: paperColour,
        },
        primary: {
            main: "#3942FF",
            dark: "#272eb2",
            light: "#6067ff",
        },
        secondary: {
            main: "#00E5FC",
            light: "#33eafc",
            dark: "#00a0b0",
        },
        info: {
            main: "#3942FF",
            dark: "#272eb2",
            light: "#6067ff",
        },
        success: {
            main: conradEnergyFreshGreenColour.main
        },
        warning: {
            main: conradEnergyMagentaColour.main
        },
        conradEnergyGrey: conradEnergyGreyColour,
        conradEnergyLightGrey: conradEnergyLightGreyColour,
        conradEnergyFreshGreen: conradEnergyFreshGreenColour,
        conradEnergyCyan: conradEnergyCyanColour,
        conradEnergyDeepBlue: conradEnergyDeepBlueColour,
        conradEnergyMagenta: conradEnergyMagentaColour,
        conradEnergyYellow: conradEnergyYellowColour,
    },
    typography: {
        fontFamily: "Manrope, san-serif",
        allVariants: {
            color: common.white
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: (_) => ({
                    fontFamily: "Manrope, sans-serif",
                    color: _.theme.palette.common.white,
                }),
            }
        },
        MuiButton: {
            styleOverrides: {
                root: (_) => ({
                    backgroundColor: _.theme.palette.conradEnergyDeepBlue.main,
                    color: _.theme.palette.conradEnergyDeepBlue.contrastText,
                    ":hover": {
                        backgroundColor: _.theme.palette.conradEnergyDeepBlue.dark,
                    }
                }),
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                // svg: ({ theme }) => ({
                //     color: theme.palette.text.primary
                // })
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderBottom: "solid 1px rgba(159, 166, 173, 0.16)"
                },
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: (_) => ({
                    border: "none"
                }),
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: paperColour,
                    borderColor: paperColour,
                    borderRadius: 0,
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: (_) => ({                    
                    ".MuiTableCell-head": {
                        backgroundColor: conradEnergyDeepBlueColour.main,
                        color: common.white,
                    },
                    //".MuiTableBody-root": {
                    //    ".MuiTableRow-root": {
                    //        ":hover": {
                    //            backgroundColor: conradEnergyLightGreyColour[50],
                    //            "*": {
                    //                color: common.black,
                    //            }
                    //        }
                    //    },
                    //}
                }),
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: (_) => ({
                    color: common.white,
                    "& .MuiToolbar-root > *": {
                        color: common.white,
                    }
                }),              
                columnSeparator: {
                    color: common.white
                },
                columnHeaders: (_) => ({
                    //backgroundImage: `linear-gradient(${conradEnergyGreyColour[200]} 45%, ${conradEnergyGreyColour[100]});`,
                    backgroundColor: conradEnergyDeepBlueColour.main,
                    color: common.white,                    
                }),
                row: {
                    //":nth-of-type(odd)": {
                    //    backgroundColor: conradEnergyLightGreyColour["100"],
                    //},
                    //":nth-of-type(even)": {
                    //    backgroundColor: conradEnergyLightGreyColour["100"],
                    //},
                    ":hover": {
                        backgroundColor: conradEnergyLightGreyColour[50],
                        "*": {
                            color: common.black,
                        }
                    },
                    ".Mui-selected": {
                        backgroundColor: conradEnergyCyanColour[50],
                        color: "white",
                        "&:hover": {
                            backgroundColor: conradEnergyCyanColour[50]
                        },
                        ".MuiSvgIcon-root": {
                            color: "black",
                        },
                    },                    
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: (_) => ({                                        
                    "> *": {
                        color: common.white
                    },
                    "& .MuiTableHead-root": {
                        backgroundColor: conradEnergyGreyColour.main,
                    },                    
                }),
            }
        },
        MuiGrid2: {
            styleOverrides: {
                root: (_) => ({
                    // margin: 0,                    
                    "*": {
                        color: common.white
                    },
                    ".MuiTableHead-root": {                        
                        color: _.theme.palette.conradEnergyGrey.contrastText,
                    },
                }),
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: (_) => ({
                    backgroundColor: _.theme.palette.conradEnergyDeepBlue.main,
                    ".MuiTab-root": {
                        color: "white",
                        ":hover": {
                            backgroundColor: _.theme.palette.conradEnergyDeepBlue.light,
                        },
                        "&.Mui-selected": {
                            color: "white",
                            backgroundColor: _.theme.palette.conradEnergyDeepBlue.dark,
                            "svg": {
                                color: "white"
                            }
                        },
                    },                    
                }),                   
            }
        },   
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    //padding: 0,
                    //margin: 0
                }
            }
        }
    },
});