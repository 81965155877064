import { Stack, Typography } from "@mui/material";
import React from "react";
import { conradEnergyDeepBlueColour, conradEnergyFreshGreenColour, conradEnergyMagentaColour, conradEnergyRedColour } from "../../../shared/theme/themeBrand";

const BessFileStatusLegend: React.FC<IBessFileStatusLegendProps> = () => {
    return (
        <Stack direction="row" useFlexGap flexWrap="wrap" justifyContent="space-around">
            <Typography variant="h6" sx={{ color: conradEnergyFreshGreenColour.main }}>Files Healthy</Typography>
            <Typography variant="h6" sx={{ color: conradEnergyRedColour.main }}>File doesn't exist</Typography>
            <Typography variant="h6" sx={{ color: conradEnergyMagentaColour.main }}>File values not in range</Typography>
            <Typography variant="h6" sx={{ color: conradEnergyDeepBlueColour.main }}>File values are static</Typography>
        </Stack>
    );
}

export interface IBessFileStatusLegendProps {

}

export default BessFileStatusLegend;
