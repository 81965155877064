export class HexGridData {
    constructor(gridWidth: number, gridHeight: number) {
        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;
        this.gridTiles = this.generateGrid(gridWidth, gridHeight);
    }

    public get tiles() {
        return Array.from(this.gridTiles.values());
    }

    private readonly gridWidth: number = 0;
    private readonly gridHeight: number = 0;
    private readonly gridTiles: Map<string, HexGridTile>;

    public readonly getTileAtCoords = (coords: CubicCoords): HexGridTile | undefined => {
        return this.gridTiles.get(coords.value);
    }

    public readonly getTilesAtCoords = (coordsList: CubicCoords[]): HexGridTile[] => {
        const returnVal: HexGridTile[] = [];

        coordsList.forEach((coords) => {
            const tile = this.getTileAtCoords(coords);
            if (tile) { returnVal.push(tile); }
        })

        return returnVal;
    }

    public readonly getGridDimenions = () => {
        return { width: this.gridWidth, height: this.gridHeight }
    }

    public readonly getUnoccupiedTiles = (): HexGridTile[] => {
        const allTiles = Array.from(this.gridTiles.values());
        return allTiles.filter((tile) => tile.canPlace());
    }

    public readonly getAnchorPoints = (): IAnchorPoints => {
        const lastRow = this.gridHeight - 1; // First row is 0, so last column is height of grid - 1

        // First column is 0, so last column is width of grid - 
        const lastColumnEvenRows = this.gridWidth - 1; // This is the last column index on even rows
        const lastColumnOddRows = lastColumnEvenRows - 1; // This is the last column index on odd rows


        const halfGridHeight = Math.floor(lastRow / 2);
        const widthMinusHalfHeightEvenRows = lastColumnEvenRows - halfGridHeight;
        const widthMinusHalfHeightOddRows = lastColumnOddRows - halfGridHeight;

        const lastRowIsOdd = lastRow % 2 !== 0;
        const centreRowIsOdd = halfGridHeight % 2 !== 0;

        const bottomRightValue = lastRowIsOdd ? widthMinusHalfHeightOddRows : widthMinusHalfHeightEvenRows;
        const centreValue = centreRowIsOdd ? widthMinusHalfHeightOddRows : widthMinusHalfHeightEvenRows;

        const topLeft = new CubicCoords(0, 0, 0);
        const topRight = new CubicCoords(lastColumnEvenRows, 0, -lastColumnEvenRows);
        const bottomLeft = new CubicCoords(-halfGridHeight, lastRow, -halfGridHeight)
        const bottomRight = new CubicCoords(bottomRightValue, lastRow, -bottomRightValue - lastRow);
        const centre = new CubicCoords(Math.ceil(centreValue / 2), halfGridHeight, -Math.ceil(centreValue / 2) - halfGridHeight);

        return { topLeft, topRight, bottomLeft, bottomRight, centre };
    }

    private readonly generateGrid = (gridWidth: number, gridHeight: number): Map<string, HexGridTile> => {
        const firstValidColumn = 0; const firstValidRow = 0;
        const lastValidColumn = gridWidth - 1; const lastValidRow = gridHeight - 1;

        const gridTopBound = firstValidRow - 1; const gridBottomBound = gridHeight;
        const gridLeftBound = firstValidColumn - 1; const gridRightBound = gridWidth;

        const map: Map<string, HexGridTile> = new Map();

        for (let r = gridTopBound; r <= gridBottomBound; r++) {
            const offset = Math.floor(r / 2);
            for (let q = -offset + gridLeftBound; q <= gridRightBound - offset; q++) {
                const s = -q - r;
                const coords = new CubicCoords(q, r, s);

                const isAtLeftBound = q - s < firstValidColumn;
                const isAtRightBound = q + Math.ceil(r / 2) > lastValidColumn;
                const isAtTopBound = r < firstValidRow;
                const isAtBottomBound = r > lastValidRow;
                const offscreen = isAtLeftBound || isAtTopBound || isAtRightBound || isAtBottomBound;

                map.set(coords.value, new HexGridTile(coords, false, offscreen));
            }
        }

        return map;
    }
}

export class HexGridTile {
    constructor(coords: CubicCoords, isOccupied: boolean = false, isOffscreen: boolean = false) {
        this.coords = coords;
        this.isOccupied = isOccupied;
        this.isOffscreen = isOffscreen;
    }

    public readonly coords: CubicCoords;
    public readonly isOffscreen: boolean;
    public isOccupied: boolean;

    public readonly getNeighbours = () => {
        const { q, r, s } = this.coords;

        const neighbourCoords = [
            new CubicCoords(q + 1, r, s - 1),
            new CubicCoords(q + 1, r - 1, s),
            new CubicCoords(q, r - 1, s + 1),
            new CubicCoords(q - 1, r, s + 1),
            new CubicCoords(q - 1, r + 1, s),
            new CubicCoords(q, r + 1, s - 1),
        ]

        return neighbourCoords;
    }

    public readonly canPlace = () => {
        return !this.isOffscreen && !this.isOccupied;
    }
}

export class CubicCoords {
    constructor(q: number, r: number, s: number) {
        this.q = q;
        this.r = r;
        this.s = s;
        this.value = this.toString();
    }

    public readonly q: number;
    public readonly r: number;
    public readonly s: number;
    public readonly value: string;

    public equals(obj: CubicCoords): boolean {
        return obj.q === this.q && obj.r === this.r && obj.s === this.s;
    }

    private toString = (): string => {
        return `(q: ${this.q}, r: ${this.r}, s: ${this.s})`;
    }
}

export interface IAnchorPoints {
    topLeft: CubicCoords;
    topRight: CubicCoords;
    bottomLeft: CubicCoords;
    bottomRight: CubicCoords;
    centre: CubicCoords;
}

export enum HiveGenerationMode {
    Hive,
    Clusters,
    Dispatch
}
