import { Moment } from "moment";
import { getApiAccessToken } from "../auth/helpers"
import ApiRoot from "./ApiRoot"

const rootUrl = process.env.REACT_APP_PORTAL_API_URL

const getPerfmonFile = async (timestamp: Moment | undefined, siteId: string | undefined): Promise<any> => {
    if (!timestamp || !siteId) {
        return;
    }
    else {
        const accessToken = await getApiAccessToken();

        const requestBody = {
            "Timestamp": timestamp,
            "SiteId": siteId
        }

        return ApiRoot.post(
            `${rootUrl}dataaccess/GetPerfmonFile`,
            requestBody,
            { accessToken }
        )
    }
}

export default getPerfmonFile;