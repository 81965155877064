import React from "react";
import { Box } from "@mui/material";
import ImbalanceREMITTable from "./ImbalanceREMITTable";
import { IImbalanceREMIT } from "../../../../shared/types/analytics/trading-analytics/IImbalanceREMIT";

const FailureToDeliverPopup: React.FC<IFailureToDeliverPopup> = ({ failuresAtTime }) => {
    return (
        <Box>
            <ImbalanceREMITTable items={failuresAtTime} />
        </Box>
    );
}
export interface IFailureToDeliverPopup {
    failuresAtTime: IImbalanceREMIT[]
}

export default FailureToDeliverPopup;