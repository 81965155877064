import { IDispatchResponse, SiteManualStatus } from "../types/asset/status-hive";

const manualStartUrl = "https://pi-application.conradcloud.net/manualstart/GasSiteManualStart"

const sendManualDispatchRequest = async (siteName: string, webID: string, newStatus: SiteManualStatus) => {

    const path = "\\\\PI-AF\\iON\\Conrad\\" + siteName + "|Site Manual Control";
    let payloadValue = 0;
    if (newStatus === SiteManualStatus.On) { payloadValue = 2 }
    else if (newStatus === SiteManualStatus.Off) { payloadValue = 4 }
    const queryString = "?WebID=" + webID + "&ManualAction=" + payloadValue + "&Site=" + path;
    const fullURL = manualStartUrl + queryString;
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("POST", fullURL, false); // false for synchronous request
    xmlHttp.setRequestHeader('Content-Type', 'application/json');
    xmlHttp.send("");
    const responseStatus = xmlHttp.status === 200;
    return {
        siteName: siteName,
        status: responseStatus
    } as IDispatchResponse
}


const dispatchApi = {
    sendManualDispatchRequest
}

export default dispatchApi;