import React, { ReactElement, useEffect, useMemo, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";
import Icon from "@mdi/react";
import { mdiClipboardListOutline } from "@mdi/js";
import { Box, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import BessFileMonthPicker from "../../../components/operate/bess-file-checking/BessFileMonthPicker";
import { Moment } from "moment";
import moment from "moment";
import IBessFileData from "../../../shared/types/operate/bess-file-checking/IBessFileData";
import { useColourModeContext } from "../../../components/ColourMode";
import BessFileCell from "../../../components/operate/bess-file-checking/BessFileCell";
import BessFileStatusLegend from "../../../components/operate/bess-file-checking/BessFileStatusLegend";
import { Ordinal } from "../../../components/analytics/helpers/DateFormatter";

const generateDummyFileName = (siteName: string, file: IBessFileData) => {
    const timestamp = moment(file.timestamp).toISOString();
    return `${siteName.toLocaleUpperCase()}-1_${timestamp}_20Hz_perfmonv1.csv`;
}

const MonthlyFileCheckerPage: React.FC<IMonthlyFileCheckerPageProps> = () => {
    const [dateToDisplay, setDateToDisplay] = useState<Moment>(moment.utc().startOf("month"));
    const [data, setData] = useState<IBessMonthlyFileData[]>([]);
    const { theme } = useColourModeContext();

    useEffect(() => {
        setData(dummyData);
    }, []);

    const generatedColumnHeaders = useMemo(() => {
        const columnHeaders: string[] = [];
        const days = dateToDisplay.daysInMonth();

        for (var i = 1; i <= days; i++) {
            if (i === 1) {
                columnHeaders.push("1st")
                continue;
            }

            if (i === Math.floor(days/2)) {
                columnHeaders.push("16th");
                continue;
            }

            if (i === days) {
                columnHeaders.push(Ordinal(days));
                continue;
            }

            columnHeaders.push("");
        }

        return columnHeaders;
    }, [dateToDisplay]);

    const getFileCells = (siteName: string, files: IBessFileData[]) => {
        const cells: ReactElement[] = [];
        const days = dateToDisplay.daysInMonth();

        for (var i = 0; i < days; i++) {
            const file = files[i];

            if (!!file && !file.filename) {
                file.filename = generateDummyFileName(siteName, file);
            }

            cells.push(<BessFileCell siteName={siteName} file={file} />)
        }

        return (
            <>
                {cells}
            </>
        );
    }

    return (
        <PageWrapper title="Monthly File Checker" loaded={true}>
            <Stack spacing={2}>
                <Box width="100%" display="flex" justifyContent="center">
                    <BessFileMonthPicker date={dateToDisplay} onDateUpdated={setDateToDisplay} />
                </Box>
                <TableContainer sx={{ maxHeight: "calc(100vh - 285px)" }}>
                    <Table component={Paper} sx={{ width: "100%" }} stickyHeader>
                        <TableHead>
                            <TableRow sx={{ [`>.${tableCellClasses.head}`]: { backgroundColor: theme.palette.background.paper } }}>
                                <TableCell align="center"></TableCell>
                                {generatedColumnHeaders.map((header) => (
                                    <TableCell padding="none" width={55} align="center">{header}</TableCell>
                                ))}
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    <TableRow key={row.siteName}>
                                        <TableCell align="center">
                                            {row.siteName}
                                        </TableCell>
                                        {getFileCells(row.siteName, row.files)}
                                        <TableCell>
                                            {row.files.filter((f) => f.status !== "Healthy").length}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <BessFileStatusLegend />
            </Stack>
        </PageWrapper>
    );
}

export interface IMonthlyFileCheckerPageProps {

}

const MonthlyFileCheckerPageConfig: IPortalRouteOptions = {
    relativeUrl: "monthly-file-checker",
    page: <MonthlyFileCheckerPage />,
    navDisplay: {
        title: "Monthly Files",
        icon: <Icon path={mdiClipboardListOutline} size={1} />,
    },
}

export default MonthlyFileCheckerPageConfig;

interface IBessMonthlyFileData {
    siteName: string;
    files: IBessFileData[];
}

const dummyData: IBessMonthlyFileData[] = [
    {
        "siteName": "Blackpool",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            }
        ]
    },
    {
        "siteName": "Flitwick",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            }
        ]
    },
    {
        "siteName": "Midsomer",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            }
        ]
    },
    {
        "siteName": "Swindon",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            }
        ]
    },
    {
        "siteName": "Torquay",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            }
        ]
    },
    {
        "siteName": "Winchester",
        "files": [
            {
                "timestamp": "Fri Nov 01 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Sun Nov 03 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 05 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 06 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 07 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 08 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 09 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Mon Nov 11 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Tue Nov 12 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Wed Nov 13 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 14 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Fri Nov 15 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sat Nov 16 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sun Nov 17 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Mon Nov 18 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Tue Nov 19 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Wed Nov 20 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Thu Nov 21 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 22 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Sat Nov 23 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Sun Nov 24 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NotInRange"
            },
            {
                "timestamp": "Mon Nov 25 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Tue Nov 26 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            },
            {
                "timestamp": "Wed Nov 27 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Thu Nov 28 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "NoFile"
            },
            {
                "timestamp": "Fri Nov 29 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Healthy"
            },
            {
                "timestamp": "Sat Nov 30 2024 00:00:00 GMT+0000 (Greenwich Mean Time)",
                "filename": "",
                "status": "Static"
            }
        ]
    }
];
