import { Typography } from "@mui/material";
import React from "react";import Grid from '@mui/material/Unstable_Grid2';
import { IBidOfferData } from "../../../../shared/types/analytics/trading-analytics/IBidOfferData";
import BalancingSummaryBOATable from "./BalancingSummaryBOATable";

const BalancingSummaryBOATableWrapper: React.FC<IBalancingSummaryBOATableWrapper> = ({ bidData, offerData }) => {
    
    return (
        <Grid container spacing={2} padding={2}>
            <Grid xs={6}>
                <Typography variant="h6" textAlign="center">Offer Table</Typography>
            </Grid>
            <Grid xs={6}>
                <Typography variant="h6" textAlign="center">Bid Table</Typography>
            </Grid>
            <Grid xs={6}>
                <BalancingSummaryBOATable items={offerData} defaultSortKey="price" defaultOrderDescending={false} />
            </Grid>
            <Grid xs={6}>
                <BalancingSummaryBOATable items={bidData} defaultSortKey="price" defaultOrderDescending={true} />
            </Grid>
        </Grid>
    );
}
export interface IBalancingSummaryBOATableWrapper {
    bidData: IBidOfferData[]
    offerData: IBidOfferData[]
}

export default BalancingSummaryBOATableWrapper;