import { Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { IMarketUpdateDictionary } from "../../../../shared/types/analytics/trading-analytics/IMarketUpdateMessage";
import { GetMarketUpdateMessages } from "../../helpers/TradingAnalyticsData";
import MarketUpdateHeader from "./MarketUpdateHeader";
import MarketUpdateTableWrapper from "./MarketUpdateTableWrapper";
import { IMarketUpdateSetting } from "../../../../shared/types/analytics/trading-analytics/IMarketUpdateSetting";

const MarketUpdateWrapper: React.FC<IMarketUpdateWrapper> = ({ setting }) => {
    const [messageDict, setMessageDict] = useState<IMarketUpdateDictionary>()
    const [filteredMessageDict, setFilteredMessageDict] = useState<IMarketUpdateDictionary>()

    const loadData = useCallback(() => {
        setMessageDict(GetMarketUpdateMessages())
    }, [])

    useEffect(() => {
        loadData()
        const intervalId = setInterval(() => {
            loadData();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadData])
    
    return (
        <Paper sx={{ height: "81vh", width: "100%", py: 1, overflowY: "scroll" }}>
            <Grid container rowSpacing={2}>
                <Grid xs={12}>
                    {messageDict && <MarketUpdateHeader unfilteredData={messageDict} setFilteredData={setFilteredMessageDict} />}
                </Grid>
                <Grid xs={12}>
                    {filteredMessageDict && <MarketUpdateTableWrapper data={filteredMessageDict} setting={setting} />}
                </Grid>
            </Grid>
        </Paper>
    );
}
export interface IMarketUpdateWrapper {
    setting: IMarketUpdateSetting
}

export default MarketUpdateWrapper;