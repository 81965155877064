import { Paper } from "@mui/material";
import PageWrapper from "../../../components/PageWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models } from 'powerbi-client';
import { getPowerBiAccessToken } from "../../../shared/auth/helpers";
import { Analytics } from "@mui/icons-material";
import { IAnalyticsBIReport } from "../../../shared/api/PowerBiApi";
import { IPortalRouteOptions } from "../../../shared/types/shared/routes/routeTypes";

export const AnalyticsPowerBiPage: React.FC<IAnalyticsPowerBiPageProps> = ({ title, groupId, reportId }) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [report, setReport] = useState<Embed>();
    const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({});

    const handleLoad = useCallback(() => {
        const reportUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`;

        getPowerBiAccessToken().then(tkn => {
            const config = {
                type: "report",
                embedUrl: reportUrl,
                tokenType: models.TokenType.Aad,
                accessToken: tkn,
                settings: {
                    layoutType: models.LayoutType.Custom,
                    customLayout: {
                        displayOption: models.DisplayOption.FitToPage,
                    },
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false,
                        },
                        pageNavigation: {
                            visible: true,
                        },
                    },
                    background: models.BackgroundType.Default,
                }
            }

            setReportConfig(config)
        }).finally(() => {
            setLoaded(true);
        });
    }, [groupId, reportId]);

    useEffect(() => {
        handleLoad();
    }, [handleLoad]);

    return (
        <PageWrapper title={title} loaded={loaded} showTitle={true} contentOverflow="hidden">
            <Paper sx={{ height: "calc(100% - 1px)", width: "100%", p: 0, background: "transparent" }} elevation={0}>
                <PowerBIEmbed
                    embedConfig={reportConfig}
                    cssClassName={"power-bi-frame"}
                    getEmbeddedComponent={(embedObject) => {
                        setReport(embedObject);
                    }}
                    eventHandlers={new Map([
                        ["loaded", () => { console.log("Report loaded") }],
                        ["error", () => { console.log("Report error") }],
                    ])}
                />
            </Paper>
        </PageWrapper>
    );
}

export interface IAnalyticsPowerBiPageProps {
    title: string;
    groupId: string;
    reportId: string;
}

const AnalyticsPowerBiReportsPageConfig = (report: IAnalyticsBIReport): IPortalRouteOptions => {
    const pageTitle = report.pageTitle || report.navTitle;
    return {
        relativeUrl: report.navTitle.replaceAll(" ", "-").toLowerCase(),
        page: <AnalyticsPowerBiPage title={pageTitle} groupId={report.groupId} reportId={report.reportId} />,
        navDisplay: { title: report.navTitle, icon: <Analytics /> }
    }
}

export default AnalyticsPowerBiReportsPageConfig;