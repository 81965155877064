import { Button, Stack, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const BessFileMonthPicker: React.FC<IBessFileDayPickerProps> = ({ date, onDateUpdated }) => {
    const [selectedDate, setSelectedDate] = useState<Moment>(date);

    const thisMonth = useMemo(() => {
        const date = moment.utc().startOf("month");
        return date;
    }, []);

    const earliestAllowed = useMemo(() => {
        const date = moment(thisMonth).subtract(6, "month");
        return date;
    }, [thisMonth])


    const dateText = useMemo(() => {
        return selectedDate.format("MMMM YY")
    }, [selectedDate]);

    const canGoForward = useMemo(() => {
        return !selectedDate.isSame(thisMonth, "month");
    }, [selectedDate, thisMonth]);

    const canGoBackward = useMemo(() => {
        return !selectedDate.isSame(earliestAllowed, "month");
    }, [selectedDate, earliestAllowed]);

    const updateSelectedDate = (numberOfDays: number) => {
        const newDate = moment(selectedDate).add(numberOfDays, "month");
        setSelectedDate(newDate);

        onDateUpdated(newDate);
    }

    return (
        <Stack direction="row" spacing={1}>
            <Button onClick={() => updateSelectedDate(-1)} disabled={!canGoBackward}>
                <ArrowBack />
            </Button>
            <Typography minWidth="140px" textAlign="center" lineHeight="40px" variant="h6">{dateText}</Typography>
            <Button onClick={() => updateSelectedDate(1)} disabled={!canGoForward}>
                <ArrowForward />
            </Button>
        </Stack>
    );
}

export interface IBessFileDayPickerProps {
    date: Moment;
    onDateUpdated: (newDate: Moment) => void;
}

export default BessFileMonthPicker;
