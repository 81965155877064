import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IMarketUpdateMessage } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateMessage';
import moment from 'moment';
import { MarketUpdateTableCell, MELLimitSetter, MessagesDisplayedSetter } from './MarketUpdateTableCell';
import { IMarketUpdateSetting } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateSetting';

const MarketUpdateRow: React.FC<IMarketUpdateRow> = ({ label, data, setting, dictKey, handleSettingChange }) => {
    const [melFilteredData, setMelFilteredData] = useState<IMarketUpdateMessage[]>([])
    const [filteredData, setFilteredData] = useState<IMarketUpdateMessage[]>([])
    const [times, setTimes] = useState<JSX.Element[]>([])
    const [units, setUnits] = useState<JSX.Element[]>([])
    const [texts, setTexts] = useState<JSX.Element[]>([])
    const [links, setLinks] = useState<JSX.Element[]>([])

    // Applying the mel limit
    useEffect(() => {
        const dataCopy: IMarketUpdateMessage[] = []
        data.forEach(message => {
            if (label !== "MEL Drops" || (message.value && message.value <= setting.melLimit)) {
                dataCopy.push(message)
            }
        })
        setMelFilteredData(dataCopy)
    }, [data, label, setting])

    // Applying the limited number of messages
    useEffect(() => {
        if (dictKey !== "user") {
            setFilteredData([...melFilteredData].splice(0, setting[dictKey]))
        }
    }, [melFilteredData, setting, dictKey])

    // Building the table data
    useEffect(() => {
        const newTimes: JSX.Element[] = []
        const newUnits: JSX.Element[] = []
        const newTexts: JSX.Element[] = []
        const newLinks: JSX.Element[] = []
        filteredData.forEach(message => {
            newTimes.push(<>{moment(message.dateTime).format("DD-MMM HH:mm")}</>)
            newUnits.push(<>{message.unit}</>)
            newTexts.push(<>{message.alertText}</>)
            newLinks.push(<a rel="noopener noreferrer" href={message.link} target="_blank">Link</a>)
        })

        if (label === "System Warning") {
            newTimes.push(<>&#8203;</>)
            newUnits.push(<a rel="noopener noreferrer" href={"https://greenfrogpower.egnyte.com/dl/Cand3L26Zu"} target="_blank">EMC CMN Proc Doc</a>)
            newTexts.push(<>&#8203;</>)
            newLinks.push(<>&#8203;</>)
        }
        setTimes(newTimes)
        setUnits(newUnits)
        setTexts(newTexts)
        setLinks(newLinks)
    }, [filteredData, label])

    return (
        <Fragment>
            <Grid xs={3}>
                <MarketUpdateTableCell contents={[
                    <>{label}</>,
                    ...(label === "MEL Drops") ? [<MELLimitSetter limit={setting.melLimit} handleSettingChange={handleSettingChange} />] : [],
                    <MessagesDisplayedSetter numberDisplayed={setting[dictKey] as number} dictKey={dictKey} handleSettingChange={handleSettingChange} numberCanDisplay={melFilteredData.length} />
                ]} />
            </Grid>
            <Grid xs={2}>
                <MarketUpdateTableCell contents={times} />
            </Grid>
            <Grid xs={2}>
                <MarketUpdateTableCell contents={units} />
            </Grid>
            <Grid xs={9}>
                <MarketUpdateTableCell contents={texts} />
            </Grid>
            <Grid xs={2}>
                <MarketUpdateTableCell contents={links} />
            </Grid>
        </Fragment>
    );
}

export interface IMarketUpdateRow {
    label: string
    data: IMarketUpdateMessage[]
    setting: IMarketUpdateSetting
    dictKey: keyof IMarketUpdateSetting
    handleSettingChange: (key: keyof IMarketUpdateSetting, value: number) => void
}

export default MarketUpdateRow;