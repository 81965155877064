import { Box, Paper, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import FailureToDeliverChart from "./FailureToDeliverChart";
import { IFailureToDeliverDataFormatted } from "../../../../shared/types/analytics/trading-analytics/IFailureToDeliverData";
import { GetFailureToDeliverData, GetLiveImbalanceData } from "../../helpers/TradingAnalyticsData";
import CurrentImbalanceDisplay from "./CurrentImbalanceDisplay";
import moment, { Moment } from "moment";
import ImbalanceTimeSelector from "./ImbalanceTimeSelector";
import { IImbalanceREMIT } from "../../../../shared/types/analytics/trading-analytics/IImbalanceREMIT";
import ImbalanceREMITTable from "./ImbalanceREMITTable";

const FailureToDeliverWrapper: React.FC<IFailureToDeliverWrapper> = ({ }) => {
    const [selectedTime, setSelectedTime] = useState<Moment>(moment.utc());
    const [nowSelected, setNowSelected] = useState<boolean>(true)
    const [currentTime, setCurrentTime] = useState<Moment>(moment.utc())
    const [chartData, setChartData] = useState<IFailureToDeliverDataFormatted[]>([])
    const [currentData, setCurrentData] = useState<IFailureToDeliverDataFormatted>()
    const [liveOutages, setLiveOutages] = useState<IImbalanceREMIT[]>([])
    const [chartTime, setChartTime] = useState<Moment>(moment.utc())

    useEffect(() => {
        if (nowSelected) {
            setChartTime(currentTime)
        }
        else {
            setChartTime(selectedTime)
        }
    }, [nowSelected, selectedTime, currentTime])

    useEffect(() => {
        if (chartData.length > 0) {
            let newCurrentData = chartData[0]
            chartData.forEach(dataPoint => {
                if (dataPoint.dateTime >= newCurrentData.dateTime) {
                    newCurrentData = dataPoint
                }
            })
            setCurrentData(newCurrentData)
        }
    }, [chartData])

    useEffect(() => {
        setChartData(GetFailureToDeliverData())
        setLiveOutages(GetLiveImbalanceData(chartTime.valueOf()))
    }, [chartTime])

    const updateCurrentTime = useCallback(() => {
        setCurrentTime(moment.utc())
    }, [])

    useEffect(() => {
        updateCurrentTime()
        const intervalId = setInterval(() => {
            updateCurrentTime();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [setCurrentTime])

    return (
        <Paper sx={{}}>
            <Grid container spacing={2} padding={1}>
                <Grid xs={12}>
                    <Stack width="100%" direction="row" justifyContent="center" alignItems="stretch" spacing={1}>
                        {currentData && <CurrentImbalanceDisplay data={currentData} />}
                        <ImbalanceTimeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime} nowSelected={nowSelected} setNowSelected={setNowSelected} />
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{ height: "400px", width: "100%" }}>
                        <FailureToDeliverChart chartData={chartData} currentTime={chartTime} />
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{ height: "250px", width: "100%" }}>
                        <ImbalanceREMITTable items={liveOutages} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}
export interface IFailureToDeliverWrapper {
}

export default FailureToDeliverWrapper;