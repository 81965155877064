import { Button, Stack, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { DateFormatter } from "../../analytics/helpers/DateFormatter";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const BessFileDayPicker: React.FC<IBessFileDayPickerProps> = ({ date, onDateUpdated }) => {
    const [selectedDate, setSelectedDate] = useState<Moment>(date);

    const today = useMemo(() => {
        const date = moment.utc().startOf("day");
        return date;
    }, []);

    const earliestAllowed = useMemo(() => {
        const date = moment(today).subtract(30, "day");
        return date;
    }, [today])


    const dateText = useMemo(() => {
        if (selectedDate.isSame(today, "day")) {
            return "Today";
        }

        return DateFormatter(selectedDate);
    }, [today, selectedDate]);

    const canGoForward = useMemo(() => {
        return !selectedDate.isSame(today, "day");
    }, [selectedDate, today]);

    const canGoBackward = useMemo(() => {
        return !selectedDate.isSame(earliestAllowed, "day");
    }, [selectedDate, earliestAllowed]);

    const updateSelectedDate = (numberOfDays: number) => {
        const newDate = moment(selectedDate).add(numberOfDays, "day");
        setSelectedDate(newDate);

        onDateUpdated(newDate);
    }

    return (
        <Stack direction="row" spacing={1}>
            <Button onClick={() => updateSelectedDate(-1)} disabled={!canGoBackward}>
                <ArrowBack />
            </Button>
            <Typography minWidth="110px" textAlign="center" lineHeight="40px" variant="h6">{dateText}</Typography>
            <Button onClick={() => updateSelectedDate(1)} disabled={!canGoForward}>
                <ArrowForward />
            </Button>
        </Stack>
    );
}

export interface IBessFileDayPickerProps {
    date: Moment;
    onDateUpdated: (newDate: Moment) => void;
}

export default BessFileDayPicker;
