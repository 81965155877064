import { SvgIconComponent } from "@mui/icons-material";
import { ISiteStatus } from "../ISiteStatus";
import { CubicCoords } from "../shared/hex-grid";
import { HexagonMouseEventHandler } from "react-hexgrid/lib/Hexagon/Hexagon";
import { StatusHiveTile } from "../../../components/status-hive/StatusHiveTile";

export class StatusHiveDataTile {
    constructor(position: CubicCoords, status: StatusHiveSiteData) {
        this._position = position;
        this._status = status;
    }

    private _position: CubicCoords;
    private _status: StatusHiveSiteData;

    public get siteId() {
        return this._status.siteId;
    }

    public readonly updateTileData = (newData: StatusHiveDataTile) => {
        this._status = newData._status;
        this._position = newData._position;
    }

    private readonly onClick: HexagonMouseEventHandler<SVGElement> = (event, source) => {
        // const { path } = this.state;
        // if (path.start == null) {
        //   path.start = source.state.hex;
        // }
        // else {
        //   path.start = null;
        //   path.end = null;
        // }
        // this.setState({ path });
    }

    public readonly graphic = (dispatchMode: boolean, updateData: (newData: ISiteStatus) => void, flashSync: boolean, dispatchSite: (request: IDispatchRequest) => Promise<boolean>) : JSX.Element => {
        return (
            <StatusHiveTile
                key={this._status.siteId}
                coords={this._position}
                status={this._status}
                dispatchMode={dispatchMode}
                updateStatus={updateData}
                flashSync={flashSync}
                dispatchSite={dispatchSite}
            />
        );
    }
}

export interface IStatusHivePlacementResult {
    placed: StatusHiveDataTile[];
    failed: StatusHiveSiteData[];
}

export interface IDispatchRequest {
    siteID: string;
    siteName: string;
    newManualStatus: SiteManualStatus;
}
export interface IDispatchResponse {
    siteName: string;
    status: boolean
}
export enum StatusHiveTileStatus {
    Active,
    Pending,
    Idle,
}

export enum SiteManualStatus {
    NotManual = 0,
    On = 1,
    Off = 2
}

export type StatusHiveSiteData = ISiteStatus & {
    priority: number;
    colour: string;
    statusHumanReadable: string;
    tileStatus: StatusHiveTileStatus;
    assetIcon: SvgIconComponent;
    commsIconData: { colour: string, tooltip: string };
    dispatchTimeHumanReadable: string;
    displayFrequencyServices: boolean;
    borderColour?: string;
    valueTextColour?: string;
    underOffer: boolean;
    isManual: boolean;
    flash: boolean;
    opacity: number;
}