import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Moment } from "moment";
import { ISystemSummaryData } from "../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
import OutageFormDate from "../../../operate/outage-forms/OutageFormDate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";


const SystemSummaryControls: React.FC<ISystemSummaryControls> = ({ unfilteredData, setFilteredData, startDate, endDate, setStartDate, setEndDate, disabled }) => {
    const applyFilters = () => {
        let filteredData: ISystemSummaryData[] = []
        unfilteredData.forEach(dataPoint => {
            const dateTime = moment(dataPoint.dateTime).utc()
            if (dateTime.isBetween(startDate, endDate)) {
                filteredData.push(dataPoint)
            }
        })
        return filteredData
    }

    useEffect(() => {
        setFilteredData(applyFilters())
    }, [unfilteredData, startDate, endDate, setFilteredData])

    return (
        <Box width="100%">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignItems="stretch"
                >
                    <Grid xs={8} />
                    <Grid xs={2}>
                        <OutageFormDate label="Start Date" date={startDate} setDate={setStartDate} maxDate={moment(endDate).subtract(1, "day")} disabled={disabled} />
                    </Grid>
                    <Grid xs={2}>
                        <OutageFormDate label="End Date" date={endDate} setDate={setEndDate} minDate={moment(startDate).add(1, "day")} disabled={disabled} />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Box>
    );
}
export interface ISystemSummaryControls {
    unfilteredData: ISystemSummaryData[]
    setFilteredData: (filteredData: ISystemSummaryData[]) => void
    startDate: Moment
    endDate: Moment
    setStartDate: (startDate: Moment) => void
    setEndDate: (endDate: Moment) => void
    disabled?: boolean
}

export default SystemSummaryControls;