import { Tooltip, Typography } from "@mui/material";
import { MarkerIcon } from "./MarkerIcon";

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const HexIndicatorBar: React.FC<IHexIndicatorBarProps> = ({ x = 0, y = 0, height, width, fill, hidePercentage, hideMarker, fillHorizontally, value = 0, maxValue = 0, markerValue = 0, unit = "", valueTooltipLabel, markerTooltipLabel }) => {
    const noMarkerValue = !markerValue && markerValue !== 0;
    hideMarker = hideMarker || noMarkerValue;
    markerValue = markerValue || 0;

    // Get readable values before doing any clamping
    const readableValue = parseFloat(value.toFixed(2));
    const readableMarkerValue = parseFloat(markerValue.toFixed(2));
    const readableMaxValue = parseFloat(maxValue.toFixed(2));

    // Clamp values
    value = clamp(value, 0, maxValue);
    markerValue = clamp(markerValue, 0, maxValue);

    // Build tooltip
    const valueTooltipText = `${readableValue}/${readableMaxValue}${unit}`; // The tooltip text for the bar value
    const markerTooltipText = `${readableMarkerValue}/${readableMaxValue}${unit}`; // The tooltip text for the marker value
    const tooltip: JSX.Element = (
        <>
            <Typography fontSize={"inherit"} textAlign={"center"}>{valueTooltipLabel}</Typography>
            <Typography fontSize={"inherit"} textAlign={"center"}>{valueTooltipText}</Typography>
            {
                noMarkerValue || hideMarker || fillHorizontally || (
                    <>
                        <br />
                        <Typography fontSize={"inherit"} textAlign={"center"}>{markerTooltipLabel}</Typography>
                        <Typography fontSize={"inherit"} textAlign={"center"}>{markerTooltipText}</Typography>
                    </>

                )
            }
        </>
    );

    // Calculate values
    const strokeWidth = 0.0025; // The total stroke width of the rectangle
    const lineThickness = strokeWidth / 2; // The thickness of each line
    const fontSize = width / 2;

    const percentage = value <= 0 ? 0 : (value / maxValue) * 100; // The percentage of the bar that is filled

    const availableFillHeight = height - strokeWidth; // How much of the bar's height is available to fill (the area inside the strokes)
    const availableFillWidth = width - strokeWidth; // How much of the bar's width is available to fill (the area inside the strokes)

    const valueHeight = (availableFillHeight / 100) * percentage; // The actual height of the fill
    const heightDiff = availableFillHeight - valueHeight; // The height different between the available fill height and the actual

    const markerSizeValue = width; // The size of the marker indicator
    const markerPercentage = markerValue <= 0 ? 0 : (markerValue / maxValue) * 100; // The percentage of the bars height that the marker should be at
    const markerValueHeight = (availableFillHeight / 100) * markerPercentage; // The actual height of the marker
    const markerHeightDiff = availableFillHeight - markerValueHeight; // The height difference between available fill height and the marker

    const xPosValue = -(width / 2) + x; // The X position of the outer bar
    const yPosValue = -(height / 2) + y; // The Y position of the outer bar

    let fillXPosValue = xPosValue + lineThickness; // The X position of the fill
    let fillYPosValue = yPosValue + heightDiff + lineThickness; // The Y position of the fill

    const markerYPosValue = (yPosValue + markerHeightDiff + lineThickness) - (markerSizeValue / 2); // The Y position of the marker indicator
    const markerXPosValue = xPosValue + markerSizeValue + (strokeWidth * 2); // The X position of the marker indicator

    // The formatted em strings for the above values
    const xPos = `${xPosValue}em`;
    const yPos = `${yPosValue}em`;
    const rectWidth = `${width}em`;
    const rectHeight = `${height}em`;

    let fillHeight = `${valueHeight}em`;
    let fillWidth = `${availableFillWidth}em`;
    let fillYPos = `${fillYPosValue}em`;
    let fillXPos = `${fillXPosValue}em`;

    if (fillHorizontally) {
        const valueWidth = (availableFillWidth / 100) * percentage // The actiual width of the fill if filling horizontally
        fillHeight = `${availableFillHeight}em`;
        fillWidth = `${valueWidth}em`;
        fillYPos = `${yPosValue + lineThickness}em` ;
    }

    const markerSize = `${markerSizeValue}em`;
    const markerYPos = `${markerYPosValue}em`;
    const markerXPos = `${markerXPosValue}em`;

    return (
        <>
            {/* {hidePercentage || <Text x={xPos} y={0} fontSize={fontSize} fill={HiveColours.Running} strokeWidth={"0"}>100%</Text>} */}
            <Tooltip title={tooltip} placement="top" followCursor={true}>
                <rect fill={'transparent'} x={xPos} y={yPos} height={rectHeight} width={rectWidth} stroke={fill} strokeWidth={`${strokeWidth}em`} />
            </Tooltip>
            <Tooltip title={tooltip} placement="top" followCursor={true}>
                <rect fill={fill} x={fillXPos} y={fillYPos} height={fillHeight} width={fillWidth} strokeWidth={0} />
            </Tooltip>
            {
                hideMarker || fillHorizontally ||
                <Tooltip title={tooltip} placement="top" followCursor={true}>
                    <svg x={markerXPos} y={markerYPos} color={fill}>
                        <MarkerIcon height={markerSize} width={markerSize} />
                    </svg>
                </Tooltip>
            }
        </>
    )
}

export interface IHexIndicatorBarProps {
    // Visual
    x?: number;
    y?: number;
    height: number;
    width: number;
    fill?: string;
    hidePercentage?: boolean;
    hideMarker?: boolean;
    fillHorizontally?: boolean;

    // Values
    value: number;
    maxValue: number;
    markerValue?: number;

    //Tooltips
    unit?: string;
    valueTooltipLabel?: string;
    markerTooltipLabel?: string;
}
