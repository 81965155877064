import { useTheme } from "@mui/material";
import { IBalancingSummaryCategories } from "../../../shared/types/analytics/trading-analytics/IBalancingSummaryData";
import { ISystemSummaryCategories } from "../../../shared/types/analytics/trading-analytics/ISystemSummaryData";

export const GetBalancingSummaryBackgroundColors = () => {
    const theme = useTheme()
    const extendedFuelDict = GetExtendedFuelBackgroundColours()

    const coloursDict: IBalancingSummaryCategories<string> = {
        biomass: extendedFuelDict.biomass,
        ccgt: extendedFuelDict.ccgt,
        flexGen: extendedFuelDict.flexgen,
        battery: extendedFuelDict.battery,
        nuclear: extendedFuelDict.nuclear,
        interconnectors: extendedFuelDict.interconnectors,
        pumped: extendedFuelDict.pumped,
        hydro: extendedFuelDict.hydro,
        bsadUp: extendedFuelDict.bsad,
        bsadDown: extendedFuelDict.bsad,
        bid250Plus: "#A65D5B",
        bid200To250: "#B6755B",
        bid170To200: "#C78D5D",
        bid140To170: "#D8A65E",
        bid120To140: "#E9BF60",
        bid100To120: "#FAD761",
        bid80To100: "#FAE086",
        bid65To80: "#FAEAAD",
        bid50To65: "#FAF2D4",
        bid0To50: "#FBFBFB",
        offer0Minus: "#595959",
        offer0To10: "#D3E8F0",
        offer10To20: "#BFDAE2",
        offer20To30: "#A9CCD6",
        offer30To35: "#95BDC9",
        offer35To40: "#80AFBD",
        offer40To45: "#7CA6B2",
        offer45To50: "#789EA8",
        offer50To55: "#74949E",
        offer55Plus: "#6C838A",
        overallImbalance: theme.palette.common.black,
    }
    return coloursDict
}

export const GetSystemSummaryBackgroundColors = () => {
    const theme = useTheme()

    const coloursDict: ISystemSummaryCategories<string> = {
        imbalNGC: theme.palette.conradEnergyMagenta.dark,
        disBSAD: theme.palette.conradEnergyFreshGreen.main,
        ispStack: theme.palette.conradEnergyMagenta.light,
        nivForecast: theme.palette.conradEnergyYellow.main,
        totalBalMechLevel: theme.palette.conradEnergyDeepBlue.dark,
        ndf: theme.palette.conradEnergyDeepBlue.light,
        tsdf: theme.palette.conradEnergyGrey.dark,
        indo: theme.palette.common.white,
        itsdo: theme.palette.conradEnergyCyan.main,
        mid: theme.palette.common.black
    }
    return coloursDict
}

export const GetFuelBackgroundColours = () => {
    const theme = useTheme()

    const coloursDict: IFuelColoursDictionary = {
        flexgen: "#5D6D96",
        ocgt: theme.palette.conradEnergyMagenta.dark,
        pumped: "#80AEBD",
        battery: "#C48DFD",
        biomass: "#8F6844",
        coal: theme.palette.common.black,
        bsad: theme.palette.conradEnergyGrey.main,
        ccgt: "#66A1D4",
        wind: theme.palette.conradEnergyLightGrey.main,
        hydro: "#6EC9F3"
    }
    return coloursDict
};

export const GetExtendedFuelBackgroundColours = () => {
    const theme = useTheme()

    const baseColoursDict = GetFuelBackgroundColours()
    const extendedDict = baseColoursDict as IFuelColoursDictionaryExtended

    extendedDict.interconnectors = "#E5A265"
    extendedDict.oil = theme.palette.conradEnergyGrey.dark
    extendedDict.peat = theme.palette.conradEnergyFreshGreen.light
    extendedDict.solar = theme.palette.conradEnergyYellow.main
    extendedDict.nuclear = "#FCFF63"
    extendedDict.synchronouscondenser = theme.palette.conradEnergyLightGrey.light
    extendedDict.other = theme.palette.common.black
    extendedDict.total = theme.palette.common.black
    return extendedDict
};

export const GetFuelTextColours = () => {
    const theme = useTheme()

    const coloursDict: IFuelColoursDictionary = {
        flexgen: theme.palette.common.white,
        ocgt: theme.palette.common.white,
        pumped: theme.palette.common.white,
        battery: theme.palette.common.white,
        biomass: theme.palette.common.white,
        coal: theme.palette.common.white,
        bsad: theme.palette.common.black,
        ccgt: theme.palette.common.white,
        wind: theme.palette.common.black,
        hydro: theme.palette.common.white,
    }
    return coloursDict
};

export const GetExtendedFuelTextColours = () => {
    const theme = useTheme()

    const baseColoursDict = GetFuelTextColours()
    const extendedDict = baseColoursDict as IFuelColoursDictionaryExtended

    extendedDict.interconnectors = theme.palette.common.white
    extendedDict.oil = theme.palette.common.white
    extendedDict.peat = theme.palette.common.black
    extendedDict.solar = theme.palette.common.black
    extendedDict.nuclear = theme.palette.common.black
    extendedDict.synchronouscondenser = theme.palette.common.black
    extendedDict.other = theme.palette.common.white
    extendedDict.total = theme.palette.common.white
    return extendedDict
};

export const formatFuelTypeToKey = (fuelType: string) => {
    return fuelType.replaceAll(" ", "").toLowerCase()
}

export interface IFuelColoursDictionary {
    flexgen: string,
    ocgt: string,
    pumped: string,
    battery: string,
    biomass: string,
    coal: string,
    bsad: string,
    ccgt: string,
    wind: string,
    hydro: string
}

export interface IFuelColoursDictionaryExtended extends IFuelColoursDictionary {
    interconnectors: string,
    oil: string,
    peat: string,
    solar: string,
    nuclear: string,
    synchronouscondenser: string,
    other: string,
    total: string
}