import React, { useEffect, useState } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { IBidOfferData } from '../../../../shared/types/analytics/trading-analytics/IBidOfferData';
import { formatFuelTypeToKey, GetFuelBackgroundColours, GetFuelTextColours, IFuelColoursDictionary } from '../../helpers/TradingAnalyticsFuelColours';
import moment from 'moment';

const defaultHeadCells: HeadCell[] = [
    {
        id: "siteID",
        label: "Site ID",
        dataType: "String"
    },
    {
        id: "location",
        label: "Location",
        dataType: "String"
    },
    {
        id: "fuelType",
        label: "Fuel Type",
        dataType: "String"
    },
    {
        id: "price",
        label: "Price",
        dataType: "Price"
    },
    {
        id: "fpn",
        label: "FPN",
        dataType: "Power"
    },
    {
        id: "boa",
        label: "BOA",
        dataType: "Power"
    },
    {
        id: "bmAdjustedFPN",
        label: "BM Adjusted FPN",
        dataType: "Power"
    },
    {
        id: "mel",
        label: "MEL",
        dataType: "Power"
    },
    {
        id: "sel",
        label: "SEL",
        dataType: "Power"
    },
    {
        id: "pair",
        label: "Pair",
        dataType: "String"
    },
    {
        id: "volume",
        label: "Volume",
        dataType: "Power"
    },
]

const extraHeadCells: HeadCell[] = [
    {
        id: "mnzt",
        label: "MNZT",
        dataType: "Time"
    },
    {
        id: "mzt",
        label: "MZT",
        dataType: "Time"
    },
    {
        id: "ndz",
        label: "NDZ",
        dataType: "Time"
    },
    {
        id: "latestCall",
        label: "Latest Call",
        dataType: "DateTime"
    }
]

const BidOfferTable: React.FC<IBidOfferTable> = ({ items, showDynamicData }) => {
    const [orderDescending, setOrderDescending] = useState<boolean>(false);
    const [orderCategory, setOrderCategory] = useState<keyof IBidOfferData>("price");
    const [headCells, setHeadCells] = useState<HeadCell[]>(defaultHeadCells)

    const fuelBackgroundColoursDictionary = GetFuelBackgroundColours()
    const fuelTextColoursDictionary = GetFuelTextColours()
    const theme = useTheme()
    const defaultTextColour = theme.palette.common.black
    const bidBackgroundColour = theme.palette.common.white
    const offerBackgroundColour = theme.palette.conradEnergyCyan.light
    const systemFlagBackgroundColour = theme.palette.conradEnergyYellow.main
    const highBOABackgroundColour = "orange"

    const sortItems = (inputArray: IBidOfferData[], category: keyof IBidOfferData, descending: boolean) => {
        const outputArray = [...inputArray]
        outputArray.sort((a, b) => {
            const aValue = a[category]
            const bValue = b[category]
            if (aValue === null) { return 1 }
            else if (bValue === null) { return -1 }
            else if (aValue < bValue) { return -1 }
            return 1
        })
        if (descending) {
            outputArray.reverse()
        }
        return outputArray
    }

    useEffect(() => {
        setInternalItems(sortItems(items, orderCategory, orderDescending))
    }, [items])

    if (items === undefined) {
        items = []
    }

    const [internalItems, setInternalItems] = React.useState<IBidOfferData[]>(sortItems(items, orderCategory, orderDescending))

    const handleSort = (categoryID: keyof IBidOfferData) => {
        let descending = false
        if (orderCategory === categoryID && !orderDescending) {
            descending = true
        }
        setOrderDescending(descending)
        setOrderCategory(categoryID)
        setInternalItems(sortItems(internalItems, categoryID, descending))
    }

    useEffect(() => {
        if (showDynamicData) {
            setHeadCells([...defaultHeadCells, ...extraHeadCells])
        }
        else {
            setHeadCells(defaultHeadCells)
        }
    }, [showDynamicData])

    const elexonLink = (bmuID: string) => {
        const url = "https://bmrs.elexon.co.uk/balancing-mechanism-bmu-view?bmuId=" + bmuID
        window.open(url);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    align="center"
                                    key={headCell.id}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                                        onClick={() => handleSort(headCell.id)}
                                    >
                                        <Typography variant="subtitle1" fontSize="small">{headCell.label}</Typography>
                                        {headCell.id === orderCategory ? (orderDescending ? < ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />) : <></>}
                                    </IconButton>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {internalItems
                            .map((item) => {
                                const rowKey = "BOA-table-key-" + item.siteID + "-" + item.price
                                let fuelBackgroundColour = "default"
                                let fuelTextColour = "default"
                                const fuelKey = formatFuelTypeToKey(item.fuelType)
                                if (fuelKey in fuelBackgroundColoursDictionary) {
                                    const colourKey = fuelKey as keyof IFuelColoursDictionary
                                    fuelBackgroundColour = fuelBackgroundColoursDictionary[colourKey]
                                    fuelTextColour = fuelTextColoursDictionary[colourKey]
                                }
                                let backgroundColour = item.bid ? bidBackgroundColour : offerBackgroundColour
                                if (item.systemFlag) {
                                    backgroundColour = systemFlagBackgroundColour
                                }
                                else if (item.boa > 0) {
                                    backgroundColour = highBOABackgroundColour
                                }
                                return (
                                    <TableRow key={rowKey}>
                                        {
                                            headCells.map((headCell) => {

                                                const cellValue = item[headCell.id].toString()
                                                let cellContents = <>{cellValue.toString()}</>
                                                if (headCell.dataType === "Price") {
                                                    cellContents = <>&pound;{parseFloat(cellValue).toFixed(2).toString()}</>
                                                }
                                                else if (headCell.dataType === "Power") {
                                                    cellContents = <>{parseFloat(cellValue).toFixed(0).toString()}MW</>
                                                }
                                                else if (headCell.dataType === "Time") {
                                                    cellContents = <>{parseFloat(cellValue).toFixed(0).toString()} Mins</>
                                                }
                                                else if (headCell.dataType === "DateTime") {
                                                    cellContents = <>{moment(cellValue).format("YYYY-MM-DD HH:mm:ss")}</>
                                                }


                                                const fuelColumn = headCell.id === "fuelType"
                                                const siteColumn = headCell.id === "siteID"

                                                if (siteColumn) {
                                                    cellContents = <Button onClick={() => elexonLink(item.elexonBMUID)}>{cellContents}</Button>
                                                }

                                                return (
                                                    <TableCell
                                                        key={rowKey + "-" + headCell.id}
                                                        align="center"
                                                        sx={{
                                                            color: fuelColumn ? fuelTextColour : defaultTextColour,
                                                            backgroundColor: fuelColumn ? fuelBackgroundColour : backgroundColour
                                                        }}
                                                    >
                                                        {cellContents}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

}
interface HeadCell {
    id: keyof IBidOfferData,
    label: string,
    dataType: "String"|"Price"|"Power"|"Time"|"DateTime"
}
export interface IBidOfferTable {
    items: IBidOfferData[],
    showDynamicData: boolean
}

export default BidOfferTable;