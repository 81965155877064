import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { formatFuelTypeToKey } from "../../helpers/TradingAnalyticsFuelColours";
import FuelSelector from "../shared/FuelSelector";
import { IMarketUpdateDictionary, IMarketUpdateMessage } from "../../../../shared/types/analytics/trading-analytics/IMarketUpdateMessage";
import { GetDemandForecastDeviation, GetFrequency, GetHighestDailyLOLP, GetLowestDailyDRM, GetTotalUnplannedOutages, GetWindForecastDeviation } from "../../helpers/TradingAnalyticsData";

const MarketUpdateHeaderBox: React.FC<IMarketUpdateHeaderBox> = ({ label, value, backgroundColor = "White", unit }) => {

    return (
        <Grid xs={1}>
            <Box sx={{
                mr: 1,
                p: 1,
                height: "100%",
                backgroundColor: backgroundColor,
                borderRadius: "10px",
                border: "1px solid white",
            }}>
                <Grid container spacing={0} alignItems="center" justifyContent="center" height="100%">
                    <Grid xs={6}>
                        <Typography color="black" variant="body2" textAlign="left" fontWeight="bold">{label}</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography color="black" variant="h6" textAlign="right" justifyContent="right" fontWeight="bold" sx={{ direction: "rtl" }}>{value}{unit}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

interface IMarketUpdateHeaderBox {
    label: string,
    value: string,
    backgroundColor?: string,
    unit?: string
}

const MarketUpdateFuelSelect: React.FC<IMarketUpdateFuelSelect> = ({ selectedTypes, setSelectedTypes }) => {

    return (
        <Grid xs={1}>
            <Box sx={{
                mx: 1,
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <FuelSelector selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} fullFuelList={true} />
            </Box>
        </Grid>
    );
}

interface IMarketUpdateFuelSelect {
    selectedTypes: string[],
    setSelectedTypes: (selectedTypes: string[]) => void
}



const MarketUpdateHeader: React.FC<IMarketUpdateHeader> = ({ unfilteredData, setFilteredData }) => {
    const theme = useTheme()
    const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>(["All"])
    const [highestLOLP, setHighestLOLP] = useState<number>(0)
    const [lowestDRM, setLowestDRM] = useState<number>(0)
    const [frequency, setFrequency] = useState<number>(0)
    const [demandForecastDeviation, setDemandForecastDeviation] = useState<number>(0)
    const [windForecastDeviation, setWindForecastDeviation] = useState<number>(0)
    const [totalUnplannedOutages, setTotalUnplannedOutages] = useState<number>(0)

    const applyFilters = useCallback(() => {
        const filteredDict: IMarketUpdateDictionary = {} as IMarketUpdateDictionary
        const selectedFuels = new Set<string>();
        selectedFuelTypes.forEach((fuelType) => {
            selectedFuels.add(formatFuelTypeToKey(fuelType))
        })
        let key: keyof IMarketUpdateDictionary
        for (key in unfilteredData) {
            const filteredMessages: IMarketUpdateMessage[] = []
            const unfilteredMessages = unfilteredData[key]
            unfilteredMessages.forEach(message => {
                if (selectedFuels.has("all") || selectedFuels.has(formatFuelTypeToKey(message.fuelType))) {
                    filteredMessages.push(message)
                }
            })
            if (filteredMessages.length > 0) {
                filteredDict[key] = filteredMessages
            }
        }

        return filteredDict
    }, [selectedFuelTypes, unfilteredData])

    useEffect(() => {
        setFilteredData(applyFilters())
    }, [unfilteredData, selectedFuelTypes, applyFilters, setFilteredData])

    const loadData = useCallback(() => {
        setHighestLOLP(GetHighestDailyLOLP())
        setLowestDRM(GetLowestDailyDRM())
        setFrequency(GetFrequency())
        setDemandForecastDeviation(GetDemandForecastDeviation())
        setWindForecastDeviation(GetWindForecastDeviation())
        setTotalUnplannedOutages(GetTotalUnplannedOutages())
    }, [])

    useEffect(() => {
        loadData()
        const intervalId = setInterval(() => {
            loadData();
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadData])

    return (
        <Box width="100%">
            <Grid
                container
                spacing={0}
                columns={7}
                alignItems="stretch"
            >
                <MarketUpdateFuelSelect selectedTypes={selectedFuelTypes} setSelectedTypes={setSelectedFuelTypes} />
                <MarketUpdateHeaderBox label={"Daily Highest LOLP"} value={(highestLOLP * 100).toFixed(0)} unit={"%"} />
                <MarketUpdateHeaderBox label={"Daily Lowest DRM"} value={lowestDRM.toString()} unit={"MW"} />
                <MarketUpdateHeaderBox label={"Frequency"} value={frequency.toFixed(2)} backgroundColor={frequency > 49.7 && frequency < 50.3 ? theme.palette.common.white : theme.palette.error.main} />
                <MarketUpdateHeaderBox label={"Demand Forecast Deviation"} value={demandForecastDeviation.toFixed(0)} unit={"MW"} />
                <MarketUpdateHeaderBox label={"Wind Forecast Deviation"} value={windForecastDeviation.toFixed(0)} unit={"MW"} />
                <MarketUpdateHeaderBox label={"Total Unplanned Outages"} value={totalUnplannedOutages.toFixed(0)} unit={"MW"} />
            </Grid>
        </Box>
    );
}
export interface IMarketUpdateHeader {
    unfilteredData: IMarketUpdateDictionary
    setFilteredData: (filteredData: IMarketUpdateDictionary) => void
}

export default MarketUpdateHeader;