import moment, { Moment } from "moment"
import { ISystemSummaryCategories, ISystemSummaryData } from "../../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
export const CalcXTicks = (domainStart: Moment, domainEnd: Moment) => {
    let tick = moment(domainStart)
    if (tick.minutes() >= 30) {
        tick = moment(tick).endOf("hour")
    }
    else {
        tick = moment(tick).startOf("hour").add(30, "minutes")
    }

    const newXTicks = []
    while (tick.isSameOrBefore(domainEnd)) {
        newXTicks.push(tick.valueOf())
        tick = moment(tick).add(30, "minutes")
    }
    return newXTicks
};

export const CalcYTicks = (chartData: ISystemSummaryData[], keys: (keyof ISystemSummaryCategories<any>)[], enabledData: ISystemSummaryCategories<boolean>, interval: number, defaultMax: number, min?: number) => {
    let absoluteMax = 0
    chartData.forEach(dataPoint => {
        keys.forEach(key => {
            if (enabledData[key]) {
                const value = Math.abs(dataPoint[key])
                if (value > absoluteMax) {
                    absoluteMax = value
                }
            }
        })
    })

    absoluteMax = Math.round(absoluteMax / interval) * interval
    if (absoluteMax === 0) { absoluteMax = defaultMax }
    let minimum = min
    if (minimum === undefined) {
        minimum = 0 - absoluteMax
    }
    const ticks: number[] = []
    let tick = minimum
    while (tick <= absoluteMax) {
        ticks.push(tick)
        tick += interval
    }
    return ticks
};