import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, useTheme } from "@mui/material";
import { IMarketUpdateDictionary } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateMessage';
import MarketUpdateRow from './MarketUpdateTableRow';
import { IMarketUpdateSetting } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateSetting';
import { useUpdateMarketUpdateSettingsMutation } from '../../../../shared/api/TradingAnalyticsApi';
import { IMarketUpdateCategories } from '../../../../shared/types/analytics/trading-analytics/IMarketUpdateCategories';

const MarketUpdateTableWrapper: React.FC<IMarketUpdateTableWrapper> = ({ data, setting }) => {
    const [updateSetting] = useUpdateMarketUpdateSettingsMutation()
    const theme = useTheme()
    const headerColor = theme.palette.primary.main



    const handleSettingChange = (key: keyof IMarketUpdateSetting, value: number) => {
        const newSetting: IMarketUpdateSetting = structuredClone(setting)
        if (key !== "user") {
            newSetting[key] = value
            updateSetting(newSetting)
        }
    }

    const labelMap: IMarketUpdateCategories<string> = {
        sonar: "SONAR Alerts",
        remit: "Outages REMIT",
        system: "System Warning",
        mel: "MEL Drops",
        bod: "BOD Changes",
        boa: "BOA and BSAD Orders",
        interconnector: "Interconnector Trips and Flow Changes"
    }

    return (
        <Grid container columns={18} alignItems="stretch" textAlign="center" spacing={2} margin={0}>
            <Grid xs={3} sx={{ backgroundColor: headerColor, height: "100%" }}>
                <Typography variant="h5">Alert Type</Typography>
            </Grid>
            <Grid xs={2} sx={{ backgroundColor: headerColor }}>
                <Typography variant="h5">Time</Typography>
            </Grid>
            <Grid xs={2} sx={{ backgroundColor: headerColor }}>
                <Typography variant="h5">Unit</Typography>
            </Grid>
            <Grid xs={9} sx={{ backgroundColor: headerColor }}>
                <Typography variant="h5">Text</Typography>
            </Grid>
            <Grid xs={2} sx={{ backgroundColor: headerColor }}>
                <Typography variant="h5">Link</Typography>
            </Grid>
            {
                (Object.keys(data) as Array<keyof typeof data>).map((key) => 
                    <MarketUpdateRow key={"Market-Update-Table-" + key} label={labelMap[key]} data={data[key]} setting={setting} dictKey={key} handleSettingChange={handleSettingChange} />
                )
            }
        </Grid>
    );
}

export interface IMarketUpdateTableWrapper {
    data: IMarketUpdateDictionary
    setting: IMarketUpdateSetting
}

export default MarketUpdateTableWrapper;