import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import analyticsApi from "../../shared/api/AnalyticsApi";
import moment from "moment"
import LoadingSymbol from "../operate/LoadingSymbol";
import { Bar, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart } from 'recharts';
import { useColourModeContext } from "../../components/ColourMode";
import { IAnalyticsChartDataItem } from "../../shared/types/analytics/IAnalyticsChartDataItem";
import { NewColorPalette } from "./helpers/NewColorPalette";
import { DateTimeFormatter } from "./helpers/DateFormatter"

const BalancingVolumeChart: React.FC<IBalancingVolumeChart> = ({ displayScreen }) => {
    const [chartData, setChartData] = React.useState<Array<IAnalyticsChartDataItem>>([])
    const [yAxisMax, setYAxisMax] = React.useState<number>(0)
    const [currentTime, setCurrentTime] = React.useState<number>(0)
    const [beginningOfDay, setBeginningOfDay] = React.useState<number>(0)
    const [domainStart, setDomainStart] = React.useState<number>(0)
    const [domainEnd, setDomainEnd] = React.useState<number>(1)
    const colourMode = useColourModeContext();

    const loadBalancingData = useCallback(() => {
        const formattedData = new Array<IAnalyticsChartDataItem>();
        let yMax = 0
        analyticsApi.getBalanceVolumeData()
            .then((response) => {
                const currentMoment = moment().utc().valueOf()
                let currentDate = moment().startOf('day').utc()
                const today = currentDate.valueOf()
                const yesterday = currentDate.add(-1, 'days').valueOf()
                const tomorrow = currentDate.add(2, 'days').valueOf()
                const dateTimes = new Set<number>()
                response.forEach((dataItem) => {
                    const dataMoment = moment(dataItem.dateTimeUTC + "Z").valueOf()
                    if (!dateTimes.has(dataMoment)) {
                        dateTimes.add(dataMoment)
                        const BMBidEnergy = (dataItem.bmBidEnergy ? dataItem.bmBidEnergy : 0)
                        const BMOfferEnergy = (dataItem.bmOfferEnergy ? dataItem.bmOfferEnergy : 0)
                        const BSADBid = (dataItem.bsadBid ? dataItem.bsadBid : 0)
                        const BSADOffer = (dataItem.bsadOffer ? dataItem.bsadOffer : 0)
                        const BMBidSystem = (dataItem.bmBidSystem ? dataItem.bmBidSystem : 0)
                        const BMOfferSystem = (dataItem.bmOfferSystem ? dataItem.bmOfferSystem : 0)
                        const niv = dataItem.niv
                        const belowYAxis = Math.abs(BSADBid + BMBidEnergy + BMBidSystem)
                        const aboveYAxis = BSADOffer + BMOfferEnergy + BMOfferSystem
                        if (belowYAxis > yMax) { yMax = belowYAxis }
                        if (aboveYAxis > yMax) { yMax = aboveYAxis }
                        const newItem: IAnalyticsChartDataItem = {
                            dateTime: dataMoment,
                            BSADBid: BSADBid,
                            BSADOffer: BSADOffer,
                            BMBidEnergy: BMBidEnergy,
                            BMOfferEnergy: BMOfferEnergy,
                            BMBidSystem: BMBidSystem,
                            BMOfferSystem: BMOfferSystem,
                            NIV: niv
                        }
                        formattedData.push(newItem)
                    }
                })
                formattedData.sort((a, b) => {
                    if (a.dateTime < b.dateTime) { return -1 }
                    else { return 1 }
                })
                yMax = Math.ceil(yMax / 500) * 500 // Round Y Axis up to nearest 500
                setYAxisMax(yMax)
                setDomainStart(yesterday)
                setDomainEnd(tomorrow)
                setCurrentTime(currentMoment)
                setBeginningOfDay(today)
                setChartData(formattedData)
            })
    }, [])


    useEffect(() => {
        loadBalancingData()
        const intervalId = setInterval(() => {
            loadBalancingData(); // Fetch data every 2 minutes
        }, 120000);

        return () => clearInterval(intervalId);
    }, [loadBalancingData])

    const renderBars = () => {
        const dataKeys = ["BSADBid", "BSADOffer", "BMBidEnergy", "BMOfferEnergy", "BMBidSystem", "BMOfferSystem"]
        const names = ["BSAD Bid", "BSAD Offer", "BM Bid Energy", "BM Offer Energy", "BM Bid System", "BM Offer System"]
        const colors = [0, 3, 1, 4, 2, 5]
        const bars = dataKeys.map((key, index) => {
            const name = names[index]
            const color = NewColorPalette[colors[index]]
            return (
                <Bar dataKey={key} key={key} name={name} unit=" MW" stroke="#000000" fill={color} stackId="1" isAnimationActive={false} />
            )
        })
        return bars
    }
    return (
        <Box
            sx={{ "height": "100%", "width": "100%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Box
                sx={(displayScreen ? { "height": "100%", "width": "100%", "backgroundColor": "#262626", "borderRadius": "10px", padding: 1 } : { "height": "100%", "width": "100%" })}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Typography style={{ fontSize: "20px", color: (colourMode.isDarkMode || displayScreen ? "white" : "black") }}>Balancing Volume Chart</Typography>
                <ResponsiveContainer height={"87%" }>
                    {(chartData.length > 0 ?
                        <ComposedChart data={chartData} stackOffset="sign">
                            <XAxis padding="gap" name="Time" domain={[domainStart, domainEnd]} dataKey="dateTime" scale="time" type="number" tickFormatter={DateTimeFormatter} hide={displayScreen} allowDataOverflow={true} />
                            <YAxis domain={[0 - yAxisMax, yAxisMax]}><Label dx={-20} angle={-90}>MW</Label></YAxis>
                            {(!displayScreen ? <Legend /> : <></>)}
                            <Tooltip
                                contentStyle={{ backgroundColor: (colourMode.isDarkMode ? "#262626" : "white") }}
                                labelFormatter={value => {
                                    return "Time: " + DateTimeFormatter(value) + " UTC";
                                }}
                                formatter={(value, name) => { return (value as number).toFixed(2).toString() }}
                            />
                            <ReferenceLine x={beginningOfDay} stroke={"white"} strokeDasharray="5" opacity={0.3} isFront={true} strokeWidth={3} />
                            <ReferenceLine x={currentTime} stroke={NewColorPalette[4]} strokeDasharray="18" opacity={100} isFront={true} strokeWidth={3} />
                            <ReferenceLine y={0} stroke="grey" strokeWidth={3} />
                            {renderBars()}
                            <Line type="monotone" dataKey="NIV" name="NIV" unit=" MW" stroke={(colourMode.isDarkMode ? "white" : NewColorPalette[8])} strokeWidth={3} dot={false} activeDot={false} isAnimationActive={false} />
                        </ComposedChart> :
                        <LoadingSymbol />
                    )}
                </ResponsiveContainer>
            </Box>
        </Box>
    );
}
export interface IBalancingVolumeChart {
    displayScreen?: boolean
}

export default BalancingVolumeChart;