import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import { ISystemSummaryCategories, ISystemSummaryPowerData, ISystemSummaryPriceData } from "../../../../shared/types/analytics/trading-analytics/ISystemSummaryData";
import Grid from '@mui/material/Unstable_Grid2';

const SystemSummaryChartLegendRow: React.FC<ISystemSummaryChartLegendRow> = ({ keys, square, enabledData, setEnabledData, nameDict, coloursDict }) => {

    const flipData = (key: keyof ISystemSummaryCategories<any>) => {
        const newEnabledData = structuredClone(enabledData) as ISystemSummaryCategories<boolean>
        newEnabledData[key] = !newEnabledData[key]
        setEnabledData(newEnabledData)
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap'
        }}>
            {keys.map((key) => {
                const enabled = enabledData[key]
                const name = nameDict[key]
                const color = enabled ? coloursDict[key] : "black"
                return (
                    <IconButton
                        key={"System-Summary-Legend-" + key}
                        size="small"
                        sx={{ borderRadius: 10, px: 1, "transition": "all .2s", "color": "inherit", "&:hover": { "backgroundColor": "rgba(160, 160, 160, 0.5)" } }}
                        onClick={() => { flipData(key) }}
                    >
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <span style={{ backgroundColor: color, width: "20px", height: square ? "20px" : "2px" }}></span>
                            <span style={{ opacity: enabled ? 1 : 0.5 }}>{name}</span>
                        </Stack>
                    </IconButton>
                )
            })}
        </div>
    );
}

interface ISystemSummaryChartLegendRow {
    keys: (keyof ISystemSummaryCategories<any>)[],
    square: boolean,
    enabledData: ISystemSummaryCategories<boolean>,
    setEnabledData: (enabledData: ISystemSummaryCategories<boolean>) => void,
    nameDict: ISystemSummaryCategories<string>,
    coloursDict: ISystemSummaryCategories<string>
}

const SystemSummaryChartLegend: React.FC<ISystemSummaryChartLegend> = ({ powerKeys, priceKeys, enabledData, setEnabledData, nameDict, coloursDict }) => {

    return (
        <Box>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid xs={12}>
                    <SystemSummaryChartLegendRow keys={powerKeys} square={true} enabledData={enabledData} setEnabledData={setEnabledData} nameDict={nameDict} coloursDict={coloursDict} />
                </Grid>
                <Grid xs={12}>
                    <SystemSummaryChartLegendRow keys={priceKeys} square={false} enabledData={enabledData} setEnabledData={setEnabledData} nameDict={nameDict} coloursDict={coloursDict} />
                </Grid>
            </Grid>
        </Box>
    );
}
export interface ISystemSummaryChartLegend {
    powerKeys: (keyof ISystemSummaryPowerData<any>)[],
    priceKeys: (keyof ISystemSummaryPriceData<any>)[],
    enabledData: ISystemSummaryCategories<boolean>,
    setEnabledData: (enabledData: ISystemSummaryCategories<boolean>) => void,
    nameDict: ISystemSummaryCategories<string>,
    coloursDict: ISystemSummaryCategories<string>
}

export default SystemSummaryChartLegend;